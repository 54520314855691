import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  .shipments-list {
    min-height: 100px;
    position: relative;
    margin-bottom: 20px;
  }
  .healthy {
    background: ${theme.colors.healthy};
  }
  .partial {
    background: ${theme.colors.partial};
  }
  .issued {
    background: ${theme.colors.issued};
  }
  .shipments-table {
    background: #FFF !important;
    border: 1px solid #bbb !important;
    border-radius: 8px;
    padding: 12px 23px;
    margin: 20px 0;
    overflow: auto;
    width: 100%;
    h2 {
      margin: 0 10px;
    }
    .shipments-date {
      font-size: ${theme.text.size.sm};
    }
    table {
      margin: 0;
      width: 100%;
      tbody {
        width: 100%;
        tr {
          display: flex;
          width: 100%;
          td {
            max-width: 35px;
            margin-left: 7px;
            }
            .shipments-column {
              text-align: center;
              width: 100%;
              .shipments-status {
                height: 47px;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .circle {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin: 0 9px;
  }
  .status {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 20px;
    font-size: ${theme.text.size.xs};
    
    .status-captions {
      width: fit-content;
      justify-content: flex-start;
      div {
        display: flex;
      }
    }
  }
`;
