import { useCallback, useContext, useEffect, useState } from 'react';

import NumberFormat from 'react-number-format';

import * as dashboardFinanceSvc from '../../service/dashboardFinance';

import { AuthContext } from '../../context/auth';

import { LoadingContext } from '../../context/loading';
import { Loading } from '../../providers';

import FinanceBarChart from '../../components/FinanceBarChart';
import FinanceChart from '../../components/FinanceChart';

import Tooltip from '../../components/InfoTooltip';

import { Modal, message } from 'antd';

import {
  Container,
  Title,
  MetricContent,
  CardMetric,
  ContainerRangeAssinaturePercent,
  RangeAssinaturePercent,
  ContentAssinature,
  AmountUsed,
  Metric,
  ContainerDash,
  Dash,
  FilterContainer,
  PlaneCard,
} from './styled';
import theme from '../../theme';

interface CardProps {
  title: string;
  value: number | undefined | null;
  info: string;
  active?: boolean;
  isCurrency?: boolean;
}

interface MetricsDataProps {
  description: string;
  isActive: boolean;
  maxCustomersAllowed: number;
  name: string;
  surplusPrice: number;
  unitPrice: number;
  usersConsumed: number;
  amount: number;
  portionAmount: number;
  smsPrice: number;
  iquiteiPrice: number;
  unvalidatedClientPrice: number;
  phonesCount: number;
}

interface PlansProps {
  title: string;
  icon: string;
  active: boolean;
}

const monthsOfYear = {
  1: 'Janeiro',
  2: 'Fevereiro',
  3: 'Março',
  4: 'Abril',
  5: 'Maio',
  6: 'Junho',
  7: 'Julho',
  8: 'Agosto',
  9: 'Setembro',
  10: 'Outubro',
  11: 'Novembro',
  12: 'Dezembro',
};

export default function DashboardFinance() {
  const { setLoading: setLoadingContext } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [load, setLoad] = useState(true);
  const [loadGraph, setLoadGraph] = useState(false);

  const ControllerRequisition = new AbortController();

  useEffect(() => {
    if (user) {
      setLoadingContext(false);
    }
  }, [user]);

  const [card, setCard] = useState<CardProps[]>([]);

  const [amountUse, setAmountUse] = useState<number | null | undefined>(null);
  const [roi, setRoi] = useState<number | null | undefined>(null);
  const [dataMetrics, setDataMetric] = useState<MetricsDataProps>();
  const [dataGraphs, setDataGraph] = useState({ creditors: [], data: [] });

  const [filterMonth, setFilterMonth] = useState<any>(new Date().getMonth() + 1);
  const [filterYear, setFilterYear] = useState<any>(new Date().getFullYear());

  const fetchMetricsData = async () => {
    setLoad(true);
    try {
      const data = await dashboardFinanceSvc.getDataMetrics(
        user.partner.id,
        ControllerRequisition.signal,
        filterMonth,
        filterYear
      );

      // if (!data) return setModalActivePlane(true);

      console.log({ data });
      setDataMetric(data);
    } catch (error: any) {
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
      const planInactive = err === 'Plano inativo' || err === 'Não existem dados para este período';
      // setModalActivePlane(planInactive);
      setLoad(false);
      console.error(error);
    } finally {
      setLoadingContext(false);
    }
    setLoad(false);

    return () => ControllerRequisition.abort();
  };

  const fetchGraphicsData = async () => {
    try {
      setLoadGraph(true);
      const data: any = await dashboardFinanceSvc.getDataGraphics(
        user.partner.id,
        ControllerRequisition.signal,
        filterMonth,
        filterYear
      );

      setDataGraph(data);
      setLoadGraph(false);
    } catch (error) {
      setLoadGraph(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMetricsData();
      fetchGraphicsData();
    }
  }, [user, filterMonth, filterYear]);

  const [modalActivePlane, setModalActivePlane] = useState<boolean>(false);

  useEffect(() => {
    if (dataMetrics) {
      if (dataMetrics?.maxCustomersAllowed === null) {
        setAmountUse(dataMetrics.maxCustomersAllowed);
      } else {
        setAmountUse((dataMetrics?.usersConsumed / dataMetrics?.maxCustomersAllowed) * 100);
      }

      setRoi(dataMetrics.amount - (dataMetrics?.iquiteiPrice + dataMetrics?.smsPrice));

      setCard([
        {
          title: 'Telefones',
          value: dataMetrics?.phonesCount,
          info: 'Números de telefone cadastrados nas campanhas para disparo na plataforma',
          active: true,
          isCurrency: false,
        },
        {
          title: 'Gasto estimado (SMS)',
          value: dataMetrics?.smsPrice,
          info: 'Gasto total estimado de acordo com os valores de SMS usados nas campanhas, cadastrados na plataforma',
          active: true,
          isCurrency: true,
        },
        {
          title: 'Custo atual iQuitei',
          value: dataMetrics?.iquiteiPrice,
          info: `Custo de R$ ${dataMetrics?.unitPrice} por CPF cadastrado na plataforma (acima da cota mínima contratada de R$ 990,00)`,
          active: true,
          isCurrency: true,
        },
        // {
        //   title: 'Est total sem validacao de tel',
        //   value: (dataMetrics?.unvalidatedClientPrice + dataMetrics?.smsPrice),
        //   info: 'Estimativa total sem validação de telefone',
        //   active: true
        // },
        // {
        //   title: 'Economia com validacao de tel',
        //   value: (dataMetrics?.unvalidatedClientPrice),
        //   info: 'Economia com validacao de telefone',
        //   active: true
        // },
        // {
        //   title: 'Total geral negociado (1 parc)',
        //   value: dataMetrics?.portionAmount || 0,
        //   info: 'Total geral negociado na primeira parcela',
        //   active: true
        // },
        {
          title: 'Total geral negociado',
          value: dataMetrics?.amount || 0,
          info: 'Total geral de negociações no mês selecionado.',
          active: true,
          isCurrency: true,
        },
        {
          title: 'Estimativa ROI',
          value: roi,
          info: 'Estimativa de ROI (retorno sobre o investimento) baseado no valor estimado de SMS + custo iQuitei dividido pelo valor negociado pela plataforma.',
          active: true,
          isCurrency: true,
        },
      ]);
    }
  }, [dataMetrics, roi]);

  const [itemsPlane, setItemsPlane] = useState<PlansProps[]>([]);
  useEffect(() => {
    setItemsPlane([
      {
        title: 'CLASSIC',
        icon: 'https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Star_icon_stylized.svg/1077px-Star_icon_stylized.svg.png',
        active: false,
      },
      {
        title: 'DELUXE',
        icon: 'https://cdn-icons-png.flaticon.com/512/2385/2385865.png',
        active: false,
      },
      {
        title: 'PREMIUM',
        icon: 'https://cdn-icons-png.flaticon.com/128/2055/2055833.png',
        active: false,
      },
    ]);
  }, [user]);

  const handleChangeActiveItem = (i) => {
    const items = itemsPlane.map((item) =>
      item === i ? { ...item, active: true } : { ...item, active: false }
    );

    setItemsPlane(items);
  };

  const [selectFilterOptions, setSelectFilterOptions] = useState<any[]>([]);

  const fetchMonthOptions = async () => {
    try {
      setLoad(true);
      const selectOptions = await dashboardFinanceSvc.getEnableSelectData(user.partner.id);

      // selectOptions.unshift({
      //   year: new Date().getFullYear(),
      //   month: new Date().getMonth() + 1,
      // });

      setSelectFilterOptions(selectOptions.reverse());
      if (selectOptions.length > 0) {
        setFilterYear(selectOptions[0].year);
        setFilterMonth(selectOptions[0].month);
      }
      // setLoad(false);
    } catch (error) {
      // setLoad(false);
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      fetchMonthOptions();
    }
  }, [user]);

  const handleFetchDataFilter = useCallback(
    (value) => {
      try {
        const date = value.split(' ');
        setFilterMonth(date[0]);
        setFilterYear(date[1]);
      } catch (error) {
        throw error;
      }
    },
    [filterMonth]
  );

  return (
    <Container>
      <Title>Dashboard Financeiro</Title>

      <Modal
        title="Ativar um plano"
        cancelText={'Fechar'}
        okText={'Ativar'}
        visible={modalActivePlane}
        onCancel={() => setModalActivePlane((value) => !value)}
        children={
          <div
            style={{
              display: 'flex',
              padding: '8px',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {itemsPlane.map((i, index) => (
              <PlaneCard key={index} active={i.active} onClick={() => handleChangeActiveItem(i)}>
                <p>{i.title}</p>
                <img src={i.icon} alt="deluxe" style={{ width: '80px', height: '80px' }} />
              </PlaneCard>
            ))}
          </div>
        }
      />

      <FilterContainer>
        <div className="content-text">
          <p className="filter-name">Mês</p>
        </div>
        <select
          value={`${filterMonth} ${filterYear}`}
          onChange={(event: any) => handleFetchDataFilter(event.target.value)}
        >
          {selectFilterOptions?.map(
            (i, index) =>
              i && (
                <option key={index} value={`${i.month} ${i.year}`}>
                  {`${monthsOfYear[i.month]} de ${i.year}`}
                </option>
              )
          )}
        </select>
      </FilterContainer>

      <div className="content-assinature">
        <ContentAssinature>
          <ContainerRangeAssinaturePercent>
            <h4 className="title-text">
              Seu plano: <strong>{dataMetrics?.name}</strong>
            </h4>

            <div className="container">
              {load && <Loading relative />}
              <div className="input-group">
                {amountUse === null ? (
                  <p className="text">Seu plano atual é de uso ilimitado</p>
                ) : (
                  <p className="text">
                    {!Number.isNaN(amountUse) ? amountUse?.toFixed(1) : '0'}% dos usuários incluídos
                    no plano
                  </p>
                )}
                <RangeAssinaturePercent
                  percent={!amountUse ? 100 : amountUse || 0}
                  strokeColor={{
                    '0%': '#5ab4f5',
                    '100%': '#0f29aa',
                  }}
                  showInfo={false}
                  size="default"
                />
              </div>
              <AmountUsed>
                <p className="text-amount">Total utilizado</p>
                <Metric>
                  <strong className="amount-us">
                    {dataMetrics?.usersConsumed
                      ? dataMetrics?.usersConsumed.toLocaleString('pt-br')
                      : '-'}{' '}
                    /{' '}
                    {dataMetrics?.maxCustomersAllowed === null
                      ? 'Ilimit.'
                      : dataMetrics?.maxCustomersAllowed || '-'}
                  </strong>
                </Metric>
              </AmountUsed>
            </div>
          </ContainerRangeAssinaturePercent>
        </ContentAssinature>
      </div>

      <MetricContent>
        {card?.map((i, index) => (
          <CardMetric
            className={`delay-${index}`}
            key={index}
            background={index === 3 || index === 4 ? '#E8ECFF' : '#fff'}
            active={i.active}
          >
            {load && <Loading relative />}
            <div style={{ display: 'flex' }}>
              <p className="text-metric">{i.title}:</p>
              <Tooltip text={i.info} />
            </div>
            <Metric>
              <strong className="percent">
                {i.isCurrency ? (
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={i.value ? Number(i.value) : 0}
                    prefix="R$ "
                    decimalSeparator=","
                    displayType="text"
                    type="text"
                    thousandSeparator="."
                    decimalScale={2}
                    fixedDecimalScale={true}
                  />
                ) : (
                  <NumberFormat
                    thousandsGroupStyle="thousand"
                    value={i.value ? Number(i.value.toLocaleString('pt-br')) : 0}
                    displayType="text"
                    type="text"
                  />
                )}
              </strong>
            </Metric>
          </CardMetric>
        ))}
      </MetricContent>

      {/* <ContainerDash>
        <Dash>
          {loadGraph && <Loading relative />}
          <p className="title-dash">Quantidade de mensagens por dia</p>
          <FinanceChart data={dataGraphs.data} />
        </Dash>

        <Dash>
          {loadGraph && <Loading relative />}
          <p className="title-dash">Quantidade de mensagens por dia (credor)</p>
          <FinanceBarChart
            title="Quantidade de mensagens por dia (credor)"
            data={dataGraphs.data}
            colors={Object.values(theme.colors)}
            keys={dataGraphs.creditors}
            indexBy={'day'}
            axisBottomName={'Dia da semana'}
            axisLeftName={'Quantidade'}
            label={'Mensagens'}
          />
        </Dash>
      </ContainerDash> */}
    </Container>
  );
}
