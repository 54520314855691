import React from 'react';
import ReactDOM from 'react-dom';
import { AuthProvider } from './context/auth';
import { LoadingProvider } from './context/loading';
import { PartnerProvider } from './context/partnerSelect';
import 'antd/dist/antd.css';
import './index.css';
import 'react-datepicker/dist/react-datepicker.css';
import reportWebVitals from './reportWebVitals';

import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import Routes from './routes';

if (process.env.REACT_APP_NODE_ENV === 'prod') {
  Sentry.init({
    dsn: 'https://814f2a0f981a432487f3d6ae6ded75c9@o1039221.ingest.sentry.io/6008004',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

ReactDOM.render(
  <React.StrictMode>
    <LoadingProvider>
      <AuthProvider>
        <PartnerProvider>
          <Routes />
        </PartnerProvider>
      </AuthProvider>
    </LoadingProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
