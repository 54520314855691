import React from 'react';
import { Input as AntdInput } from 'antd';
import { Container } from './styled';
import theme from '../../theme';
import { Loading } from '../../providers';

interface InputProps {
  type: string;
  value: any;
  onChange?: (value: string) => any;
  placeholder?: string;
  disabled?: boolean;
  autoComplete?: string;
  maxLength?: number;
  pattern?: string;
  suffix?: any;
  prefix?: any;
  loading?: boolean;
}

const style = {
  borderRadius: theme.border.radius,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.colors.lightGrey,
  marginBottom: '10px',
};

const Input: React.FC<InputProps> = ({
  type,
  value,
  onChange,
  placeholder,
  disabled,
  autoComplete,
  maxLength,
  pattern,
  suffix,
  prefix,
  loading,
}) => {
  return (
    <Container >
      {loading && <Loading relative/>}
      <AntdInput
        type={type}
        placeholder={placeholder}
        style={style}
        value={value}
        autoComplete={autoComplete}
        maxLength={maxLength}
        suffix={suffix}
        disabled={disabled}
        prefix={prefix}
        pattern={pattern}
        onChange={(event: any) => onChange && onChange(event.target.value)}
      />
    </Container>
  );
};

export default Input;
