import * as iquiteiSvc from './iquiteiApi.service';

interface IPartnerCredorCreate {
  creditorId: number | string;
  username: string;
  password: string;
}

export async function findById(partnerId: string, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os acessos';
    throw err;
  }
}

export async function getPartnerCreditors(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/creditors`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os credores';
    throw err;
  }
}

export async function linkPartnerCreditor(
  partnerId: number,
  creditorId: number,
  signal?: AbortSignal
) {
  try {
    const res = await iquiteiSvc.postRequest(
      `/partners/${partnerId}/v2/partnerCreditors`,
      { creditorId },
      { signal }
    );

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao vincular o parceiro ao credor';
    throw err;
  }
}

export async function getPartnerCreditorsV2(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/creditors`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os credores';
    throw err;
  }
}

export async function getAllCreditorsV2(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/creditors/list-all`, {
      signal,
    });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os credores';
    throw err;
  }
}

export async function putCreditorData(partnerId: number, form: any) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/v2/creditors/${form.id}`, form);

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao salvar as informações';
    throw err;
  }
}

export async function listPartners(signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest('/partners', { signal });

    return res.data.body.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os acessos';
    throw err;
  }
}

export async function createPartner(
  name: string,
  email: string,
  document: number,
  initials: string,
  socialReason: string,
  fantasyName: string,
  image: any | null
) {
  try {
    const res = await iquiteiSvc.postRequest('/partners', {
      name,
      email,
      document,
      initials,
      socialReason,
      fantasyName,
    });
    const id = res.data.body.data.insertId;

    if (image) await updatePartnerPicture(image, id);

    return res.data.body.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao salvar o parceiro.';
    throw err;
  }
}

export async function updatePartnerPicture(imgFile: any, partnerId: string | number) {
  try {
    const body = new FormData();

    body.append('imgFile', imgFile);

    const res = await iquiteiSvc.putRequest(`/partners/image/${partnerId}`, body);

    return res.data.body.data;
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao atualizar a foto do parceiro';
    throw err;
  }
}

export async function updatePartner(
  partnerId: string | number,
  email: string,
  document: number,
  initials: string,
  socialReason: string,
  fantasyName: string,
  image: any | null
) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}`, {
      partnerId,
      email,
      document,
      initials,
      socialReason,
      fantasyName,
    });

    if (image) await updatePartnerPicture(image, partnerId);

    return res.data.body.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao atualizar o parceiro';
    throw err;
  }
}

export async function updatePartnerCreditor(partnerId: number, body: IPartnerCredorCreate) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/v2/partnerCreditors`, body);

    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao atualizar o credor do parceiro';
    throw err;
  }
}

export async function getPartnerCreditorByPartner(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/partnerCreditors`, {
      signal,
    });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao buscar credor do parceiro';
    throw err;
  }
}

export async function getInfoById(partnerId: string, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/info/${partnerId}`, { signal });

    if (res?.data?.body?.length > 0) {
      return res.data.body[0];
    }

    return null;
  } catch (error: any) {
    console.error({ error });
    const { data } = error.response;
    const err =
      data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar as informações do parceiro';
    throw err;
  }
}
