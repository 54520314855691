import React from 'react';

import { Button as AntdButton } from 'antd';

import theme from '../../theme';

interface ButtonProps {
  color: 'primary' | 'secondary' | 'no-border' | 'disabled' | 'newButton' | 'deleteButton';
  text?: string;
  type?: any;
  icon?: any;
  disableMarginBottom?: boolean;
  onClick?: () => void;
  disabled?: boolean;
  fontSize?: string;
  styleCustom?: any;
}

const Button: React.FC<ButtonProps> = ({
  type,
  color,
  text,
  icon,
  disableMarginBottom,
  onClick,
  disabled,
  fontSize,
  styleCustom
}) => {
  const standardStyle = {
    borderRadius: theme.border.radius,
    height: '46px',
    width: '100%',
    fontSize: fontSize || theme.text.size.md,
    border: 'none',
    marginBottom: disableMarginBottom ? '0px' : '10px',
    fontWeight: 'bold' as 'bold',
    paddingLeft: '20px',
    paddingRight: '20px',
    // display: 'flex',
    // alignItems: 'center'
  };

  const primaryStyle = {
    ...standardStyle,
    background: theme.colors.primary,
    color: theme.colors.light,
  };

  const secondaryStyle = {
    ...standardStyle,
    backgroundColor: theme.colors.light,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
  };

  const newButtonStyle = {
    ...standardStyle,
    backgroundColor: theme.colors.healthy,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.healthy,
    color: theme.colors.light,
  };

  const deleteButtonStyle = {
    ...standardStyle,
    backgroundColor: theme.colors.issued,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.issued,
    color: theme.colors.light,
  };

  const noBorderStyle = {
    ...standardStyle,
    backgroundColor: theme.colors.light,
    height: 'auto',
    width: 'auto',
    textDecoration: 'underline',
    fontWeight: 'normal' as 'normal',
    border: 'none',
    padding: 0,
    color: theme.colors.primary,
  };

  const disabledStyle = {
    ...standardStyle,
    backgroundColor: theme.colors.lightGrey,
  };

  let style: any = null;

  switch (color) {
    case 'primary':
      style = primaryStyle;
      break;
    case 'secondary':
      style = secondaryStyle;
      break;
    case 'no-border':
      style = noBorderStyle;
      break;
    case 'disabled':
      style = disabledStyle;
      break;
    case 'newButton':
      style = newButtonStyle;
      break;
    case 'deleteButton':
      style = deleteButtonStyle;
      break;
    default:
      style = primaryStyle;
  }

  return (
    <AntdButton type={type} style={{...style, ...styleCustom}} onClick={onClick} disabled={disabled}>
      {icon}
      {text}
    </AntdButton>
  );
};

export default Button;
