import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { routeParams } from '../../config/routes.config';
import { Loading } from '../../providers';

export default function SplashScreen() {
  const history = useHistory();

  useEffect(() => {
    history.push(routeParams.ROUTE_LOGIN);
  });

  return <Loading />;
}
