import Joi from 'joi';

export const formModalShipmentValidation = Joi.object({
  partnerId: Joi.number().disallow(null).required().messages({
    'any.invalid': 'Não foi possível identificar o parceiro. Entre em contato com o suporte',
  }),
  name: Joi.string().disallow('', null).required().messages({
    'any.invalid': 'É necessário informar o nome da remessa',
  }),
  message: Joi.string().disallow('', null).required().messages({
    'any.invalid': 'É necessário informar a mensagem da remessa',
  }),
  creditorId: Joi.number().disallow(null).required().messages({
    'any.invalid': 'É necessário a carteira indicada para essa remessa',
  }),
  file: Joi.any()
    .disallow(null)
    .required()
    .messages({ 'any.invalid': 'Selecione o arquivo antes de enviar' }),
  csvFormat: Joi.number().disallow(null).required().messages({
    'any.invalid': 'Formato inválido. Entre em contato com o suporte',
  }),
  shippingDate: Joi.string().disallow(null).required().messages({
    'any.invalid': 'Data de envio inválida. Entre em contato com o suporte',
  }),
});
