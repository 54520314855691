import { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { LoadingContext } from '../../../context/loading';

import { Container } from '../styled';

import TextArea from '../../../components/TextArea';
import Button from '../../../components/Button';
import Input from '../../../components/Input';
import Select from '../../../components/Select';

import { message } from 'antd';

import { AuthContext } from '../../../context/auth';
import { getErrorLogById, updateErrorLogFields } from '../../../service/logErrors.service';


interface SystemIdErrorProps {
    id?: string;
}

const SystemIdError = ({ id  }: SystemIdErrorProps) => {
    const { setLoading, loading } = useContext(LoadingContext);
    // // const { setLoading: setLoadingContext } = useContext(LoadingContext);
    const { user } = useContext(AuthContext);

    const { docId }: any = useParams();

    const [form, setForm] = useState({
        payload: '',
        origemService: '',
        resolved: '',
        dataError: '',
        ambiente: '',
        description: '' 
    });

    const handleSubmitError = async (docId) => {
        try {
            const {
                ambiente,
                dataError,
                description,
                origemService,
                payload,
                resolved 
            } = form;

            if(resolved === '8') {
                form.resolved = 'true';
            } else {
                form.resolved = 'false'
            }

            if(!ambiente || ambiente === '') {
                // setLoading(false);
                return message.error('O campo Ambiente é obrigatório');
            }

            if(!dataError || dataError === '') {
                // setLoading(false);
                return message.error('O campo data do erro é obrigatório');
            }

            if(!description || description === '') {
                // setLoading(false);
                return message.error('O campo descrição é obrigatório');
            }

            if(!origemService || origemService === '') {
                // setLoading(false);
                return message.error('O campo origem de serviço é obrigatório');
            }

            if(!payload || payload === '') {
                // setLoading(false);
                return message.error('O campo de payload é obrigatório');
            }

            if(!resolved || resolved === '') {
                // setLoading(false);
                return message.error('O campo resolvido é obrigatório');
            }
            
            await updateErrorLogFields(user.partner.id, docId, form);
            window.location.reload();

            message.success('Log do erro atualizado com sucesso!');
            // setLoading(false);
        }catch(err) {
            // setLoading(false);
            console.error(err);
            message.error('Ocorreu um erro ao atualizar.');
        }     
    }

    const formattDate = (data) => {
        if(data.stringValue) {
            const time = data.stringValue.timestampValue;
            const dateFormat = new Date(time*1000);
            const formattDate = dateFormat.toLocaleDateString('pt-br');
            
            return formattDate;
        }
        if(data.timestampValue) {
            const time = data.timestampValue.seconds;
            const dateFormat = new Date(time*1000);
            const formattDate = dateFormat.toLocaleDateString('pt-br');
            
            return formattDate;
        }
    }

    const fetchLogError = async (partnerId, docId) => {
        try {
            const data = await getErrorLogById(partnerId, docId);
            const dateFormat = formattDate(data.createdAt);

            setForm({
                ambiente: data.env.stringValue || '', 
                dataError: dateFormat || '',
                description: data.description.stringValue || 'undefined',
                origemService: data.origin_service.stringValue ||  '',
                payload: data.payload.stringValue || '',
                resolved: data.solved.stringValue === 'true' ? 'resolvido' : 'não resolvido', 
            });

        } catch(error: any) {
            const err = typeof error === 'string' ? error : 'Ocorreu um erro';
            message.error(err);
        }
    };

    useEffect(() => {
        //FETCH DATA
        if(user && user.partner) {
            (async () => {
                await fetchLogError(user.partner.id, docId.toString());
            })();
        }

        setForm({
            ambiente: '', 
            dataError: '',
            description: '',
            origemService: '',
            payload: '',
            resolved: '', 
        });
        setLoading(false);
    }, [user]);
    
    const options = [
        { _id: '8', name: 'Resolvido' },
        { _id: '10', name: 'Não Resolvido'},
    ];

  return (
    <Container>
      <h2 className='title'>
        { id }
      </h2>
      <div className='container-form'>
          <form className='form'>
                <div className='content-input'>
                    <label className='text-label' htmlFor="">Serviço de origem:</label>
                    <Input 
                        type="text" 
                        value={form.origemService} 
                        onChange={(value: string) => setForm({ ...form , origemService: value})} 
                    />
                    
                    <label className='text-label' htmlFor="">Data do erro:</label>
                    <Input 
                        type="text" 
                        value={form.dataError} 
                        onChange={(value: any) => setForm({ ...form , dataError: value})} 
                    />
                </div>
                
                <div className='content-input'>
                    <label className='text-label' htmlFor="">Ambiente:</label>
                    <Input 
                        type="text" 
                        value={form.ambiente} 
                        onChange={(value: any) => setForm({ ...form , ambiente: value})} 
                    />
                   
                    <label className='text-label' htmlFor="">Resolvido:</label>
                    <Select
                        placeholder='--Selecione--'
                        firstDisabled
                        value={form.resolved}
                        optionKeys={{ value: '_id' ,displayName: 'name' }}
                        options={options}
                        onChange={(value: any) => setForm({ ...form , resolved: value})} 
                    />
                </div>

                <div className='content-input'>
                    <label className='text-label' htmlFor="">Descrição</label>
                    <Input 
                        type="text" 
                        value={form.description} 
                        onChange={(value: any) => setForm({ ...form , description: value})} 
                    />
                </div>
                
                <div className='content-textarea'>
                    <label className='text-label' htmlFor="">Payload</label>
                    <TextArea
                        rows={10} 
                        value={form.payload} 
                        onChange={(value: any) => setForm({ ...form , payload: value})} 
                    />
                </div>

                <div className='content-actions'>
                    <div className='button'>
                        <a href="/system-errors" style={{ all: 'unset' }}>
                            <Button
                                color='primary' 
                                text='voltar'
                            />
                        </a>
                    </div>
                    <div className='button'>
                        <Button
                            onClick={() => handleSubmitError(docId)}
                            color='secondary' 
                            text='salvar' 
                        />
                    </div>
                </div>

          </form>
      </div>
    </Container>
  )
}

export default SystemIdError;
