import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .metrics {
    display: flex;
    flex-direction: column;
    gap: ${theme.flexGrid.gap};

    .datepicker-content {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      label {
        margin-right: 10px;
        font-weight: bold;
      }

      nav {
        .active {
          background: ${theme.colors.secondary};
          color: ${theme.colors.primary};
          border: 1px solid ${theme.colors.primary};
        }

        .inactive {
          background-color: ${theme.colors.primary};
          color: ${theme.colors.secondary};
          border: none;
        }

        .button-left {
          border-radius: ${theme.border.radius} 0 0 ${theme.border.radius};
          /* border-right: 1px solid ${theme.colors.secondary} */
        }

        .button-right {
          border-radius: 0 ${theme.border.radius} ${theme.border.radius} 0;
          /* border-left: 1px solid ${theme.colors.secondary} */
        }

        button {
          padding: 10px;
          cursor: pointer;
        }
      }
    }
  }
`;
