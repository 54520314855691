import { useContext, useEffect, useState } from 'react';
import { Footer } from 'antd/lib/layout/layout';
import { useHistory } from 'react-router-dom';
import errorImage from '../../assets/images/error-image.svg';
import logoImage from '../../assets/images/iquitei-logo.png';
import { LoadingContext } from '../../context/loading';
import { AuthContext } from '../../context/auth';
import { Container } from './styled';
import { routeParams } from '../../config/routes.config';

export default function ErrorPage() {
  const { setLoading } = useContext(LoadingContext);
  const { logoutUser } = useContext(AuthContext);
  const history = useHistory();

  const currentYear = new Date().getFullYear();

  const [counter, setCounter] = useState(5);

  useEffect(() => {
    setLoading(false);
    const interval = setInterval(() => {
      setCounter((prevState) => prevState - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (counter <= 0) {
    localStorage.clear();
    logoutUser();
    window.location.href = `${routeParams.ROUTE_URL}`;
    }
  }, [counter]);

  return (
    <Container>
      <img src={ logoImage } alt="logo" className='logo-image'/>
      <h2>Ops...</h2>
      <span>Ocorreu um problema ao carregar sua sessão. Iremos te</span>
      <span>redirecionar para o login novamente, ok?</span>
      <h3>Redirecionando em {counter}...</h3>
      <img src={ errorImage } alt="erro" className="error-image" />
      <Footer id="layout-footer">
        iQuitei.com© - {currentYear}. Todos os direitos reservados.
      </Footer>
    </Container>
  );
};
