import { firebaseConfig } from '../config';

export enum UserRole {
  ROLE_ADMIN = 'ADMIN',
  ROLE_MANAGER = 'MANAGER',
  ROLE_SUPERVISOR = 'SUPERVISOR',
  ROLE_OPERATOR = 'OPERATOR',
}

export interface IUser {
  _id?: string;
  name: string;
  email: string;
  imageUrl?: string;
  role?: UserRole;
  createdAt?: any;
  updatedAt?: any;
  deletedAt?: any;
  isDeleted?: boolean;
}

export class User {
  _id?: string;
  name: string;
  email: string;
  imageUrl?: string;
  role?: UserRole;
  createdAt?: any;
  updatedAt?: any;
  deletedAt?: any;
  isDeleted?: boolean;

  constructor(props: IUser) {
    this._id = props._id ?? '';
    this.name = props.name ?? '';
    this.email = props.email ?? '';
    this.imageUrl = props.imageUrl ?? '';
    this.role = props.role || UserRole.ROLE_OPERATOR;
    this.createdAt = firebaseConfig.serverTime;
    this.updatedAt = props.updatedAt ?? '';
    this.deletedAt = props.deletedAt ?? '';
    this.isDeleted = false;
  }

  toFirestore(user: User) {
    return {
      _id: user._id,
      name: user.name,
      email: user.email,
      imageUrl: user.imageUrl,
      role: user.role,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
      deletedAt: user.deletedAt,
      isDeleted: user.isDeleted,
    };
  }

  fromFirestore(snapshot: any, options: any) {
    const user: any = snapshot.data(options);

    return new User({
      _id: user._id,
      name: user.name,
      email: user.email,
      imageUrl: user.imageUrl,
      role: user.role,
      createdAt: user.createdAt,
      updatedAt: user.updatedAt,
      deletedAt: user.deletedAt,
      isDeleted: user.isDeleted,
    });
  }
}
