import * as iquiteiSvc from './iquiteiApi.service';

export async function getNewsFeature(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/newsFeatures`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}
