import * as iquiteiSvc from './iquiteiApi.service';

export async function get(signal?: AbortSignal): Promise<any> {
  try {
    const res: any = await iquiteiSvc.getRequest('/healthCheck', { signal });
    const { data } = res;

    return data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}
