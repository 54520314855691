import { Container, ViewLatestNews } from './styled';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../context/loading';
import { AuthContext } from '../../context/auth';
import { newsFeatureService } from '../../service';
import { formattedFullDate } from '../../utils/date-format';
import { message } from 'antd';
import { Loading } from '../../providers';

const LatestNews = () => {
  const { setLoading } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [newsFeatures, setNewsFeatures] = useState<any>([]);
  const [loading, setLoadingBreaked] = useState(true);

  const ControllerRequisition = new AbortController();

  const fetchNewsFeatures = async (partnerId: number) => {
    try {
      setLoadingBreaked(true);
      const res: any[] = await newsFeatureService.getNewsFeature(partnerId, ControllerRequisition.signal);
      setNewsFeatures(res);
      setLoadingBreaked(false);
    } catch (error: any) {
      setLoadingBreaked(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };
  const descriptionFormatter = (id: number, text: string, origin: string) => {
    let element: any = null;

    switch(id){
      //MELHORIA
      case 1:
        element = (<span style={{color: '#17053A'}}> {text} - {origin} </span>)
        break;
      //BUG
      case 2:
        element = (<span style={{color: 'red'}}> {text} - {origin} </span>)
        break;
      //NOVIDADES
      case 3:
        element = (<span style={{color: 'green'}}> {text} - {origin} </span>)
        break;
      default:
        break;
    }

    return element;

  }

  useEffect(() => {
    setLoading(false);
    if (user) {
      fetchNewsFeatures(user?.partner.id);
    }
    return () => ControllerRequisition.abort();
  }, [user]);
  
  return (
    <Container>
      <h2>Resumo das últimas atualizações</h2>
      <div className="card-row">
        { loading && <Loading relative />}
        {newsFeatures.map((item) => (
          <div className="card" key={item.id}>
            <ViewLatestNews>
              <h3>{item.title}</h3>
              {descriptionFormatter(item.typeId, item.description_type, item.origin)}
              <span>{formattedFullDate(new Date(item.date), 'dd-MM-yyyy HH:mm')}</span>
              <p>
                <span
                  className="cardText"
                  dangerouslySetInnerHTML={{ __html: `${item.description}` }}
                />
              </p>
            </ViewLatestNews>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default LatestNews;
