import { Container, RegisterEmployeeForm } from './styled';
import { Input, Button, Select, InfoTooltip } from '../../../components';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../../context/loading';
import { message } from 'antd';
import { userService } from '../../../service';
import { UserRole } from '../../../entity';
import { AuthContext } from '../../../context/auth';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../providers';

const RegisterEmployee = () => {
  const { setLoading: setLoadingContext } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(true);
  const [roles, setRoles] = useState<any[]>();

  const [form, setForm] = useState<any>({
    name: null,
    email: null,
    roleName: '',
    partnerId: '',
  });

  let roleSelected = { name: 'Colaborador' };
  if (roles) roleSelected = roles.find((r) => r.role === form.roleName);

  const ControllerRequisition = new AbortController();

  const fetchRoles = async () => {
    try {
      setLoading(true);
      const data: any[] = await userService.listRoles(ControllerRequisition.signal);
      let roles = data;
      if (user.role === UserRole.ROLE_MANAGER || user.role === UserRole.ROLE_SUPERVISOR) {
        roles = data.filter(({ role }) => role !== 'ADMIN');
      }
      setRoles(roles);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro. Tente novamente em alguns segundos';
      message.error(err);
    }
  };

  const handleSaveEmployee = async () => {
    try {
      setLoading(true);

      const { name, email, partnerId, roleName } = form;

      if (!name || name === '') {
        setLoading(false);
        return message.error('O campo nome não pode ser vazio.');
      }

      if (!email || email === '') {
        setLoading(false);
        return message.error('O campo email não pode ser vazio.');
      }

      if (!roleName || roleName === '') {
        setLoading(false);
        return message.error('Selecione o perfil de permissão.');
      }

      await userService.createEmployee(name, email, partnerId, roleName);

      setForm({
        name: '',
        email: '',
        roleName: UserRole.ROLE_OPERATOR,
        partnerId: user.partner.id,
      });

      message.success(`${roleSelected.name} cadastrado com sucesso!`);
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro. Tente novamente em alguns segundos';
      message.error(err);
    }
  };

  useEffect(() => {
    if (user) {
      setLoadingContext(false);
      setForm({
        name: '',
        email: '',
        roleName: UserRole.ROLE_OPERATOR,
        partnerId: user.partner.id,
      });
      fetchRoles();
    }
    return () => ControllerRequisition.abort();
  }, [user]);

  return (
    <Container>
      <h2>Cadastrar usuário</h2>
      {loading && <Loading relative/>}
      <RegisterEmployeeForm>
        <Input
          type="text"
          placeholder="Nome:"
          value={form.name}
          onChange={(value: string) => setForm({ ...form, name: value })}
        />

        <div className="div-email-tooltip">
          <InfoTooltip text={'O e-mail é utilizado para logar na plataforma'} position={'right'} />
          <Input
            type="text"
            placeholder="E-mail:"
            value={form.email}
            onChange={(value: string) => setForm({ ...form, email: value })}
          />
        </div>

        <Select
          placeholder="-- Selecione o perfil --"
          firstDisabled
          optionKeys={{ value: 'role', displayName: 'name' }}
          options={roles as any[]}
          value={form.roleName}
          onChange={(value: any) => setForm({ ...form, roleName: value })}
        />

        <div className="buttons-register-cancel">
          <Button color="primary" text={`Cadastrar ${roleSelected.name.toLowerCase()}`} onClick={handleSaveEmployee} />
          <Button color="secondary" text="Cancelar" onClick={() => history.push('/dashboard')} />
        </div>
      </RegisterEmployeeForm>
    </Container>
  );
};

export default RegisterEmployee;
