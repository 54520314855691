import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from './styled';
import { useLocation, useParams } from 'react-router-dom';
import cpfConvert from '../../../../../../utils/cpf-complete';

function CustomLP({ pageCustom }) {
  const regexp = /{[A-Za-z0-9_]+}/g;

  const error = false;
  const helperText = true;
  const [cpf] = useState('000.000.000-00');

  const [customer /*, setCustomer*/] = useState({
    namePartner: 'N&N Assessoria',
    site_contact: 'www.com',
    creditor: '',
    clientName: 'Shitzy Teskl',
    cpf: '000.XXX.000-00',
    pageText: '',
    name_cnpj: 'N&N Assessoria',
    document: '000.000.000-00',
    sigla: 'Assessoria',
  });

  const formatMessage = (message) => {
    let found = message.match(regexp);
    let formatedMessage = message;
    if (found) {
      // eslint-disable-next-line
      found.map((item) => {
        let filterItem = item.replace('{', '').replace('}', '');

        let position = pageCustom.file.response.data.headers.indexOf(filterItem);

        if (filterItem === 'CPF_CNPJ') {
          formatedMessage = formatedMessage.replace(
            item,
            cpfConvert(pageCustom.file.response.data.firstLine[position])
          );
        } else if (filterItem !== 'Link') {
          formatedMessage = formatedMessage.replace(
            item,
            pageCustom.file.response.data.firstLine[position]
          );
        } else {
          formatedMessage = formatedMessage.replace(item, 'https://iquitei.com/xyz');
        }
      });

      return formatedMessage;
    } else {
      return message;
    }
  };

  return (
    <Container className="container">
      <div className="headline" style={{ backgroundImage: `url(${pageCustom?.headlineImage})` }}>
        <div className="logo">
          {(pageCustom.creditor && pageCustom.creditor.creditorImage) && (
            <img
              style={{width: '50%'}}
              src={pageCustom.creditor.creditorImage}
              alt="logo"
            />
          )}
          <br/>
          {(pageCustom.creditor && pageCustom.creditor.partnerImage) && (
            <img
              src={pageCustom.creditor.partnerImage}
              style={{ width: '50%' }}
              alt="hapiness"
            />
          )}
        </div>
        <span
          className="headline-text"
          dangerouslySetInnerHTML={{__html: `${formatMessage(
            pageCustom.headlineText
            || `Aproveite o desconto e negocie sua dívida com a ${pageCustom.creditor.partnerName} e dê
          um passo em direção à mudança de vida!`)}`}}
        />

        <button
          className="button-negotiate"
          onClick={() => {}}
        >
          {pageCustom.headlineButton || 'Clique aqui para negociar com desconto!'}
        </button>
      </div>
    </Container>
  );
}

export default CustomLP;
