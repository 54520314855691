import styled from 'styled-components';
import { Progress } from 'antd';

import theme from '../../theme';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  z-index: 100;

  width: 100%;
  height: 100%;

  overflow-x: auto;

  padding: 15px;

  .content-assinature {
    padding: 0 10px;
  }

  @media only screen and (max-width: 1500px) {
    .content-assinature {
      padding: 0;
    }
  }
`;

export const Title = styled.h1`
  position: relative;
  font-style: normal;
  font-size: 20px;

  margin-left: 10px;

  color: #232323;
`;

export const MetricContent = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: space-between;

  margin-top: 27px;

  @media only screen and (max-width: 1000px) {
    margin-top: 100px;
  }

  @media only screen and (max-width: 1500px) {
    margin-top: 10px;
    flex-direction: column;
  }

  /* .delay-1 { animation-delay: 0.1s; }  
  .delay-2 { animation-delay: 0.2s; }
  .delay-3 { animation-delay: 0.3s; } */
`;

interface CardProps {
  background?: string;
  active?: boolean;
}

export const CardMetric = styled.div<CardProps>`
  position: relative;

  display: flex;
  flex-direction: column;

  background: ${({ active, background }) => (!active ? 'rgba(0,0,0,0.9)' : background)};

  cursor: ${({ active }) => (!active ? 'not-allowed' : '')};

  width: 100%;
  height: 108px;

  box-shadow: 0px 11px 20px rgba(38, 15, 99, 0.2);
  border-radius: 5px;

  margin: 0 10px;
  padding: 15px;

  .text-metric {
    text-align: start;

    font-size: 0.95em;
    font-weight: bold;
  }

  @media only screen and (max-width: 1500px) {
    margin: 15px 0;
  }

  animation-duration: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;

export const ContentAssinature = styled.div`
  display: flex;
  flex-direction: column;

  box-shadow: 0px 11px 20px rgba(38, 15, 99, 0.2);
  border-radius: 5px;

  padding: 18px;

  h4.title-text {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 7px;

    strong {
      color: #2cdcff;
    }
  }

  animation-duration: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;

export const ContainerRangeAssinaturePercent = styled.div`
  display: flex;
  flex-direction: column;

  padding: 10px;

  border-radius: 5px;

  .container {
    position: relative;
    display: flex;

    border-radius: 5px;

    div.input-group {
      display: flex;
      flex-direction: column;
      flex: 1;

      border-radius: 5px;

      background-color: #e8ecff;

      margin-right: 10px;

      padding: 0px 10px;
    }

    p.text {
      position: relative;
      top: 15px;

      padding: 5px 0;

      line-height: 16.8px;
      font-size: 14px;
    }

    p.text-amount {
      line-height: 16.8px;
      font-size: 14px;
    }
  }
`;

export const RangeAssinaturePercent = styled(Progress)``;

export const AmountUsed = styled.div`
  display: flex;
  flex-direction: column;

  max-width: 218px;

  background-color: #e8ecff;

  border-radius: 5px;

  padding: 18px;
`;

export const Metric = styled.div`
  display: flex;

  .percent {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;

    margin: auto;

    line-height: 30px;
  }

  strong.amount-us {
    font-style: normal;
    font-weight: 700;
    font-size: 25px;

    line-height: 30px;
  }
`;

export const ContainerDash = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 17px;

  margin-top: 27px;

  padding: 10px;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
    grid-row-gap: 17px;

    padding: 0;
  }

  .title-dash {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;

    line-height: 14px;

    text-align: center;

    text-transform: uppercase;

    color: #232323;
  }
`;

export const Dash = styled.div`
  flex: 1;
  position: relative;

  padding: 20px;

  height: 302px;

  box-shadow: 0px 10px 10px rgba(38, 15, 99, 0.2);
  border-radius: 5px;

  animation-duration: 0.5s;
  animation-name: animate-pop;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);

  @keyframes animate-pop {
    0% {
      opacity: 0;
      transform: scale(0.5, 0.5);
    }

    100% {
      opacity: 1;
      transform: scale(1, 1);
    }
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: column;

  align-items: flex-end;

  @media only screen and (max-width: 1000px) {
    align-items: center;
  }

  margin-right: 15px;
  margin-bottom: 17px;

  div.content-text {
    position: relative;
    top: 10px;
    display: flex;

    width: 200px;

    justify-content: flex-start;
  }

  p.filter-name {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;

    line-height: 17px;

    color: #232323;
  }

  select {
    position: relative;

    display: flex;

    flex-direction: row;
    align-items: flex-start;

    padding: 4px 12px;

    width: 200px;

    background: #ffffff;
    border: 1px solid #c3c3c3;
    border-radius: 2px;

    option {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
    }
  }
`;

interface PlaneCardProps {
  active: boolean;
}

export const PlaneCard = styled.div<PlaneCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;

  background: rgb(255, 255, 255);

  transform: scale(${({ active }) => (active ? 1.1 : '')});

  border: 1px solid rgba(237, 237, 237, 1);

  border-radius: 10px;

  padding: 10px;
  box-shadow: ${({ active }) => (active ? '0 0 6px 2px rgba(0,0,0,0.2)' : 'none')};
  background: ${({ active }) =>
    active
      ? 'linear-gradient(100deg, rgba(255,255,255,1) 0%, rgba(237,237,237,1) 53%, rgba(255,255,255,1) 100%)'
      : ''};

  transition: 0.325s;
  &:hover {
    transform: scale(1.1);
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.2);

    background: linear-gradient(
      100deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(237, 237, 237, 1) 53%,
      rgba(255, 255, 255, 1) 100%
    );
  }
`;
