import { createContext } from 'react';

interface LoadingState {
  loading: boolean;
  setLoading: (value: boolean) => void;
}

const initialState: LoadingState = {
  loading: false,
  setLoading: (value: boolean) => {},
};

const LoadingContext = createContext<LoadingState>(initialState);

const LoadingConsumer = LoadingContext.Consumer;

export { LoadingContext, LoadingConsumer };

export type { LoadingState };
