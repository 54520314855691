import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { Button, Input, TextArea } from '../../../../../../components';
import { Modal } from 'antd';
import { useContext, useState, useEffect, createContext } from 'react';
import NumberFormat from 'react-number-format';
import { InputElement } from '../../../../../proposals-deny/styled';

interface ModalShipmentDetailsProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  onSaveButton: (value: any) => any;
}

const ModalNewType: React.FC<ModalShipmentDetailsProps> = ({ isOpen, onClose, onSaveButton }) => {
  const [form, setFormText] = useState<any>({
    name: '',
    value: '0.00',
  });

  const handleCancel = () => {
    onClose(false);
    setFormText({
      name: '',
      value: 0,
    });
  };

  const checkUndefined = (item) => !checkUndefined || item.toString().trim() === '';

  return (
    <Modal
      title="Novo tipo de SMS"
      cancelText={'Fechar'}
      visible={isOpen}
      onCancel={handleCancel}
      footer={
        <div className="modal-footer-buttons">
          <Button
            color="primary"
            text="Salvar"
            icon={<CheckOutlined />}
            onClick={() => onSaveButton(form)}
          />
        </div>
      }
    >
      <div>
        <label>Nome :</label>
        <Input
          type="text"
          value={form.name}
          placeholder="Nome do tipo"
          onChange={(value) => setFormText({ ...form, name: value })}
        />
      </div>
      <div>
        <label>Valor por SMS :</label>
        {/* <Input
          type="number"
          value={form.value}
          placeholder="Valor do tipo"
          onChange={(value) => setFormText({...form, value: value})}
          
        /> */}

        <NumberFormat
          thousandsGroupStyle="thousand"
          value={Number(form.value)}
          prefix="R$ "
          decimalSeparator=","
          displayType="input"
          type="text"
          thousandSeparator="."
          decimalScale={2}
          fixedDecimalScale={true}
          customInput={InputElement}
          onValueChange={({ value }) => setFormText({ ...form, value: value })}
        />
      </div>
    </Modal>
  );
};

export default ModalNewType;
