import React from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';

import { Content } from '../DateRange/styled';

registerLocale('pt-BR', ptBR);

interface DatePickerProps {
  value: Date;
  onChange?: (date: Date) => any;
  placeholder?: string;
  disabled?: boolean;
  pattern?: string;
  showTimeSelect?: boolean;
}

const style = {
  marginTop: '4px',
};


const DatePicker: React.FC<DatePickerProps> = ({
  value,
  onChange,
  placeholder,
  disabled,
  pattern = 'dd/MM/yyyy',
  showTimeSelect,
}) => {
  return (
    <div>
      <ReactDatePicker
        className="react-datepicker"
        placeholderText={placeholder}
        dateFormat={pattern}
        showTimeSelect={showTimeSelect}
        selected={value}
        disabled={disabled}
        locale={ptBR}
        onChange={(date: Date) => onChange && onChange(date)}
      />
    </div>
  );
};

export default DatePicker;
