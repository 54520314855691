import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;

  flex-direction: column;

  .ant-table-tbody > tr > td {
    display: flex;

    align-items: center;
    justify-content: space-between;
    
    padding: 8px;
  }

  .table {
    padding: 10px;
  }
  .table:nth-child(even) {
    background-color: ${theme.colors.light};
  }
  .table:nth-child(odd) {
    background-color: ${theme.colors.lightGrey};
  }
`;

export const ContentModal = styled.div`
  display: flex; 
  flex-direction: column; 
  justify-content: center;
  padding: 20px;
  margin: 20px 0;

  .ant-table-tbody > tr > td {
    display: flex;

    align-items: center;
    justify-content: space-between;
    
    padding: 8px;
  }

  .table {
    padding: 10px;
  }
  .table:nth-child(even) {
    background-color: ${theme.colors.light};
  }
  .table:nth-child(odd) {
    background-color: ${theme.colors.lightGrey};
  }

  span {
    margin-bottom: 10px;
  }
`;

export const BlockListItem = styled.div`
  display: flex; 
  width: 100%;
  align-items: center; 
  justify-content: space-between;
`;