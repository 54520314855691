import { formatInTimeZone } from 'date-fns-tz';
import { addDays, format } from 'date-fns';
import formatLocale from 'date-fns/locale/pt-BR';

const timeZone = 'America/Sao_Paulo';

export const formattedDate = (
  date: Date,
  format: string = 'dd/MM/yyyy',
  amount: number = 0
) => {

  const subtractDays = addDays(date, amount);

  return formatInTimeZone(subtractDays, timeZone, format);
};

export const formattedFullDate = (date: Date, format: string = 'yyyy-MM-dd HH:mm:ss') => {
  return formatInTimeZone(date, timeZone, format);
};

const timeZoneZero = 'Europe/London';

export const formattedFullDateNoTimeZone = (date: Date, format: string = 'yyyy-MM-dd HH:mm:ss') => {
  return formatInTimeZone(date, timeZoneZero, format);
};

export const formatDate = (date: Date, dateFormat: string = 'yyyy-MM-dd HH:mm:ss') => {
  return format(date, dateFormat, {locale:formatLocale});
};