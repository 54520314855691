import styled from 'styled-components';
import theme from '../../../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background: ${theme.colors.primary};
    border-color: ${theme.colors.primary};
  }

  .ant-steps-item-finish .ant-steps-item-icon {
    background: ${theme.colors.primary};
    border: ${theme.colors.white};
  }

  .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: ${theme.colors.white};
  }

  .ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    background: ${theme.colors.primary};
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  flex: auto;
  align-items: center;
  border-radius: ${theme.border.radius};
  margin-bottom: 10px;
  background: ${theme.colors.white};
  box-shadow: ${theme.boxShadow};
  color: ${theme.colors.primary};
  padding: 40px 30px;

  .divider {
    width: 100%;
    height: 2px;
    background: ${theme.colors.lightGrey};
    margin: 20px 0;
  }

  .steps {
  }

  @media screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const GenerateCampaignContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .step-actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }

  .step-actions .buttons {
    display: flex;
  }

  .step-actions .buttons button {
    margin-left: 10px;
    width: 150px !important;
  }
`;

export const StepUploadFileContainer = styled.div``;
