import { AxiosError } from 'axios';
import { UserRole } from '../entity';
import * as iquiteiSvc from './iquiteiApi.service';

export async function findById(partnerId: number, userId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/users/${userId}`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao buscar as informações do usuário';
    throw err;
  }
}

export async function createEmployee(
  name: string,
  email: string,
  partnerId: number,
  roleName: string = UserRole.ROLE_OPERATOR,
) {
  try {
    const body = {
      name,
      email,
      role: roleName,
    };

    const res = await iquiteiSvc.postRequest(`/partners/${partnerId}/users`, body);

    return res.data.result;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao salvar o usuário.';
    throw err;
  }
}

export async function createManager(name: string, email: string, partnerId: number) {
  try {
    const body = {
      name,
      email,
      role: UserRole.ROLE_MANAGER,
    };

    const res = await iquiteiSvc.postRequest(`/partners/${partnerId}/users`, body);

    return res.data.result;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os acessos';
    throw err;
  }
}

export async function listRoles(signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest('/roles', { signal });

    return res.data.body.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os acessos';
    throw err;
  }
}

export async function updateProfile(
  partnerId: number,
  name: string,
  password: string | null,
  email: string
) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/users`, {
      name,
      password,
      email,
    });

    return res.data.data.message;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao resetar a senha. Tente novamente';
    throw err;
  }
}

export async function updateProfilePicture(partnerId: number, userId: number, imgFile: File) {
  try {
    const body = new FormData();

    body.append('imgFile', imgFile);

    const res = await iquiteiSvc.putRequest(
      `/partners/${partnerId}/users/${userId}/update-picture`,
      body
    );

    const { imageUrl } = res.data.data;

    return imageUrl;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao atualizar a imagem de perfil. Tente novamente.';
    throw err;
  }
}

export async function registerLastLogin(
  partnerId: number,
  userId: number,
) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/users/${userId}/lastLogin`, {});

    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao registrar o login.';
    throw err;
  }
}
