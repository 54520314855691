import { format } from 'd3-format';
import { Container } from './styled';
import { Loading } from '../../providers';

import {
  LineChart as RechartsLineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { uuid4 } from '@sentry/utils';

interface LineChartProps {
  title: string;
  data: any[];
  prefix?: string;
  suffix?: string;
  formatPattern?: string;
  loading?: boolean;
}

const LineChart: React.FC<LineChartProps> = ({
  title,
  data,
  prefix = '',
  suffix = '',
  formatPattern,
  loading,
}) => {
  //Example: '.3s' abbreviates thousands and milions into 2 decimal places.
  const formatValue = (value: any) => {
    if (formatPattern) {
      return `Hora + ${prefix}${format(formatPattern)(value)}${suffix}`;
    }

    return `${prefix}${value}${suffix}`;
  };

  const setColorLineChart = (id: string) => {
    if (id === 'acessos') {
      return '#face0a';
    } else if (id === 'propostas') {
      return '#f70a0a';
    } else if (id === 'confirmações') {
      return '#8c00ff';
    } else if (id === 'cliques') {
      return '#4ace0a';
    } else {
      return '#00d9ff';
    }
  };

  return (
    <Container className="bar-chart">
      {loading && <Loading relative />}
      <div className="title">{title}</div>
      <div className="content">
        <ResponsiveContainer>
          <RechartsLineChart
            data={data
              .filter(({ data: points }) => points.length !== 0)
              .map((item, index) => ({
                ...item,
                data: item.data.sort((a, b) => a.x - b.x),
              }))}
            margin={{
              top: 5,
              right: 20,
              left: 20,
              bottom: 5,
            }}
          >
            <Tooltip />
            <Legend
              iconType="circle"
              iconSize={10}
              align="right"
              verticalAlign="top"
              layout="vertical"
              margin={{ left: 10, right: 10 }}
              key={uuid4()}
            />
            <XAxis
              name="Hora"
              allowDuplicatedCategory={false}
              dataKey="x"
              domain={Array.from(Array(24).keys())}
              key={uuid4()}
            />
            <YAxis dataKey="y" domain={[0, 'dataMax']} />
            {data
              .filter(({ data: points }) => points.length !== 0)
              .map((item) => ({
                ...item,
                data: item.data.sort((a, b) => a.x - b.x),
              }))
              .map(({ id, data: points, index }) => (
                <Line
                  name={id}
                  type="monotone"
                  strokeWidth={2}
                  data={points}
                  dataKey="y"
                  stroke={setColorLineChart(id)}
                  key={uuid4()}
                />
              ))}
          </RechartsLineChart>
        </ResponsiveContainer>
      </div>
    </Container>
  );
};

export default LineChart;
