import { useEffect } from 'react';
import { IphoneContainer } from './styled';

type IPhoneMockupProps = {
  message: string;
  variables: any;
  setSmsLength?: any;
};

const IPhoneMockup: React.FC<IPhoneMockupProps> = ({ message, variables, setSmsLength }: any) => {
  const regexp = /{[A-Za-z0-9_]+}/g;
  
  const formatMessage = (message: string) => {
    let found = message.match(regexp);
    let formatedMessage = message;
    if (found) {
      // eslint-disable-next-line
      found.map((item) => {
        let filterItem = item.replace('{', '').replace('}', '');

        let position = variables.headers.indexOf(filterItem);

        if (filterItem !== 'Link') {
          formatedMessage = formatedMessage.replace(item, variables.firstLine[position]);
        } else {
          formatedMessage = formatedMessage.replace(item, 'https://iquitei.com/p/xyz');
        }
      });
      setSmsLength(formatedMessage.length);
      return formatedMessage;
    } else {
      setSmsLength(message.length);
      return message;
    }
  };

  return (
    <IphoneContainer>
      <div className="iphone-x">
        <i>Speaker</i>
        <b>Camera</b>
        {/* <s>10:24</s> */}

        {message?.length > 0 && (
          <div className="chat">
            <div className="sms">{formatMessage(message)}</div>
          </div>
        )}

        {/* <span>Left action button</span>
        <span>Right action button</span> */}
      </div>
    </IphoneContainer>
  );
};

export default IPhoneMockup;
