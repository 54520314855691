import  { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingContext } from '../../context/loading';
import { Container, IconError } from './styled';
import { getAllErrorsLogs, updateErrorLogById } from '../../service/logErrors.service';
import { AuthContext } from '../../context/auth';
import Select from '../../components/Select';
import { routeParams } from '../../config/routes.config';

import { Loading } from '../../providers';

import successImg from '../../assets/images/success.png';
import coneErrorImg from '../../assets/images/cone-error.png';


import { Table, message } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';

interface SystemErrorsProps {
    date: Date;
    description: string;
    id: string;
    solved: 'Resolvido' | 'Não resolvido';
}

const SystemErrors:React.FC = () => {
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const { setLoading, loading } = useContext(LoadingContext);
    const [load, setLoad] = useState(false);
    
    const [errorsLogs, setErrorsLogs] = useState<SystemErrorsProps[]>();

    const fetchListErrosLogs = async (id) => {
        try {
            setLoad(true);
            const data = await getAllErrorsLogs(id);
            const errors =  data.map((i) => ({
                description: JSON.stringify(i._fieldsProto.description) ? `${i._fieldsProto.description.stringValue}` : 'undefined', 
                solved: i._fieldsProto.solved.stringValue === 'true' ? 'Resolvido' : 'Não resolvido',
                date: i._fieldsProto.createdAt,
                id: i._ref._path.segments[3]
            }));
            
            setErrorsLogs(errors);
            setLoad(false);
        } catch(error: any) {
            setLoad(false);
            const err = typeof error === 'string' ? error : 'Ocorreu um erro';
            message.error(err);
        }
    };

    const formatDescription = (description: string) => {
        if(description.length > 100) {
            return description.slice(0 ,100).concat('...');
        } else {
            return description.concat('.');
        }
    }

    const handleUpdateSolved = async (uid: string, logId: string) => {
        if(errorsLogs) {
            const exist = errorsLogs.some((log) => log.id === logId);
            
            if(exist) {
                const index = errorsLogs.findIndex((i) => i.id === logId);
               
                if(uid === '8') {
                    setErrorsLogs(errorsLogs.map((i, index2) => (index2 === index ? {...i, solved: 'Resolvido'} : i)));
                    await updateErrorLogById(user.partner.id, logId, 'true');
                 } else {
                    setErrorsLogs(errorsLogs.map((i, index2) => (index2 === index ? {...i, solved: 'Não resolvido'} : i)));
                    // CHAMAR A FUNÇÃO PARA UPDATE NO BD
                    await updateErrorLogById(user.partner.id, logId, 'false');
                }
            }   
        }
    }

    const [errorsNotSolved, setErrorsNotSolved] = useState<SystemErrorsProps[]>([]);

    useEffect(() => {
        if(errorsLogs) {
            const errorNotSolved = errorsLogs.filter((i) => 
                i.solved === 'Não resolvido'
            );
            setErrorsNotSolved(errorNotSolved);
        }
    },[errorsLogs]);
    
    useEffect(() => {
        if(user && user.partner) {
            (async () => {
                await fetchListErrosLogs(user.partner.id);
            })();
        }

        setLoading(false);
    }, [user]);

    const options = [
        { _id: '8', name: 'Resolvido' },
        { _id: '10', name: 'Não Resolvido'},
    ];

    const columns = [
        {
            title: '',
            dataIndex: 'solved',
            key: 'solved',
            render: (item) => {
                return (
                    <div style={{ marginRight: 20 }}>
                        { item === 'Resolvido' ? (
                            <img src={successImg} alt='Imagem de sucesso' />
                         ) : (
                            <img src={coneErrorImg} alt='Imagem de cone representando erro' />
                        )}
                    </div>
                )
            },
        },
        {
            title: 'Descrição',
            dataIndex: 'description',
            key: 'description',
            render: (item) => {
                return (
                    <div>
                        <h3 className='text-error'>
                            { formatDescription(item) }
                        </h3>
                    </div>
                )
            },
            sorter: (a: any, b: any) => a.description.length - b.description.length,
        },
        {
            title: 'Data',
            dataIndex: 'date',
            key: 'date',
            render: ({ timestampValue, stringValue }: any) => {
                if(stringValue) {
                    const time = stringValue.timestampValue;
                    const date = new Date(time*1000);
                    const formattDate = date.toLocaleDateString('pt-br');
                    
                    return formattDate;
                }
                if(timestampValue) {
                    const time = timestampValue.seconds;
                    const date = new Date(time*1000);
                    const formattDate = date.toLocaleDateString('pt-br');
                    
                    return formattDate;
                }
            },
            sorter: (a: any, b: any) => a.date.timestampValue.seconds - b.date.timestampValue.seconds,
            
        },
        {
            title: 'Status',
            key: 'id',
            render: ({ id: uid, solved }) => {
                return (
                    <div style={{ display: 'flex', alignItems: 'center'}}>
                        <Select
                            placeholder='--Selecione--'
                            firstDisabled
                            value={solved}
                            optionKeys={{ value: '_id' ,displayName: 'name' }}
                            options={options}
                            onChange={(id: string) => handleUpdateSolved(id, uid)}
                        />
                    </div>
                );
            },
            sorter: (a: any, b: any) => b.solved.length - a.solved.length,
        },
        {
            title: '',
            dataKey: 'id',
            key: 'id',
            render: ({ id }) => {
                return (
                    <button 
                        onClick={() => history.push(`${routeParams.ROUTE_SYSTEM_ERROR_BY_ID}/${id}`)}
                        style={{ all: 'unset', marginLeft: 10 }}
                    >
                    <IconError />
                </button>
                )
            }
        }
    ];

    return (
        <Container>
            <h2 style={{ fontSize: '1.4rem', marginBottom: '60px' }}>
                Erros do sistema
            </h2>
            <button type="button" onClick={async () => fetchListErrosLogs(user.partner.id)} className='reload-button'>
                    <ReloadOutlined />
                    Recarregar
            </button>
            <div style={{ position: 'relative' }}>
                {load && <Loading relative />}
                {errorsLogs && (
                    <Table
                        dataSource={[...errorsNotSolved]}
                        pagination={{ defaultCurrent: 1, defaultPageSize: 10 }}
                        columns={columns}
                        rowKey={(item: any) => item.id}
                        onChange={() => {}}
                        locale={{ 
                            triggerDesc: 'Clique para ordenar de forma decrescente',
                            triggerAsc: 'Clique para ordenar de forma crescente', 
                            cancelSort: 'Clique para cancelar a ordenação'
                        }}
                    />
                )}
            </div>
        </Container>
    )
}

export default SystemErrors;