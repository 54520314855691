import { Container, Logo, Card } from './styled';
import { Button, Input } from '../../components';
import logo from '../../assets/images/iquitei-logo.png';
import { useContext, useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';
import { Loading, showToast } from '../../providers';
import { routeParams } from '../../config/routes.config';
import { AuthContext } from '../../context/auth';
import { LoadingContext } from '../../context/loading';
import { isLoggedIn } from '../../service/auth.service';

export default function Login() {
  const history = useHistory();

  const { authUser } = useContext(AuthContext);
  const { loading, setLoading } = useContext(LoadingContext);

  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  async function handleLogin() {
    try {
      setLoading(true);

      if (checkValidForm(form)) {
        const res: any = await authUser(form.email, form.password);

        if (res === true) {
          return history.push({
            pathname: routeParams.ROUTE_DASHBOARD,
          });
        }
      }
    } catch (error) {
      setLoading(false);
      return false;
    }
  }

  function checkValidForm(form: any) {
    if (!form.email || form.email === '') {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'Você precisa informar qual é o seu e-mail',
      });
    }

    if (!form.password || form.password === '') {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'Você precisa informar qual é a sua senha',
      });
    }

    return true;
  }

  useEffect(() => {
    if (isLoggedIn()) {
      history.push({
        pathname: routeParams.ROUTE_DASHBOARD,
      });
    }

    setLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Card>
          <Logo>
            <img src={logo} alt="logo" />
          </Logo>

          <Input
            type="text"
            placeholder="E-mail:"
            value={form.email}
            onChange={(value: string) => setForm({ ...form, email: value })}
          />
          <Input
            type="password"
            placeholder="Senha:"
            value={form.password}
            onChange={(value: string) => setForm({ ...form, password: value })}
          />

          <div className="forgot-password">
            <Button
              color="no-border"
              text="Esqueceu sua senha?"
              onClick={() => history.push(routeParams.ROUTE_FORGOT_PASSWORD)}
            />
          </div>

          <Button color="primary" text="Entrar" onClick={handleLogin} />
        </Card>
      </Container>
    </>
  );
}
