import { Container, RegisterPartnerForm } from './styled';
import { Input, Button, InfoTooltip, Select } from '../../../components';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingContext } from '../../../context/loading';
import { AuthContext } from '../../../context/auth';
import { message } from 'antd';
import { partnerService, integrationService } from '../../../service';
import { cpfCnpjMask } from '../../../utils/string-format';
import { routeParams } from '../../../config/routes.config';

const RegisterCreditor = () => {
  const { setLoading } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [isEditing, toggleIsEditing] = useState(false);
  const history = useHistory();

  const [creditors, setCreditors] = useState<any[]>([]);

  const [form, setForm] = useState<any>({
    partnerId: '',
    creditorId: '',
  });

  const handleLinkCreditor = async () => {
    try {
      setLoading(true);

      const { partnerId, creditorId } = form;

      if (!partnerId || partnerId === '') {
        setLoading(false);
        return message.error('Ocorreu um erro ao buscar o parceiro.');
      }

      if (!creditorId || creditorId === '') {
        setLoading(false);
        return message.error('Selecione um credor antes de vincular.');
      }

      await Promise.all([
        partnerService.linkPartnerCreditor(partnerId, creditorId),
        integrationService.insertIntegrationCredentials({ partnerId, creditorId, username: '', password: '', isActive: true }),
      ]);

      setCreditors((prevState) => prevState.filter((cred) => cred.creditorId !== creditorId));

      setForm((prevState) => ({ ...prevState, creditorId: '' }));

      setLoading(false);

      message.success('Credor vinculado ao parceiro com sucesso!');
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      const err =
        typeof error === 'string'
          ? error
          : 'Ocorreu um erro ao tentar vincular o credor ao parceiro';
      message.error(err);
    }
  };

  async function fetchAllCreditors(partnerId) {
    const response: any[] = await partnerService.getAllCreditorsV2(partnerId);

    const partnerLinkedCreditors: any[] = await partnerService.getPartnerCreditorsV2(partnerId);

    const allowedCreditors = response.filter((c) =>
      !partnerLinkedCreditors.some((lc) => c.creditorId === lc.creditorId)
    );

    setCreditors(allowedCreditors);
  }

  useEffect(() => {
    setLoading(false);

    if (user) {
      const partnerId = user.partner.id;
      fetchAllCreditors(partnerId);
      setForm({ ...form, partnerId });
    }
  }, [user]);

  return (
    <Container>
      <h2>Cadastrar credor</h2>

      <RegisterPartnerForm>
        <Select
          value={form.creditor}
          placeholder={'-- Selecione --'}
          options={creditors}
          firstDisabled
          optionKeys={{ value: 'creditorId', displayName: 'name' }}
          onChange={(value) => setForm({ ...form, creditorId: value })}
        />

        <div className="buttons-register-cancel">
          <Button color="primary" text={'Vincular credor'} onClick={handleLinkCreditor} />
          <Button
            color="secondary"
            text="Voltar"
            onClick={() => history.push(routeParams.ROUTE_INTEGRATION_CONFIGURATION)}
          />
        </div>

        <div className="partner-image-preview">
          {isEditing && form.imageUrl && <img src={form.imageUrl} alt="partner" />}
        </div>
      </RegisterPartnerForm>
    </Container>
  );
};

export default RegisterCreditor;
