import { useState, useEffect, useContext } from 'react';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';
import { message } from 'antd';

import { AuthContext } from '../../../../context/auth';
import { Input, Button, Select } from '../../../../components';
import Modal from './components/modal';
import { Loading } from '../../../../providers';
import { operatorsService, operatorsTypeService } from '../../../../service';
import { Container } from './styled';
import NumberFormat from 'react-number-format';
import { InputElement } from '../../../proposals-deny/styled';

export default function OperatorsManagerComponent() {
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(true);
  const [operators, setOperators] = useState([]);
  const [operator, setOperator] = useState('');
  const [types, setTypes] = useState<any[]>([]);
  const [type, setType] = useState('');
  const [name, setName] = useState('');
  const [typeName, setTypeName] = useState('');
  const [value, setValue] = useState('0.00');
  const [openModalNewType, setOpenModalNewType] = useState(false);
  const [isEditing, toggleIsEditing] = useState(false);

  const ControllerRequisition = new AbortController();

  useEffect(() => {
    if (user && user.partner) {
      (async () => {
        const partnerId = user.partner.id;
        const operatorList = await operatorsService.getOperatorsInfo(
          partnerId,
          ControllerRequisition.signal
        );
        setLoading(false);

        if (operatorList.length > 0) {
          setOperators(operatorList);
        }
      })();
    }
    return () => ControllerRequisition.abort();
  }, [user]);

  useEffect(() => {
    if (operator && operators.length > 0) {
      const op: any = operators.find(({ id }) => id === Number(operator));

      setName(op.name);
      setTypes(op.types);
      if (op.types.length > 0) {
        setType(op.types[0].id);
        setTypeName(op.types[0].type);
      }
    }
  }, [operator]);

  useEffect(() => {
    if (type && types.length > 0) {
      const typ: any = types.find(({ id }) => id === Number(type));
      setValue(typ.value);
      setTypeName(typ.type);
    }
  }, [type]);

  const reloadOperators = async () => {
    try {
      setLoading(true);
      const partnerId = user.partner.id;
      const operatorList = await operatorsService.getOperatorsInfo(
        partnerId,
        ControllerRequisition.signal
      );
      setLoading(false);

      if (operatorList) {
        setOperators(operatorList);
      }
      // setOperator(operatorList[0].id);
    } catch (error) {
      console.error(error);
      message.error('Falha ao recarregar as operadoras');
    }
  };

  const onDeleteButton = async () => {
    try {
      await operatorsService.deleteOperators(user.partner.id, Number(operator));
      message.success('Operadora excluída com sucesso');
      await reloadOperators();
    } catch (e) {
      console.error(e);
      message.error('Falha ao excluir operadora, tente novamente em alguns segundos');
    }
  };

  const onSaveButton = async () => {
    try {
      await Promise.all([
        operatorsService.updateOperators(user.partner.id, Number(operator), {
          name,
        }),
        operatorsTypeService.updateOperatorsType(user.partner.id, Number(type), {
          name: typeName,
          value,
        }),
      ]);
      message.success('Operadora alterado com sucesso');
      await reloadOperators();
    } catch (e) {
      console.error(e);
      message.error('Falha ao alterar operadora, tente novamente em alguns segundos');
    }
  };

  const onCreateButton = async () => {
    try {
      const newOperator = await operatorsService.createOperators(user.partner.id, {
        name,
      });

      await operatorsTypeService.createOperatorsType(user.partner.id, {
        name: typeName,
        value,
        operatorsId: newOperator.insertId,
      });
      message.success('Operadora criada com sucesso');
      await reloadOperators();
    } catch (e) {
      console.error(e);
      message.error('Falha ao cadastrar operadora, tente novamente em alguns segundos');
    }
  };

  const onCreateTypeButton = async (form) => {
    try {
      await operatorsTypeService.createOperatorsType(user.partner.id, {
        ...form,
        operatorsId: Number(operator),
      });
      message.success('Tipo de SMS criada com sucesso');
      setOpenModalNewType(false);
      await reloadOperators();
    } catch (e) {
      console.error(e);
      message.error('Falha ao cadastrar tipo de SMS, tente novamente em alguns segundos');
    }
  };

  const onDeleteTypeButton = async () => {
    try {
      await operatorsTypeService.deleteOperatorsType(user.partner.id, Number(type));
      message.success('Tipo de SMS excluída com sucesso');
      await reloadOperators();
    } catch (e) {
      console.error(e);
      message.error('Falha ao excluir tipo de SMS, tente novamente em alguns segundos');
    }
  };

  return (
    <Container className="text-negotiations" style={{ maxWidth: '1000px' }}>
      <h3>Gerenciamento de operadoras de SMS:</h3>
      <div>
        <label htmlFor="isEditing-create-partner" className="isEditing-label">
          <input
            id="isEditing-create-partner"
            type="checkbox"
            className="switch"
            checked={isEditing}
            onClick={() => {
              toggleIsEditing(!isEditing);
            }}
          />
          <span>Modo editor</span>
        </label>
        <br />
        {isEditing && operators.length > 0 && (
          <>
            <label>Operadoras:</label>
            <div className="about-tab">
              <div className="text-area">
                {loading && <Loading relative />}
                {operators.length > 0 && (
                  <Select
                    placeholder="Selecione a operadora"
                    firstDisabled
                    optionKeys={{ value: 'id', displayName: 'name' }}
                    options={operators as any[]}
                    value={operator}
                    onChange={setOperator}
                  />
                )}
              </div>
            </div>
          </>
        )}
        <label> Nome da operadora:</label>
        <div className="input">
          <Input type="text" value={name} onChange={(value: any) => setName(value)} />
        </div>

        {types.length > 0 && isEditing && (
          <div className="about-tab">
            <div className="text-area">
              {loading && <Loading relative />}
              {types.length > 0 && (
                <>
                  <label> Tipos:</label>
                  <Select
                    placeholder="Selecione o tipo do SMS"
                    firstDisabled
                    optionKeys={{ value: 'id', displayName: 'type' }}
                    options={types as any[]}
                    value={type}
                    onChange={setType}
                  />
                </>
              )}
            </div>
            <div className="line-text">
              <Button
                color="newButton"
                text="Novo tipo de SMS"
                icon={<PlusOutlined />}
                onClick={() => setOpenModalNewType(true)}
              />
              <Button
                color="deleteButton"
                text="Excluir tipo de SMS"
                icon={<CloseOutlined />}
                onClick={onDeleteTypeButton}
              />
            </div>
          </div>
        )}
        <label> Nome do tipo:</label>
        <div className="input">
          <Input type="text" value={typeName} onChange={(value: any) => setTypeName(value)} />
        </div>

        <label>Valor do SMS:</label>

        <div className="input">
          {/* <Input type="number" value={value} onChange={(value: any) => setValue(value)} /> */}
          <NumberFormat
            thousandsGroupStyle="thousand"
            value={Number(value)}
            prefix="R$ "
            decimalSeparator=","
            displayType="input"
            type="text"
            thousandSeparator="."
            decimalScale={2}
            fixedDecimalScale={true}
            customInput={InputElement}
            onValueChange={({ value }) => setValue(value)}
          />
        </div>
      </div>

      <div className="btn">
        {isEditing && operators.length > 0 ? (
          <>
            <div className="button" style={{ marginRight: 10 }}>
              <Button color="disabled" text="Excluir" onClick={onDeleteButton} />
            </div>
            <div className="button">
              <Button color="primary" text="Editar" onClick={onSaveButton} />
            </div>
          </>
        ) : (
          <div className="button">
            <Button color="primary" text="Cadastrar" onClick={onCreateButton} />
          </div>
        )}
      </div>
      <Modal
        isOpen={openModalNewType}
        onClose={() => setOpenModalNewType(false)}
        onSaveButton={onCreateTypeButton}
      />
    </Container>
  );
}
