// ### Libraries
import { useEffect, useContext } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { Table } from 'antd';

// ### Local imports
import { Button } from '../../../../components';
import { Container } from './styled';
import { formattedFullDateNoTimeZone } from '../../../../utils/date-format';
import { AuthContext } from '../../../../context/auth';
import { LoadingContext } from '../../../../context/loading';
import { routeParams } from '../../../../config/routes.config';
import { textSpanContainsTextSpan } from 'typescript';
import { array, object } from 'joi';

//array com os dados:

const ShipmentErrors = () => {
  const history: any = useHistory();

  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);

  const columns = [
    {
      title: 'Id da campanha',
      dataIndex: 'shipment_id',
      key: 'shipment_id',
    },
    {
      title: 'Data do erro',
      dataIndex: 'date',
      key: 'date',
      render: (value: any) => {
        return formattedFullDateNoTimeZone(value, 'dd/MM/yyyy HH:mm');
      },
      align: 'center' as const,
    },
    {
      title: 'Credor do erro',
      dataIndex: 'creditorName',
      key: 'creditorName',
      align: 'center' as const,
    },
    {
      title: 'Nome do cliente',
      dataIndex: 'name',
      key: 'name',
      align: 'center' as const,
    },
    {
      title: 'Telefones',
      dataIndex: 'phone',
      key: 'phone',
      align: 'center' as const,
    },
    {
      title: 'CPF do cliente',
      dataIndex: 'cpf',
      key: 'cpf',
      align: 'center' as const,
    },
    {
      title: 'Log do erro',
      dataIndex: 'log',
      key: 'log',
      align: 'center' as const,
    },
  ];
 
  useEffect(() => {
    (async () => {
      if (history.location.state.creditorError) {
        const { partnerId, shipmentId } = history.location.state;
        setLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  return (
    <Container>
      <h2>Erros de Integração</h2>
      <div className="actions">
        <div className="btn-content">
          <Button
            color={'primary'}
            text='Voltar aos detalhes'
            icon={<ArrowLeftOutlined />}
            onClick={() => history.push(routeParams.campaigns.ROUTE_CAMPAIGN_DETAILS, {
              partnerId: history.location.state.campaign.partnerId,
              shipmentId: history.location.state.campaign.shipmentId,
            })}
          />
        </div>
      </div>
      <div className="shipment-errors">
        <Table
          dataSource= {history.location.state.creditorError}
          columns={columns}
          pagination={{ defaultCurrent: 1, defaultPageSize: 10 }}
          rowKey={(item: any) => item.date}
        />
      </div>
    </Container>
  );
};

export default ShipmentErrors;
