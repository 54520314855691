import axios from 'axios';
import jwtDecode from 'jwt-decode';
import * as dateFns from 'date-fns';

import { firebaseConfig, params } from '../config';

export async function login(username: string, password: string) {
  try {
    const res: any = await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
      username,
      password,
    });

    const { token } = res.data.data;

    const userData = jwtDecode(token);

    return {
      userData,
      token,
    };
  } catch (error: any) {
    console.error({error})
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao entrar';
    throw err;
  }
}

export async function checkUser(email: string) {
  try {
    const res: any = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/update-password/check-user`,
      {
        username: email,
      }
    );

    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function sendForgotPassword(email: string) {
  try {
    const res: any = await axios.post(
      `${process.env.REACT_APP_API_URL}/auth/update-password/send-email`,
      {
        username: email,
      }
    );

    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao logar';
    throw err;
  }
}

export const logout = async (): Promise<any> => {
  localStorage.removeItem(params.PARAMS_USER);
  localStorage.removeItem(params.PARAMS_AUTH_TOKEN);

  return window.location.reload();
};

export function isLoggedIn() {
  let user: any = localStorage.getItem(params.PARAMS_USER);

  user = JSON.parse(user);

  if (user) {
    if (
      !user?.lastVerifiedLogin ||
      dateFns.isBefore(new Date(user?.lastVerifiedLogin), new Date('2021-12-11T00:00'))
    ) {
      return logout();
    }

    return user;
  }

  return null;
}

export const register = (email: string, password: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    firebaseConfig.auth.createUserWithEmailAndPassword(email, password).then(
      (data: any) => {
        return resolve(data);
      },
      (error) => {
        throw reject(error);
      }
    );
  });
};
