import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  flex: 1;
  width: 100%;

  .container-status {
    display: flex;
    background-color: ${theme.colors.light};

    justify-content: center;

    padding: 2px 8px;

    border-radius: 2px;
    border: 2px solid ${theme.colors.lightGrey};

    cursor: not-allowed;
  }

  .ant-table-tbody > tr > td {
    padding: 8px;
  }

  .status {
    font-size: 1.1em;

    text-align: center;
    margin-top: 5px;

    color: ${theme.colors.success};
  }

  .modal-row {
    margin-right: 10px;
  }

  .table {
    padding: 10px;
  }
  .table:nth-child(even) {
    background-color: ${theme.colors.light};
  }
  .table:nth-child(odd) {
    background-color: ${theme.colors.lightGrey};
  }

  .proposal-list {
    position: relative;
    min-height: 200px;
  }

  .filters {
    display: flex;

    margin-top: 43px;

    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1200px) {
      flex-direction: column;
      padding: 10px;
    }
  }

  .filters-proposal {
    display: flex;

    @media only screen and (max-width: 1200px) {
      margin-bottom: 20px;
    }
  }

  .filterSelect {
    display: flex;

    align-items: center;
  }

  .selectFilter {
    width: 222px;
  }

  .input {
    border: 2px solid red;
  }
`;

interface FilterProps {
  active?: boolean;
}

export const Filter = styled.button<FilterProps>`
  display: flex;

  align-items: center;

  width: 130px;
  height: 40px;

  margin-top: 15px;

  justify-content: center;

  cursor: pointer;
  border: 1px solid ${theme.colors.grey_300};

  background-color: ${({ active }) =>
    !active ? `${theme.colors.light}` : `${theme.colors.primary}`};
  color: ${({ active }) => (!active ? `${theme.colors.dark}` : `${theme.colors.white}`)};

  border-radius: 2px 2px 0 0;
  padding: 21px 10px 10px 8px;

  .filter {
    display: flex;
    align-items: center;
  }

  .icon {
    font-size: 15px;
    margin-right: 10px;

    margin-bottom: 12px;
  }

  .title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 700;
  }
`;

interface InputProps {
  disabled: boolean;
}

export const InputElement = styled.input<InputProps>`
  border: 1px solid ${theme.colors.lightGrey};
  border-radius: 5px;

  padding: 0 10px;

  background-color: ${({ disabled }) => (disabled ? theme.colors.disabled : theme.colors.white)};
  color: ${({ disabled }) => (disabled ? theme.colors.grey_300 : theme.colors.dark)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : '')};

  transition: 0.325s;

  &:focus {
    box-shadow: 0 0 1.5px 1.5px ${theme.colors.input_focus};
  }
`;
