import { showToast } from '../providers';

export function getFileMimeType(fileExtension: string) {
  switch (fileExtension) {
    case 'csv':
      return 'text/csv';
    default:
      throw showToast({ type: 'error', message: 'Formato de arquivo inválido!' });
  }
}
