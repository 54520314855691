const devCredentials: any = {
  apiKey: 'AIzaSyDupilAFyYOEUJcfTyyThxbfdwDpKhdMnA',
  authDomain: 'vhg-dash-dev.firebaseapp.com',
  projectId: 'vhg-dash-dev',
  storageBucket: 'vhg-dash-dev.appspot.com',
  messagingSenderId: '438082222286',
  appId: '1:438082222286:web:f0dbb6535da7a5ca13b89f',
  measurementId: 'G-VRZFGT5CZT',
};
const hmlCredentials: any = {
  apiKey: 'AIzaSyAtzL-I_FiwCsQ8tr2-ZHjoWCvOteoSeSQ',
  authDomain: 'vhg-dash-hml.firebaseapp.com',
  projectId: 'vhg-dash-hml',
  storageBucket: 'vhg-dash-hml.appspot.com',
  messagingSenderId: '763488644737',
  appId: '1:763488644737:web:8d6af29b142bc92d026780',
};

const prodCredentials: any = {
  apiKey: 'AIzaSyCaKd9yBMHnTqUqq9soR4ui7B-jmOV-jZk',
  authDomain: 'vhg-dash-prod.firebaseapp.com',
  projectId: 'vhg-dash-prod',
  storageBucket: 'vhg-dash-prod.appspot.com',
  messagingSenderId: '881130686273',
  appId: '1:881130686273:web:febff8bf19e13b337b43c0',
  measurementId: 'G-NHF9CJG3JQ',
};

export default function getFirebaseCredentials(projectId: string): any {
  if (projectId === 'vhg-dash-prod') {
    return prodCredentials;
  } else if (projectId === 'vhg-dash-hml') {
    return hmlCredentials;
  } else {
    return devCredentials;
  }
}
