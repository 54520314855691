import { createContext } from 'react';

interface PartnerState {
  partnerSelected: string | number;
  setPartnerSelected: (value: string | number) => void;
}

const initialState: PartnerState = {
  partnerSelected: 0,
  setPartnerSelected: (value: string | number) => {},
};

const PartnerContext = createContext<PartnerState>(initialState);

const PartnerConsumer = PartnerContext.Consumer;

export { PartnerContext, PartnerConsumer };

export type { PartnerState };
