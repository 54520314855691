import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  align-items: center;
  background-color: ${theme.colors.light};
  display: flex;
  flex: 1;
  flex-direction: column;
  left: 0;
  height: 100%;
  min-height: 100vh;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1000;

  .logo-image {
    width: 10%;
  }
  
  h2 {
    font:size: 25px;
    font-weight: 700;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    margin: 50px 0;
  }

  span {
    font-size: 20px;
    font-weight: 500;
  }

  .error-image {
    margin: 50px 0;
    width: 25%;
  }

  #layout-footer {
    text-align: center;
    width: 100%;
  }
`;
