import { format } from 'd3-format';
import { Container } from './styled';
import { Loading } from '../../providers';

import { 
  FunnelChart as FunnelChartLibrary,
  LabelList,
  Tooltip,
  Funnel,
  ResponsiveContainer,
  Legend,
} from 'recharts';

interface FunnelChartProps {
  title: string;
  data: any[];
  colors: any[];
  keys: string[];
  indexBy: string;
  axisBottomName?: string;
  axisLeftName?: string;
  prefix?: string;
  suffix?: string;
  formatPattern?: string;
  label?: string;
  loading?: boolean;
}

const FunnelChart: React.FC<FunnelChartProps> = ({
  title,
  data,
  colors,
  keys,
  indexBy,
  axisBottomName = '',
  axisLeftName = '',
  prefix = '',
  suffix = '',
  formatPattern,
  loading,
  label,
}) => {

  const renderLegend = (props) => {
    const { payload } = props;
    return (
      <ul>
        {
          payload.map((entry, index) => (
            <li key={`item-${index}`} style={{ color: entry.fill, fontWeight: 'bold' }}>{entry.etapa}</li>
          ))
        }
      </ul>
    );
  }

  return (
    <Container className="bar-chart">
      {loading && <Loading relative/>}
      <div className="title">{title}</div>
      <div className="content">
        <ResponsiveContainer
          width="100%"
          height="100%"
        >
          <FunnelChartLibrary
            layout={'centric'}
          >
            <Legend
              iconType="circle"
              iconSize={10}
              align="right"
              verticalAlign="top"
              layout="vertical"
              margin={{ left: 10, right: 10 }}
              payload={data}
              content={renderLegend}
            />
            <Tooltip 
              cursor={{ fill: 'transparent' }} 
            />
            <Funnel
              data={data}
              dataKey="values"
              nameKey="etapa"
              isAnimationActive
            >
            </Funnel>
          </FunnelChartLibrary>
        </ResponsiveContainer>
      </div>
    </Container>
  );
};

export default FunnelChart;
