import * as iquiteiSvc from './iquiteiApi.service';

export const getPartnerBlockList = async (partnerId, signal?: AbortSignal) => {
  try {
    const { data } = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/blockList`,
      { signal: signal },
    );

    return data;
  } catch (error: any) {
    throw error;
  }
}

export const insertBlockList = async (partnerId, body: string, signal?: AbortSignal) => {
  try {
    const { data } = await iquiteiSvc.postRequest(
      `/partners/${partnerId}/v2/blockList`, body, { signal: signal }
    );

    return data;
  } catch (error: any) {
    throw error;
  }
}

export const updateBlockList = async (partnerId, id, body: any, signal?: AbortSignal) => {
  try {
    const data  = await iquiteiSvc.putRequest(
      `/partners/${partnerId}/v2/blockList/${id}`, body, { signal: signal }
    );

    return data;
  } catch (error: any) {
    throw error;
  }
}

export const deleteBlockList = async (partnerId, id, signal?: AbortSignal) => {
  try {
    const data  = await iquiteiSvc.deleteRequest(
      `/partners/${partnerId}/v2/blockList/${id}`, { signal: signal }
    );

    return data;
  } catch (error: any) {
    throw error;
  }
}
