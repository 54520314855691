import React, {
  useState,
  useEffect,
  useContext,
  useCallback
} from 'react';

import {
  BlockListItem,
  Container,
  ContentModal
} from './styled';

import { LoadingContext } from '../../context/loading';
import { Loading } from '../../providers';

import { message, Modal, Table } from 'antd';

import {
  Button,
  InfoTooltip,
  Input
} from '../../components';

import { DeleteOutlined } from '@ant-design/icons';

import * as BlockListSvc from '../../service/blocklist.service';
import { AuthContext } from '../../context/auth';

interface BlockListProps {
  id: string;
  phone: string;
};

const BlockList:React.FC = () => {
  const { user } = useContext(AuthContext);

  const [phones, setPhones] = useState<BlockListProps[]>();
  const [phone, setPhone] = useState<string>('');
  const [blockListPhones, setBlockListPhones] = useState<any[]>();

  const [load, setLoad] = useState<boolean>(false);
  const { setLoading } = useContext(LoadingContext);

  useEffect(() => {
    (async () => {
      await fetchPhoneBlockList();
    })();
  },[user]);

  useEffect(() => {
    setLoading(false);
  },[]);

  const phoneFormated = useCallback((phone: string) => {
    phone = phone.replace(/[^\d]/g, '');

    if (phone.length > 10) {
        return phone.replace(/(\d{2})(\d{1})(\d{4})(\d)/, '($1) $2.$3-$4');
    }
    if (phone.length > 6) {
        return phone.replace(/(\d{2})(\d{4})(\d)/, '($1) $2-$3');
    }
    if (phone.length > 2) {
        return phone.replace(/(\d{2})(\d)/, '($1) $2');
    }
    return phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2.$3-$4');
  },[]);

  const fetchPhoneBlockList = async () => {
    try {
      setLoad(true);
      
      if(user) {
        const data: any[] = await BlockListSvc.getPartnerBlockList(user.partner.id);
        if(data.length > 0) {
          setBlockListPhones(data);
        }
        setLoad(false);

        return;
      }

    } catch (error: any) {
      message.error(error);
      throw error;
    }
  };

  const handleAddingToBlackList = useCallback( async (phones: any) => {
    if(user && phones.length > 0) {
      try {
        setLoad(true);

        if(!blockListPhones) {
          await Promise.all(phones.map(async (phone) =>
            BlockListSvc.insertBlockList(user.partner.id, phone)
          ));
          
          message.success('Sucesso ao criar blocklist');
        } else {
          await Promise.all(phones.map(async (phone) =>
            BlockListSvc.insertBlockList(user.partner.id, phone)
          ));
          
          message.success('Lista de bloqueio atualizada com sucesso');
        }
        setLoad(false);

        setPhones([]);

        setShow(false);
        await fetchPhoneBlockList();

        return;
      } catch (error: any) {
        message.error(error);
      }
    } else {
      message.error('Impossível cadastrar blocklist sem ter inserido números na lista.');
    }
  },[blockListPhones, phones]);

  const handleRemovingPhone = useCallback((phone: string) => {   
    if(phones) {
      const obj = phones.find((i) => i.phone === phone);
      const newList = phones.filter((i) => i !== obj);

      message.success('Número removido com sucesso');

      return setPhones(newList);
    }

    message.error('Ocorreu um erro ao remover ro número da lista' );
  }, [phones]);

  const handleAddingPhone = useCallback((phone: string, blockList: any) => {
    phone = phone.replace(/[^\d]/g, '');
    if(phone.length === 11 || phone.length === 10) {
      let existsInBlockList = false;
      if(blockList) {
        const exist = blockList.map((i) => i.toString() === phone);
        existsInBlockList = exist.some(i => i === true && true);
      };
      if(existsInBlockList) {
        message.error('Este número já existe dentro da lista de blocklist');
        setPhone('');
        
        return;
      }
      
      let existInList = false;
      if(phones) {
        const exist = phones.map((i) => i.phone.toString() === phone);
        existInList = exist.some(i => i === true && true);
      };
      if(existInList) {
        message.warning('Este número já existe dentro da lista atual');
        setPhone('');

        return;
      }
      
      if(phones) {
        const obj: BlockListProps[] = phones;
        obj.push({ id: 'a', phone });

        if(phone.length === 11){
          setPhones(obj);
          setPhone('');
          
          message.success('Numero adicionado com sucesso');
        }
        
      } else {
        const obj: BlockListProps[] = [{
          id: '',
          phone
        }];
        setPhones(obj);
        setPhone('');
        
        return message.success('Lista criada e numero adicionado');
      }
    } else if(phone.length < 11 && phone.length > 0) {
      message.error('Número inválido');
    } else {
      message.info('Preencha o campo antes de clicar em adicionar');
    }
  }, [phones, blockListPhones]);

  const handleRemovingPhoneBlockList = useCallback( async (value: any) => {   
    if(blockListPhones) {
      try {
        setLoad(true);
        const newList = blockListPhones.filter((i) => i.id !== value);
  
        if(user) {
          await BlockListSvc.deleteBlockList(
            user.partner.id, 
            value,
          );
          setBlockListPhones(newList);
          setLoad(false);
          
          message.success('Número removido com sucesso');

          return;
        }
         message.success('Ocorreu um erro ao remover o número da blocklist');
      } catch (error: any) {
        message.error(error);
        throw error;
      }
    }
    message.error('Ocorreu um erro ao remover ro número da lista' );
  }, [blockListPhones]);

  const [show, setShow] = useState<boolean>(false);

  const BlockListcolumns = [
    {
      title: 'Telefones',
      render: (idx, {id, phone}) => (
        <BlockListItem
          key={`${phone}${idx}`} 
          style={{  }}
        >
            <span>{ phoneFormated(phone.toString()) }</span>
            <DeleteOutlined 
              size={16}
              color='rgba(255,0,0)'
              style={{ marginRight: 5 }}
              onClick={() => handleRemovingPhoneBlockList(id)}
            />
        </BlockListItem>
      ),
    }
  ];

  const phonesColumns = [
    {
      title: 'Telefones',
      render: (idx, { phone }) => (
        <div
          key={`${phone}${idx}`} 
          style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'space-between' }}
        >
            <span>{ phoneFormated(phone.toString()) }</span>
            <DeleteOutlined 
              size={16} 
              color='rgba(255,0,0)'
              style={{ marginRight: 5 }}
              onClick={() => handleRemovingPhone(phone)}
            />
        </div>
      ),
    }
  ];

  return (
    <Container>
      <div style={{ position: 'relative' }}>
        <h2>Lista de telefones bloqueados para campanhas</h2>

        { load && (<Loading relative />) }
        
        <Modal 
          cancelText='Cancelar'
          okText='Cadastrar'
          title='Cadastro de telefones na block list.'
          visible={show}
          onCancel={() => setShow(false)}
          onOk={() => handleAddingToBlackList(phones)}
        >
          <ContentModal>
            <label>
              Bloquear telefones:
              <InfoTooltip text='Escreva o número com DDD, lembre-se o número obrigatoriamente precisa ser do Brasil.' />
            </label>

            <div>
              <Input
                value={phoneFormated(phone)} 
                type='text'
                onChange={(value) => setPhone(value) }
                placeholder='Ex: “85988084345“'
                maxLength={16}
              />
              <Button
                color='secondary'
                text='Adicionar'
                onClick={() => handleAddingPhone(phone, blockListPhones)}
              />
            </div>

            {phones && (
              <Table 
                className="ant-table-tbody"
                dataSource={phones.map((item) => item) || []} 
                columns={phonesColumns}
                pagination={{ defaultCurrent: 1, defaultPageSize: 5 }}
                bordered
                loading={load}
                rowClassName="table"
              />
            )}

          </ContentModal>
        </Modal>

        {blockListPhones && (
          <Table 
            className="ant-table-tbody"
            dataSource={blockListPhones.filter((phone) => phone !== '') || []} 
            columns={BlockListcolumns}
            pagination={{ defaultCurrent: 1, defaultPageSize: 5 }}
            bordered
            loading={load}
            rowClassName="table"
          />
        )};

        <Button
          color='primary'
          text='+ Cadastrar telefone'
          onClick={() => setShow(true)}
        />
      </div>

    </Container>
  );
};

export default BlockList;