import { Container } from './styled';
import { Input, Button, Select, TextArea } from '../../components';
import { useContext, useEffect, useState, useRef } from 'react';
import { AuthContext } from '../../context/auth';
import { LoadingContext } from '../../context/loading';
import { showToast, Loading } from '../../providers';
import { EyeFilled, EyeInvisibleFilled, PlusOutlined } from '@ant-design/icons';
import {
  getPartnerCreditorsV2,
  putCreditorData,
  updatePartnerCreditor,
  getPartnerCreditorByPartner,
} from '../../service/partner.service';
import { Tabs, message, Modal } from 'antd';
import JoditEditor from 'jodit-react';
import { UserRole } from '../../entity';
import { negotiationsText } from '../../service';
import ModalNewText from '../../components/ModalNewText';
import SetupCustomPage from './components/StepSetupCustomPage';
import OperatorsManager from './components/OperatorsManager';

const CreditorConfiguration = () => {
  const { setLoading } = useContext(LoadingContext);
  const { user, getUserData } = useContext(AuthContext);
  const [textNegociations, setTextNegociations] = useState<any>([]);
  const { TabPane } = Tabs;

  const [partnerCreditors, setPartnerCreditors] = useState<any>([]);
  const [configIntegration, setConfigIntegrationMode] = useState(false);
  const [isShowingPassword, setIsShowingPassword] = useState(false);

  const [form, setForm] = useState({
    aboutText: '',
    id: '',
    creditorId: null,
    id_partner: '',
    mail: '',
    creditorName: '',
    phone: '',
    site: '',
    whatsapp: '',
    username: '',
    password: '',
    formCampaign: '',
  });

  const [aboutText, setAboutText] = useState<any>(form?.aboutText);

  const [loading, setLoadingComponent] = useState(true);

  const [nameText, setNameText] = useState('');

  const fetchPartnerCreditors = async (partnerId: number) => {
    try {
      const [creditorConfig, creditorsPartner]: any[] = await Promise.all([
        getPartnerCreditorsV2(partnerId),
        getPartnerCreditorByPartner(partnerId),
      ]);

      let res = creditorConfig;
      const creditors = creditorsPartner;

      // if (user?.role === UserRole.ROLE_ADMIN && res && creditors) {
      //   res = res.map((cred) => {
      //     const creditor = creditors.find((cred2) => cred.creditorId === cred2.creditorId);
      //     if (creditor.length !== 0) {
      //       return {
      //         ...cred,
      //         ...creditor,
      //       };
      //     }
      //     return cred;
      //   });
      // }
      setPartnerCreditors(res);
      setForm(res[0]);
    } catch (error: any) {
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      showToast({
        type: 'error',
        message: err,
      });
    }
  };

  const checkUndefined = (item) => !checkUndefined || item.toString().trim() === '';

  const handleSaveCreditorInfo = async () => {
    try {
      setLoading(true);

      if (configIntegration) {
        const body = {
          username: form.username,
          password: form.password,
          creditorId: Number(form.creditorId),
        };

        if (checkUndefined(form.username) || checkUndefined(form.password)) {
          throw showToast({
            type: 'error',
            message: 'Usuário e/ou senha não podem estar vazias',
          });
        }

        await updatePartnerCreditor(user.partner.id, body);
      } else {
        await putCreditorData(user.partner.id, form);
        await fetchPartnerCreditors(user.partner.id);
      }

      setLoading(false);
      showToast({
        type: 'success',
        message: configIntegration
          ? 'Usuário do credor criado com sucesso para o parceiro'
          : 'Informações do credor salvas com sucesso.',
      });
    } catch (error: any) {
      setLoading(false);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      showToast({
        type: 'error',
        message: err,
      });
    }
  };

  const [textNegotiations, setTextNegotiations] = useState<string[]>();

  const [selectedText, setSelectedText] = useState<number|string>();

  const [negotiateText, setNegotiateText] = useState<string>('');

  const editor = useRef(null);

  const getNegotiationsText = async (partnerId: number) => {
    try {
      if(partnerCreditors.length === 0) return setLoadingComponent(false);

      const res: any[] = await negotiationsText.getNegotiationsText(partnerId);
      
      setSelectedText(res[0]?.id)
      setNameText(res[0]?.name)
      setNegotiateText(res[0]?.text)
      setTextNegociations(res);
      setLoadingComponent(false);
    } catch (error: any) {
      setLoadingComponent(false);
      message.error(error);
    }
  };

  const [openModalNewText, setOpenModalNewText] = useState<boolean>(false);

  const [isUpdated, setIsUpdated] = useState<boolean>(false);

  useEffect(() => {
    if (user && user.partner) getNegotiationsText(user?.partner.id);
    if(isUpdated)setIsUpdated(false);

  }, [openModalNewText, user, isUpdated,partnerCreditors]);

  

  const onChanceTextNegotiations = (value: any) => {
    textNegociations.find((text: any) => {
      if (value === text.id) {
        setNegotiateText(text.text);
        setNameText(text.name);
      }
      return value === text.id;
    });
    return setSelectedText(value);
  };

  const onSaveButton = async () =>{
    try {
      setLoadingComponent(true)
      if (selectedText){
      const res: any[] = await negotiationsText.updateNegotiationsText(user.partner.id, selectedText, {name:nameText, text:negotiateText});

      setTextNegociations(res)
      setLoadingComponent(false);}
      setIsUpdated(true);
      message.success('Alterado com sucesso!')
    } catch (error: any) {
      setLoadingComponent(false);
      message.error(error);
    }
    
  }
  const onDeleteButton = async () =>{
    try {
      if (selectedText){
      const res: any[] = await negotiationsText.deleteNegotiationsText(user.partner.id,selectedText);

      setTextNegociations(res);
      setLoadingComponent(false);}
      setIsUpdated(true);
      setSelectedText('')
      setNameText('')
      setNegotiateText('')
      message.success('Texto excluido com sucesso!')
    } catch (error: any) {
      setLoadingComponent(false);
      message.error(error);
    }
  }
  // ---------------------aqui----------
  useEffect(() => {
    if (user && user.partner && user.partner.id) {
      setForm({
        ...form,
        aboutText: '',
        id: '',
        mail: '',
        creditorName: '',
        phone: '',
        site: '',
        // whatsapp: '',
        username: '',
        password: '',
      });

      fetchPartnerCreditors(user.partner.id);
      setLoading(false);
      
    }
  }, [user]);
  
  return (
    <Container>
      <div className="title-and-button">
        <h2>Configurações de Negociação</h2>
      </div>

      
      <div className="select-creditor">
        <label>Credor:</label>
        
        <Select
          placeholder="- Selecione o Credor -"
          firstDisabled
          optionKeys={{ value: 'id', displayName: 'creditorName' }}
          options={partnerCreditors}
          value={form?.id}
          onChange={(value: string) => {
            const selectCreditor = partnerCreditors.find((creditor: any) => value === creditor.id);
            setForm({
              ...form,
              aboutText: selectCreditor.aboutText,
              id: selectCreditor.id,
              creditorId: selectCreditor.creditorId,
              id_partner: selectCreditor.id_partner,
              mail: selectCreditor.mail,
              creditorName: selectCreditor.creditorName,
              phone: selectCreditor.phone,
              site: selectCreditor.site,
              // whatsapp: selectCreditor.whatsapp,
              username: selectCreditor.username || '',
              password: selectCreditor.password || '',
            });
            setAboutText(selectCreditor.aboutText);
          }}
        />
      </div>

      <div className="card">
        {!configIntegration ? (
          <Tabs type="card">
            <TabPane tab="Gerais" key="1">
              <div className="input">
                <label className="label">Telefone de contato:</label>
                <Input
                  type="text"
                  placeholder="(xx) xxxxx-xxxx"
                  value={form?.phone}
                  onChange={(value: any) => setForm({ ...form, phone: value })}
                />
              </div>
              <div className="input">
                <label className="label">Site:</label>
                <Input
                  type="text"
                  placeholder="https://"
                  value={form?.site}
                  onChange={(value: any) => setForm({ ...form, site: value })}
                />
              </div>
              <div className="input">
                <label className="label">Email de contato:</label>
                <Input
                  type="text"
                  placeholder="Digite aqui o seu e-mail"
                  value={form?.mail}
                  onChange={(value: any) => setForm({ ...form, mail: value })}
                />
              </div>
              <div className="button">
                <Button color="primary" text="Salvar" onClick={handleSaveCreditorInfo} />
              </div>
            </TabPane>

            <TabPane tab="Sobre a assessoria" key="2">
              <div className="about-tab" style={{ maxWidth: '1000px' }}>
                <div className="text-area">
                  <label>Texto de sobre:</label>
                  <JoditEditor
                    value={form?.aboutText}
                    onChange={(value: any) => setForm({ ...form, aboutText: value })}
                  />
                  <div className="button">
                    <Button color="primary" text="Salvar" onClick={handleSaveCreditorInfo} />
                  </div>
                </div>
              </div>
            </TabPane>

            <TabPane tab="Textos Pág. Negociação" key="3">
              <div className="text-negotiations" style={{ maxWidth: '1000px' }}>
                <h3>Configurações dos textos da página de negociações:</h3>
                <div>
                  <label> Textos salvos:</label>
                  <div className="about-tab">
                    <div className="text-area">
                      {loading && <Loading relative />}
                      {textNegociations.length > 0 && (
                        <Select
                          placeholder="Selecione o texto de negociação"
                          firstDisabled
                          optionKeys={{ value: 'id', displayName: 'name' }}
                          options={textNegociations as any[]}
                          value={selectedText}
                          onChange={onChanceTextNegotiations}
                        />
                      )}
                    </div>
                    <div className='line-text'>
                      <Button
                        color="newButton"
                        text="Novo Texto"
                        icon={<PlusOutlined />}
                        onClick={() => setOpenModalNewText(true)}
                      />
                    </div>
                  </div>
                  <label> Nome do texto:</label>
                  <div className="input">
                  
                    <Input
                      type="text"
                      value={nameText}
                      onChange={(value: any) => setNameText(value)}
                    />
                  </div>

                  <label>Texto da página:</label>

                  <div className='about-tab'>
                    <JoditEditor
                      ref={editor}
                      value={negotiateText}
                      // value={'teste'}
                      //config={config}
                      //tabIndex={1} // tabIndex of textarea
                      //onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={(value: string) => {
                        setNegotiateText(value);
                      }}
                    />
                  </div>
                </div>

                <div className="btn">
                  <div className="button">
                    <Button color="disabled" text="Excluir" onClick={onDeleteButton} />
                  </div>
                  <div className="button">
                    <Button color="primary" text="Salvar" onClick={onSaveButton} />
                  </div>
                </div>
              </div>
            </TabPane>
           <TabPane tab="Textos Landing Page" key="4">
             <SetupCustomPage partnerInfo={partnerCreditors.find((cred) => cred.id === form?.id)}/>
           </TabPane>
           <TabPane tab="Operadoras" key="5">
             <OperatorsManager />
           </TabPane>
          </Tabs>
        ) : (
          <div className="integration-form">
            <Input
              type="text"
              placeholder="Digite aqui o usuário"
              value={form?.username}
              onChange={(value: any) => setForm({ ...form, username: value })}
            />
            <div className="password-visible-and-not">
              <Input
                type={isShowingPassword ? 'text' : 'password'}
                placeholder="Digite aqui a senha"
                value={form?.password}
                onChange={(value: any) => setForm({ ...form, password: value })}
              />
              <button onClick={() => setIsShowingPassword(!isShowingPassword)} type="button">
                {isShowingPassword ? <EyeFilled /> : <EyeInvisibleFilled />}
              </button>
            </div>
            <Button color="primary" text="Salvar" onClick={handleSaveCreditorInfo} />
          </div>
        )}
      </div>

      <ModalNewText
        isOpen={openModalNewText}
        onClose={() => setOpenModalNewText(false)}
        textNegociations={textNegociations}
        setTextNegociations={setTextNegociations}
        setSelected={setSelectedText}
        setLoading={setLoadingComponent}
        setNegotiationsText={setNegotiateText}
        setName={setNameText}
      />
    </Container>
  );
};

export default CreditorConfiguration;
