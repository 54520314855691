import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .card {
    display: flex;
    justify-content: center;
    width: 600px;
    align-items: center;
    border-radius: ${theme.border.radius};
    margin-bottom: 10px;
    background: ${theme.colors.white};
    box-shadow: ${theme.boxShadow};
    color: ${theme.colors.primary};
    padding: 40px 30px;
  }

  .card-message {
    margin-bottom: 10px;
    color: ${theme.colors.grey};
    font-weight: bold;
  }

  @media screen and (max-width: 767px) {
    .card {
      width: 100%;
    }
  }
`;

export const GenerateReportsForm = styled.div`
  width: 100%;
  height: 100%;

  #switch-shipment {
    margin-bottom: 10px;
    font-weight: bold;

    label {
      margin-right: 10px;
    }
  }
`;
