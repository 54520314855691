/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { StepSetupCustomPageContainer } from './styled';
import Checkbox from '../../../../components/Checkbox';
import { useEffect, useState, useRef, useContext } from 'react';
import CustomPageMockup from '../CustomPageMockup';
import InfoTooltip from '../../../../components/InfoTooltip';
import JoditEditor from 'jodit-react';
import { negotiationsText } from '../../../../service';
import { Select, Input, Button, RadioSelect } from '../../../../components';
import { Radio, Space, message } from 'antd';
import { AuthContext } from '../../../../context/auth';

const StepSetupCustomPage = ({ partnerInfo }) => {
  const { user } = useContext(AuthContext);

  const [headlineText, setHeadlineText] = useState<string>('');
  const [headlineButton, setHeadlineButton] = useState<string>('');
  const [headlineImage, setHeadlineImage] = useState<string>('');
  const [name, setName] = useState<string>('');

  const [isEditing, setIsEditing] = useState<any>(false);
  const [checkIsValid, onCheckIsValid] = useState<any>(false);

  const [customHeadlineText, setCustomHeadlineText] = useState<any>([]);

  const [selectedText, setSelectedText] = useState<any>();

  const editor = useRef(null);

  const DEFAULT_MESSAGE = {
    id: 0,
    name: 'Mensagem padrão',
    headlineText: `Aproveite o desconto e negocie sua dívida com a ${(partnerInfo && partnerInfo.partnerName) ? partnerInfo.partnerName : 'gente'} e dê
    um passo em direção à mudança de vida!`,
    headlineImage: 'https://i.imgur.com/elVXK7n.png',
    headlineButton: 'Clique aqui para negociar com desconto!',
  };

  const getHeadlineTexts = async (partnerId: number) => {
    try {
      const res: any[] = await negotiationsText.getCustomPageText(partnerId);

      if (res.length > 0) {
        setCustomHeadlineText(res);
        setSelectedText(res[0].id);
        setHeadlineText(res[0].headlineText);
        setHeadlineButton(res[0].headlineButton);
        setHeadlineImage(res[0].headlineImage);
        setName(res[0].name);
      } else {
        setHeadlineText(DEFAULT_MESSAGE.headlineText);
        setHeadlineButton(DEFAULT_MESSAGE.headlineButton);
        setHeadlineImage(DEFAULT_MESSAGE.headlineImage);
        setName(DEFAULT_MESSAGE.name);
      }
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(()=>{
    if (user) getHeadlineTexts(user.partner.id);
  }, [user])

  const onSaveButton = async () => {
    try {
      if(!checkIsValid) return console.error('Todos os campos precisam estar preenchidos');

      const body = {
        headlineText,
        headlineImage,
        headlineButton,
        name
      };
      if (isEditing) {
        await negotiationsText.updateCustomPageText(user.partner.id, selectedText, body);
      } else {
        await negotiationsText.insertCustomPageText(user.partner.id, body);
      }
      await getHeadlineTexts(user.partner.id);
      return message.success('Textos salvos com sucesso!');
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const onDeleteButton = async () => {
    try {
      if (isEditing) {
        await negotiationsText.deleteCustomPageText(user.partner.id, selectedText);
        await getHeadlineTexts(user.partner.id);
        return message.success('Texto salvo excluído com sucesso!');;
      }
      return;
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(() => {
    const isValidheadlineText = headlineText && headlineText !== '';
    const isValidheadlineImage = headlineImage && headlineImage !== '';
    const isValidheadlineButton = headlineButton && headlineButton !== '';

    onCheckIsValid(isValidheadlineText && isValidheadlineImage && isValidheadlineButton);
  }, [headlineText, headlineImage, headlineButton]);

  return (
    <StepSetupCustomPageContainer>
      <div className="columns column-form">
        <h3>Configurações dos textos da página inicial:</h3>
          <div>
          {customHeadlineText.length > 0 && <RadioSelect text="Modo editor" checked={isEditing} onChange={() => setIsEditing(!isEditing)} />}
          {isEditing && (
          <>
            <label> Textos salvos:</label>
            <Select
            placeholder="Selecione o texto da página"
            firstDisabled
            optionKeys={{ value: 'id', displayName: 'name' }}
            options={customHeadlineText as any[]}
            value={selectedText}
            onChange={(value:any)=>{
              customHeadlineText?.find((text: any) =>{
                if(value === text.id){
                  setHeadlineText(text.headlineText);
                  setHeadlineButton(text.headlineButton);
                  setHeadlineImage(text.headlineImage);
                  setName(text.name);
                  return text.headlineText;
                }
              })
              setSelectedText(value)
            }}
            />
          </>
          )}
          <label>Nome do texto:</label>
          <Input type='text' onChange={setName} value={name} />

          <label>Texto da página:</label>
       
          <JoditEditor
            ref={editor}
            value={headlineText}
            //config={config}
            //tabIndex={1} // tabIndex of textarea
            //onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(value: string) => {
              setHeadlineText(value);
            }}
          />
          <br/>
          <label>Texto do botão:</label>
          <Input type="text" value={headlineButton} onChange={setHeadlineButton} placeholder="Clique aqui para negociar desconto!"/>
          
          <label>Link imagem do fundo:</label>
          <Input type="text" value={headlineImage} onChange={setHeadlineImage} placeholder="Link da imagem de fundo"/>
        </div>
        <div className="options-box">
            <Button text={isEditing ? 'Salvar' : 'Criar'} onClick={onSaveButton} color="primary"/>
            {isEditing && <Button text="Excluir" onClick={onDeleteButton} color="secondary"/>}
        </div>
      </div>
      <div className="columns column-phone">
        <CustomPageMockup pageCustom={
          {
            headlineImage,
            headlineButton,
            headlineText,
            ...partnerInfo,
          }} />
      </div>
    </StepSetupCustomPageContainer>
  );
};

export default StepSetupCustomPage;
