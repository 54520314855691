import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  border-radius: ${theme.border.radius};
  width: fit-content;
  min-width: 200px;
  border: 1px solid ${theme.colors.lightGrey};
  background: ${theme.colors.secondary};
  padding: 10px;
  flex: 1;
  position: relative;

  .title {
    display: flex;

    margin-bottom: 2px;

    align-items: center;
    justify-content: space-between;

    font-size: ${theme.text.size.md};
    font-weight: bold;
  }

  .button-download {
    all: unset;

    display: flex;
    
    color: green;

    font-size: 16px;

    justify-content: flex-end;
    margin: 0 5px;

    transition: 0.325s;
    &:hover {
      transform: scale(1.1);
    }

    cursor: pointer;
  }

  .content {
    border-radius: 0 0 ${theme.border.radius} ${theme.border.radius};
    margin: 0;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    display: flex;
    justify-content: center;

    .value {
      font-size: ${theme.text.size.xxl};
      margin-right: 10px;
    }

    .percentage {
      display: flex;
      flex-direction: column;
      justify-content: end;
      height: fit-content;
      width: fit-content;
      min-height: 44px;

      .total {
      }

      .comparison {
      }

      .positive {
        color: ${theme.colors.tertiary};
      }

      .negative {
        color: ${theme.colors.red};
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      flex: 1;
      align-items: center;
      min-width: 110px;

      .value {
        font-size: ${theme.text.size.xl};
        font-weight: bold;
        text-align: center;
      }
      .name {
        font-size: ${theme.text.size.md};
        text-align: center;
      }
    }
  }
  

  @media screen and (max-width: 1000px) {
    width: 100%;
    min-width: 250px;
  }

`;
