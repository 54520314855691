/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { StepSetupCustomPageContainer } from './styled';
import Checkbox from '../../../../../../components/Checkbox';
import { useEffect, useState, useRef } from 'react';
import CustomPageMockup from '../CustomPageMockup';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import JoditEditor from 'jodit-react';
import { negotiationsText } from '../../../../../../service';
import { Select, Input } from '../../../../../../components';
import { Radio, Space, message } from 'antd';

const StepSetupCustomPage = ({ formCampaign, setFormCampaign, onCheckIsValid, user }: any) => {
  const [headlineText, setHeadlineText] = useState<string>(formCampaign.headlineText);
  const [headlineButton, setHeadlineButton] = useState<string>(formCampaign.headlineButton);
  const [headlineImage, setHeadlineImage] = useState<string>(formCampaign.headlineImage);

  const [campaignVariableNames] = useState<string[]>(formCampaign.file.response.data.headers);

  const [customHeadlineText, setCustomHeadlineText] = useState<any>([]);

  const [selectedText, setSelectedText] = useState<any>();

  
  const concatVariable = (variable: string) => {
    if (headlineText?.includes(variable)) {
      return;
    }
    setHeadlineText(headlineText?.concat(`{${variable}}`));

    const elem: any = document.getElementById('sms-text-input');

    if (elem) {
      elem.focus();
    }
  };

  const editor = useRef(null);

  useEffect(() => {
    setFormCampaign({ ...formCampaign, headlineText, headlineImage, headlineButton });
    // eslint-disable-next-line
  }, [headlineText, headlineImage, headlineButton]);

  const getHeadlineTexts = async (partnerId: number) => {
    try {
      const res: any[] = await negotiationsText.getCustomPageText(partnerId);

      const newRes = [{
        id: 0,
        name: 'Mensagem padrão',
        headlineText: `Aproveite o desconto e negocie sua dívida com a ${formCampaign.creditor.partnerName} e dê
        um passo em direção à mudança de vida!`,
        headlineImage: 'https://i.imgur.com/E0li7q6.jpeg',
        headlineButton: 'Clique aqui para negociar com desconto!',
      }, ...res];

      setCustomHeadlineText(newRes);
      setSelectedText(0);
      setHeadlineText(newRes[0].headlineText);
      setHeadlineButton(newRes[0].headlineButton);
      setHeadlineImage(newRes[0].headlineImage);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(()=>{
    getHeadlineTexts(user.partner.id)
  },[user])

  useEffect(() => {
    const isValidheadlineText = formCampaign.headlineText && formCampaign.headlineText !== '';
    const isValidheadlineImage = formCampaign.headlineImage && formCampaign.headlineImage !== '';
    const isValidheadlineButton = formCampaign.headlineButton && formCampaign.headlineButton !== '';

    onCheckIsValid(isValidheadlineText && isValidheadlineImage && isValidheadlineButton);
  }, [formCampaign]);

  return (
    <StepSetupCustomPageContainer>
      <div className="columns column-form">
        <div>
          <label>Variáveis disponíveis:</label>
          <InfoTooltip text=" As variáveis são buscadas a partir do nome das colunas do arquivo. Você pode utilizá-las na confecção do texto da página de negociação e observar o resultado no simulador ao lado" />
          <div className="variables-list">
            {campaignVariableNames.map((variable: string, idx) => {
              if (!headlineText?.includes(variable)) {
                return (
                  <div key={idx} className="variable-item" onClick={() => concatVariable(variable)}>
                    {variable}
                  </div>
                );
              }
            })}
          </div>
        </div>

        <div>
          <label> Textos salvos:</label>
          <Select
           placeholder="Selecione o texto de negociação"
           firstDisabled
           optionKeys={{ value: 'id', displayName: 'name' }}
           options={customHeadlineText as any[]}
           value={selectedText}
           onChange={(value:any)=>{
             customHeadlineText?.find((text: any) =>{
               if(value === text.id){
                 setHeadlineText(text.headlineText);
                 setHeadlineButton(text.headlineButton);
                 setHeadlineImage(text.headlineImage);
                 return text.headlineText;
               }
             })
             setSelectedText(value)
           }}

          />
          <label>Texto da página:</label>
       
          <JoditEditor
            ref={editor}
            value={headlineText}
            //config={config}
            //tabIndex={1} // tabIndex of textarea
            //onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
            onChange={(value: string) => {
              setHeadlineText(value);
            }}
          />
          <br/>
          <label>Texto do botão:</label>
          <Input type="text" value={headlineButton} onChange={setHeadlineButton} placeholder="Clique aqui para negociar desconto!"/>
          
          <label>Link imagem do fundo:</label>
          <Input type="text" value={headlineImage} onChange={setHeadlineImage} placeholder="Link da imagem de fundo"/>
        </div>
        <div className="options-box">
        </div>
      </div>
      <div className="columns column-phone">
        <CustomPageMockup pageCustom={formCampaign} />
      </div>
    </StepSetupCustomPageContainer>
  );
};

export default StepSetupCustomPage;
