import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Container } from './styled';
import { useLocation, useParams } from 'react-router-dom';
import cpfConvert from '../../../../utils/cpf-complete';

function CustomLP({ pageCustom }) {
  return (
    <Container className="container">
      <div className="headline" style={{ backgroundImage: `url(${pageCustom?.headlineImage})` }}>
        <div className="logo">
          {(pageCustom && pageCustom.creditorImage) && (
            <img
              style={{width: '50%'}}
              src={pageCustom.creditorImage}
              alt="logo"
            />
          )}
          <br/>
          {(pageCustom && pageCustom.partnerImage) && (
            <img
              src={pageCustom.partnerImage}
              style={{ width: '50%' }}
              alt="hapiness"
            />
          )}
        </div>
        <span
          className="headline-text"
          dangerouslySetInnerHTML={{__html: `${(
            pageCustom.headlineText
            || `Aproveite o desconto e negocie sua dívida com a ${pageCustom.partnerName} e dê
          um passo em direção à mudança de vida!`)}`}}
        />

        <button
          className="button-negotiate"
          onClick={() => {}}
        >
          {pageCustom.headlineButton || 'Clique aqui para negociar com desconto!'}
        </button>
      </div>
    </Container>
  );
}

export default CustomLP;
