import styled from 'styled-components';
import theme from '../../../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .metrics {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: ${theme.flexGrid.gap};

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: ${theme.flexGrid.gap};
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .btn-content {
      width: fit-content;
    }
  }

  .shipments-errors {
    max-width: 90vw;
    overflow: auto;

    @media screen and (min-width: 768px) {
      /* max-width: 76vw; */
    }
  }
`;
