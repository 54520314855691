import React, { useState } from 'react';
import { PartnerContext } from './partnerSelect.context';

interface Props {
  children: React.ReactNode;
}

export const PartnerProvider: React.FC<Props> = ({ children }) => {
  const [partnerSelected, setPartnerSelected] = useState<string | number>(0);

  return (
    <PartnerContext.Provider value={{ partnerSelected, setPartnerSelected }}>{children}</PartnerContext.Provider>
  );
};
