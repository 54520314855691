import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div `

display: flex;
flex-direction: column;

position: relative;
border-radius: ${theme.border.radius};
flex: 1;

.content {
  height: calc(300px - 40px);
  padding: 20px;
  background: ${theme.colors.secondary};
  border-radius: 0 0 ${theme.border.radius} ${theme.border.radius};
  border: 1px solid ${theme.border.color};
}

.title {
    font-size: ${theme.text.size.md};
    font-weight: bold;
    background: ${theme.colors.primary};
    color: ${theme.colors.secondary};
    border-radius: ${theme.border.radius} ${theme.border.radius} 0px 0px;
    padding: 10px;
    text-align: center;
  }

  .teste{
    border: solid red 2px ;
    
`
