import * as iquiteiSvc from './iquiteiApi.service';

export async function getAllErrorsLogs(partnerId: number, signal?: AbortSignal) {
    try {
      const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/error/getErrorsLogs`, { signal });
  
      return res.data;
    } catch (error: any) {
      const { data } = error.response;
      const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os logs de erros';
      throw err;
    }
}

export async function getErrorLogById(partnerId: number, docId: string, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/error/getErrorsLogs/${docId}`, { signal });

    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar o log do erro';
    throw err;
  }
}

export async function updateErrorLogById(partnerId: number, docId: string, solved: string, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/error/${docId}/${solved}`, { signal });
    
    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar o log do erro';
    throw err;
  }
}

export async function updateErrorLogFields(partnerId: number, docId: string, fields: any, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/error/updateFields/${docId}`, fields, { signal });
    
    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar o log do erro';
    throw err;
  }
}