import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .line-text {
    display: flex;
    flex-direction: row;
    flex-flow: nowrap;
    align-items: center;
    // justify-content: space-between;

    .columns {
      position:relative;
      display: flex;
      flex-grow: 1 ;
      min-width: 40%;
      margin-right: 15px;
    }
  }

  .title-and-button {
    display: flex;
    justify-content: space-between;
    width: 100%;
    h1 {
      flex: 1;
    }

    button {
      flex: 0;
    }
  }
  .select-creditor {
    display: flex;
    flex-direction: column;
    max-width: 25%;
    min-width: 25%;
    margin-bottom: 25px;
  }

  .card {
    flex: 1;
    width: 100%;
    align-items: center;
    border-radius: ${theme.border.radius};
    margin-bottom: 10px;
    color: ${theme.colors.primary};

    .button {
      display: flex;
      justify-content: center;
      margin-bottom: 10px;
      padding-top: 15px;
      width: 400px;
    }
  }
  .btn {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  .password-visible-and-not {
    display: flex;
    position: relative;
    button {
      background-color: transparent;
      border: none;
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }

  @media screen and (max-width: 767px) {
    .card {
      width: 100%;
    }
  }

  .edit-input {
    width: 30px;
    height: 30px;
    border: 1px solid ${theme.colors.primary};
    color: ${theme.colors.primary};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.lightGrey};
    margin-top: -35px;
    margin-left: 60px;
    z-index: 100;
    position: relative;
    cursor: pointer;
  }

  .edit-input > input {
    display: none;
  }

  .label {
    font-size: 14px;
    line-height: 19, 1px;
  }

  .input {
    margin-bottom: 10px;
    width: 400px;
  }

  .about-tab {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-right: 25px;
  }

  .text-area {
    display: flex;
    flex-direction: column;
    margin-right: 30px;
    min-width: 25%;
    position: relative;
  }

  .text-negotiations{
    max-width:100%;


  }
`;

export const UserProfileForm = styled.form`
  width: 100%;
`;
