import firebase from 'firebase';
import getFirebaseCredentials from './firebaseCredentials';

const firebaseConfig: any = getFirebaseCredentials(process.env.REACT_APP_FIREBASE_PROJECT || '');

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const app = firebase.initializeApp(firebaseConfig);

//  if (window.location.hostname === "localhost") {
//    app.functions().useFunctionsEmulator("http://localhost:5001");
//    app.auth().useEmulator("http://localhost:9099");
//    app.firestore().settings({
//      host: "localhost:8080",
//      ssl: false,
//    });
//  }

export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const auth = firebase.auth();

export const serverTime: any = firebase.firestore.Timestamp.now();
