import * as iquiteiSvc from './iquiteiApi.service';

export async function getDataMetrics (partnerId, signal?: AbortSignal, filterMonth = '', filterYear = '') {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/financeDashboard/dataMetrics?month=${filterMonth}&year=${filterYear}`,  { signal }
    );

    return res.data.data;
  } catch(error: any) {
    const data = error?.response ? error?.response?.data : {};
    const err = data?.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar as informaçoes';
    throw err;
  }
}

export async function getDataGraphics (partnerId, signal?: AbortSignal,filterMonth = '', filterYear = '') {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/financeDashboard?month=${filterMonth}&year=${filterYear}`,  { signal }
    );

    return res.data.data;
  } catch(error: any) {
    const data = error?.response ? error?.response?.data : {};
    const err = data?.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar as informaçoes';
    throw err;
  }
}

export async function getEnableSelectData (partnerId, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/financeDashboard/selectDataOptions`,  { signal }
    );
    
    return res.data.data;
  } catch(error: any) {
    const data = error?.response ? error?.response?.data : {};
    const err = data?.statusCode !== 500 ? data.error : 'Ocorreu um erro ao pegar as informaçoes';
    throw err;
  }
}