import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: relative;
  .div-email-tooltip {
    display: flex;
    position: relative;
    span {
      position: absolute;
      top: 10px;
      left: -1.4rem;
      z-index: 5;
      @media screen and (min-width: 750px) {
        left: -2rem;
      }
    }
  }

  .buttons-register-cancel {
    display: flex;
    flex-direction: row-reverse;
    button {
      margin: 5px;
    }
  }
`;

export const RegisterEmployeeForm = styled.div``;
