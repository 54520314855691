import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import theme from '../theme';

const Container = styled.div`
  width: 100%;
  height: 100%;
  background: ${theme.colors.loading.background};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  position: absolute;
  &.relative {
    opacity: 0.6;
    top: 0;
    right: 0;
  }
  
  &.shimmer {
      display: flex;
      flex: 1;

      width: 100%;
      height: 100%;

      position: absolute;
      
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      align-items: center;
      justify-content: center;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 0.5) 50%,
        rgba(255, 255, 255, 0) 80%
      ),
      lightgray;

      span.loader {
        position: relative;
        display: inline-block;
        width: 50px;
        height: 50px;
        vertical-align: middle;

        margin-top: 20px;
      }

      .loader-bars:before,
      .loader-bars:after,
      .loader-bars span {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 10px;
        height: 30px;
        background-color: ${theme.colors.blue};
        -webkit-animation: grow 1.5s linear infinite;
        animation: grow 1.5s linear infinite;
      }

      .loader-bars:after {
        left: 15px;
        -webkit-animation: grow 1.5s linear -.5s infinite;
        animation: grow 1.5s linear -.5s infinite;
      }

      .loader-bars span {
        left: 30px;
        -webkit-animation: grow 1.5s linear -1s infinite;
        animation: grow 1.5s linear -1s infinite;
      }

      @-webkit-keyframes grow {
        0% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
        50% { -webkit-transform: scaleY(1); transform: scaleY(1); opacity: 1;}
        100% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
      }

      @keyframes grow {
        0% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
        50% { -webkit-transform: scaleY(1); transform: scaleY(1); opacity: 1;}
        100% { -webkit-transform: scaleY(0); transform: scaleY(0); opacity: 0;}
      }

      @-webkit-keyframes spin {
          0%{ -webkit-transform: rotate(0deg); tranform: rotate(0deg);}
        100%{ -webkit-transform: rotate(360deg); tranform: rotate(360deg);}
      }

      @keyframes spin {
        0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
        100%{ -webkit-transform: rotate(360deg); transform: rotate(360deg);}
      }

      @-webkit-keyframes spinreverse {
          0%{ -webkit-transform: rotate(0deg); tranform: rotate(0deg);}
        100%{ -webkit-transform: rotate(-360deg); tranform: rotate(-360deg);}
      }

      @keyframes spinreverse {
        0%{ -webkit-transform: rotate(0deg); transform: rotate(0deg);}
        100%{ -webkit-transform: rotate(-360deg); transform: rotate(-360deg);}
      }
      
      /* opacity: 0.0; */
      /* border-radius: 15px; */
      background-size: 400% 400%;
      animation: shimmer 1.2s ease-in-out infinite;

      @keyframes shimmer {
        0% {
          background-position: 0% 0%;
        }
        100% {
          background-position: -135% 0%;
        }
      }

      &.white {
        background-image: linear-gradient(-90deg , #fff 0%, #e7edf1 50%, #fff 100%);
      }
    }

`;

interface ILoadingConfigProps {
  relative?: boolean;
  componentType?: 'chartBar' | 'chartLine' | 'avatar' | 'default'
};

const Loading = ({ relative, componentType = 'default' }: ILoadingConfigProps) => {
  return (
    <Container id="loading-container" className={ relative ? 'shimmer' : ''}>
      {
      !relative ? (
        <ReactLoading type={'bars'} color={theme.colors.loading.icon} width={50} />
      ) : ( 
        <div>
          {componentType === 'default' && (
              <span className="loader loader-bars"><span></span></span>
          )} 
        </div> 
      )}
    </Container>
  );
};

export default Loading;
