import Typography from '@material-ui/core/Typography';

export default function Title(props) {
  return (
    <Typography
      variant="h6"
      //fontSize={props.fontSize}
      align="center"
      paragraph={props.paragraph}
      //fontWeight={props.fontWeight ? props.fontWeight : '600'}
      color={props.color}
    >
      {props.text}
    </Typography>
    // <h2>
    //      {props.text}
    // </h2>
  );
}
