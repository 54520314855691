import styled from 'styled-components';
import { RightOutlined } from '@ant-design/icons';
import theme from '../../theme';

interface ErrorLogProps {
  solved: 'resolvido' | 'Não resolvido';
}

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  position: relative;

  .title {
    margin-bottom: 60px;
    margin-left: 15px;
  }
  .text-error {
    color: ${theme.colors.dark};
    
    font-size: 1.2rem;
    width: 400px;

    margin-right: 20px;
  }

  .icon-error {
    font-size: 1rem;
  }

  .container-form {
    display: flex;
  }

  .form {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .text-label {
    font-size: 1.2rem;
  }

  .container-input {
    display: flex;
    flex-direction: column;
  }

  .content-input {
    margin: 0 15px;
  }

  .content-textarea {
    display: flex;
    flex-direction: column;

    justify-content: flex-start;

    margin: 0 15px;

    grid-column-start: 1;
    grid-column-end: 2;
  }

  .content-actions {
    display: flex;
    
    grid-row-start: 4;
    grid-column-start: 2;

    align-items: center;
    justify-content: center;

    margin-top: 50px;
  }

  .button {
    padding: 0 8px;
  }
  .reload-button {
    align-items: center;
    display: flex;
    background-color: transparent;
    border: none;
    font-weight: 600;
    cursor: pointer;
    justify-content: center;
    transition: 0.3s;
    transform: translateY(-30px);
    width: 100%;
    span {
        transition: 1s;
        margin: 0 5px;
      }
    &:hover {
        transition: 0.3s;
        transform: translateY(-30px) scale(1.1);
      span {
        transition: 1s;
        transform: rotate(360deg);
      }
    }
    }
  }
`;

export const IconError = styled(RightOutlined)`
    font-size: 1.5rem;
    padding-bottom: 8px;
    cursor: pointer;
`;
