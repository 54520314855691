import styled from 'styled-components';

export const Container = styled.div`
  background: #f0f0f0;
  position: relative;
  margin: 40px 20px;
  width: 400px;
  height: 600px;
  border-radius: 10px;
  padding: 20px;

  .text-break-spaces {
    white-space: pre-wrap;
    text-align: center;
  }

  .headline {
    height: 100%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    overflow-y: auto;

    .logo {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-bottom: 20px;
    }

    .partner-creditor-logo {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .headline-text {
      font-weight: 700;
      font-size: 25px;
      color: #fcfcfc;
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }

  .reasons {

    .reasons-title {
      font-size: 20px;
      color: #232323;
      font-weight: bold;
      text-align: center;
      display: flex;
      justify-content: center;
      padding: 0 30px;
    }

    .reason-card {
      background: #260f63;
      border-radius: 10px;
      padding: 20px 50px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #fcfcfc;
      margin-top: 20px;

      .reason-icon {
        width: 110px;
        height: 110px;
        background: #fcfcfc;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;

        img {
          width: 70px;
          height: 70px;
        }
      }

      .reason-title {
        margin: 10px 0;
        font-size: 20px;
        font-weight: bold;
        display: flex;
        justify-content: center;
        text-align: center;
      }

      .reason-description {
        font-size: 14px;
        font-weight: 300;
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
  }

  .footer {
    background-color: #260f63;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;

    .footer-text {
      color: #fcfcfc;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }
  }

  .business-document {
    background-color: #150838;
    color: #fcfcfc;
    font-size: 10px;
    font-weight: 300;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  button {
    width: 100%;
    background: #00e48d;
    color: #fcfcfc;
    border: none;
    padding: 15px;
    border-radius: 100px;
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0;
  }
`;
