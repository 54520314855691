import { Button, Paper, TextField } from '@material-ui/core';
import { useState } from 'react';
import Title from './components/Text/Title';
import { Container } from './styled';
import { maskCpf } from './utils/maskCpf.util';
import cpfConvert from '../../../../../../utils/cpf-complete';

const NegotiationPageMockup: React.FC<any> = ({ formCampaign, setFormCampaign }: any) => {
  const regexp = /{[A-Za-z0-9_]+}/g;

  const error = false;
  const helperText = true;
  const [cpf] = useState('000.000.000-00');

  const [customer /*, setCustomer*/] = useState({
    namePartner: 'N&N Assessoria',
    site_contact: 'www.com',
    creditor: '',
    clientName: 'Shitzy Teskl',
    cpf: '000.XXX.000-00',
    pageText: '',
    name_cnpj: 'N&N Assessoria',
    document: '000.000.000-00',
    sigla: 'Assessoria',
  });

  const formatMessage = (message: string) => {
    let found = message.match(regexp);
    let formatedMessage = message;
    if (found) {
      // eslint-disable-next-line
      found.map((item) => {
        let filterItem = item.replace('{', '').replace('}', '');

        let position = formCampaign.file.response.data.headers.indexOf(filterItem);

        if (filterItem === 'CPF_CNPJ') {
          formatedMessage = formatedMessage.replace(
            item,
            cpfConvert(formCampaign.file.response.data.firstLine[position])
          );
        } else if (filterItem !== 'Link') {
          formatedMessage = formatedMessage.replace(
            item,
            formCampaign.file.response.data.firstLine[position]
          );
        } else {
          formatedMessage = formatedMessage.replace(item, 'https://iquitei.com/xyz');
        }
      });

      return formatedMessage;
    } else {
      return message;
    }
  };

  return (
    <Container>
      {/* <BoxName
        align='center'
        bgColor='#03DAC6'
        name={customer.namePartner}
        site={customer.site_contact}
      />

      <ImageHeader creditor={customer?.creditor} /> */}

      <Title text={`Olá, ${customer?.clientName}`} paragraph={true} />
      <Title text={`CPF: ${customer?.cpf}`} paragraph={true} />

      <Paper
        elevation={3}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          paddingBottom: 20,
          paddingTop: 20,
          paddingLeft: 15,
          paddingRight: 15,
          maxHeight: 400,
          overflowY: 'scroll',
        }}
      >
        {/* <Paragraph text={customer?.pageText} style={{ fontSize: 14 }} /> */}
        <p className="text-break-spaces" style={{ fontSize: 14 }}>
        <span dangerouslySetInnerHTML={{__html: `${formatMessage(formCampaign.negotiateText)}`}} />

        </p>

        <TextField
          error={error}
          helperText={helperText}
          value={' '}
          label="Insira os 3 digitos"
          size="small"
          inputProps={{
            maxLength: 3,
            style: { fontSize: 20, textAlign: 'center' },
          }}
          style={{ maxWidth: 300, width: '100%', margin: '8px' }}
        />

        <Button
          // text='CONFIRMAR CPF'
          color="primary"
          variant="contained"
          // maxWidth='300px'
          size="large"
          // marginTop={16}
        >
          CONFIRMAR CPF
        </Button>
      </Paper>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        {formCampaign.call && (
          <Button
            // text='CENTRAL DE ATENDIMENTO'
            color="secondary"
            variant="contained"
            // maxWidth='300px'
            size="large"
            // marginTop='40px'
            style={{ marginTop: 10 }}
          >
            CENTRAL DE ATENDIMENTO
          </Button>
        )}

        <Button
          // text={`Sobre a ${client?.sigla}`}
          color="secondary"
          variant="contained"
          // maxWidth='300px'
          size="large"
          style={{ marginTop: 10 }}
        >{`Sobre a ${customer?.sigla}`}</Button>

        {/* <img
        src='/images/selosseguranca.png'
        alt='seloseguranca'
        style={{ maxWidth: '300px' }}
      ></img> */}
      </div>

      {/* <Footer name={customer?.name_cnpj} document={customer?.document} /> */}
    </Container>
  );
};

export default NegotiationPageMockup;
