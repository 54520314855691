import * as iquiteiSvc from './iquiteiApi.service';

export async function getOperators(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/operators`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Não foi possível encontrar nenhuma operadora. Solicite cadastramento.';
    throw err;
  }
}

export async function getOperatorsInfo(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/operators/info`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Não foi possível encontrar nenhuma operadora. Solicite cadastramento.';
    throw err;
  }
}

export async function getOperatorsById(partnerId: number, id: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/operators/${id}`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Não foi possível encontrar nenhuma operadora. Solicite cadastramento.';
    throw err;
  }
}

export async function createOperators(partnerId: number, body: any) {
  try {
    const res = await iquiteiSvc.postRequest(`/partners/${partnerId}/v2/operators`, body);

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Não foi possível encontrar nenhuma operadora. Solicite cadastramento.';
    throw err;
  }
}

export async function updateOperators(partnerId: number, id: number, body: any) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/v2/operators/${id}`, body);

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Não foi possível encontrar nenhuma operadora. Solicite cadastramento.';
    throw err;
  }
}

export async function deleteOperators(partnerId: number, id: number) {
  try {
    const res = await iquiteiSvc.deleteRequest(`/partners/${partnerId}/v2/operators/${id}`);

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Não foi possível encontrar nenhuma operadora. Solicite cadastramento.';
    throw err;
  }
}