import React from 'react';
import { Select as AntdSelect } from 'antd';
import theme from '../../theme';

interface OptionsProps {
  _id: string;
  name: string;
  div?: any;
}

type OptionKeys = {
  value: string;
  displayName: string;
  div?: any;
};

interface SelectProps {
  options: OptionsProps[];
  value: string | any;
  onChange: (value: string) => any;
  optionKeys: OptionKeys;
  placeholder?: string;
  firstDisabled?: boolean;
  disabled?: boolean;
  style?: any;
}

const styleFix = {
  borderRadius: theme.border.radius,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.colors.lightGrey,
  marginBottom: '10px',
};

const Select: React.FC<SelectProps> = ({
  options,
  value,
  optionKeys,
  onChange,
  placeholder,
  firstDisabled,
  disabled,
  style
}) => {
  const { Option } = AntdSelect;

  return (
    <AntdSelect disabled={disabled} style={{...styleFix, ...style}} value={value} onChange={(value: any) => onChange(value)}>
      <Option value={''} disabled={firstDisabled}>
        {placeholder}
      </Option>

      {options?.map((option: any, idx: number) => (
        <Option key={idx} value={optionKeys.value ? option[optionKeys.value] : idx}>
          {option[optionKeys.div] ? option[optionKeys.div] : option[optionKeys.displayName]}
        </Option>
      ))}
    </AntdSelect>
  );
};

export default Select;
