import styled from 'styled-components';
import theme from './theme';

export const ResponsiveRow = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  gap: ${theme.flexGrid.gap};
  position: relative;

  @media screen and (min-width: 900px) {
    flex-direction: row;
  }
`;
export const Container = styled.div``;
