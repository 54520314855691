/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  EPeriodDaysName,
  EPeriodNumberOfDays,
  IDateRange,
  IPeriod,
} from '../../entity/datetime.entity';
import { formattedDate } from '../../utils/date-format';
import { Content } from './styled';
import DatePicker from 'react-datepicker';
import ptBr from 'date-fns/locale/pt-BR';

const periods: IPeriod[] = [
  {
    name: EPeriodDaysName.DAY,
    numberOfDays: EPeriodNumberOfDays.PERIOD_DAY,
  },
  {
    name: EPeriodDaysName.WEEK,
    numberOfDays: EPeriodNumberOfDays.PERIOD_WEEK,
  },
  {
    name: EPeriodDaysName.MONTH,
    numberOfDays: EPeriodNumberOfDays.PERIOD_MONTH,
  },
  {
    name: EPeriodDaysName.SELECTED,
    numberOfDays: EPeriodNumberOfDays.PERIOD_SELECTED,
  },
];

interface DateRangeProps {
  period: EPeriodDaysName;
  onChangeDate(dateRange?: IDateRange): void;
}

const DateRange: React.FC<DateRangeProps> = ({ period, onChangeDate }) => {
  const [date, setDate] = useState<any>();
  const [showCalendar, setShowCalendar] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const getPeriod = (name: EPeriodDaysName): IDateRange => {
    const data: any = periods.find((period: IPeriod) => period.name === name);

    const period: IPeriod = {
      name: data.name,
      numberOfDays: data.numberOfDays,
    };

    return {
      period,
      startDate: formattedDate(new Date(), 'yyyy/MM/dd 00:00:00', period.numberOfDays),
      endDate: formattedDate(new Date(), 'yyyy/MM/dd HH:mm:ss'),
    };
  };

  const selectDatePicker = () => {
    setShowCalendar(true);
    date.period.name = 'selected';
  };

  const setPeriod = (dateRange: any) => {
    if (dateRange.startDate && dateRange.endDate) {
      setDate({
        startDate: formattedDate(new Date(dateRange.startDate), 'yyyy/MM/dd 00:00:00'),
        endDate: formattedDate(new Date(dateRange.endDate), 'yyyy/MM/dd 23:59:59'),
        period: {
          name: 'selected',
        },
      });
    }
  };

  const changePeriod = (period: EPeriodDaysName) => {
    setShowCalendar(false);
    setDate(getPeriod(period));
  };

  useEffect(() => {
    setDate(getPeriod(period));
  }, []);

  useEffect(() => {
    onChangeDate(date);
  }, [date]);

  return (
    <Content>
      <label>Filtrar por período: </label>
      <div className="parametersPeriod">
        <nav>
          <button
            className={`button-left ${date?.period.name === 'day' ? 'active' : 'inactive'}`}
            onClick={() => changePeriod(EPeriodDaysName.DAY)}
          >
            Hoje
          </button>
          <button
            className={`button-center ${date?.period.name === 'week' ? 'active' : 'inactive'}`}
            onClick={() => changePeriod(EPeriodDaysName.WEEK)}
          >
            7 dias
          </button>
          <button
            className={`button-center ${date?.period.name === 'month' ? 'active' : 'inactive'}`}
            onClick={() => changePeriod(EPeriodDaysName.MONTH)}
          >
            30 dias
          </button>
          <button
            className={`button-right ${date?.period.name === 'selected' ? 'active' : 'inactive'}`}
            onClick={() => selectDatePicker()}
          >
            Escolha o período
          </button>
        </nav>
        <div className="showRangePeriodSelected">
          {showCalendar ? (
            <div className="showDatePicker">
              <DatePicker
                locale={ptBr}
                dateFormat="dd/MM/yyyy"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update: any) => {
                  setDateRange(update);
                  setPeriod({
                    startDate: update[0],
                    endDate: update[1],
                  });
                }}
                minDate={new Date(formattedDate(new Date(), 'yyyy/MM/dd 10:00:00', -60))}
                maxDate={new Date()}
              />
            </div>
          ) : (
            (date && date.startDate && date.endDate) && (
              <div>
                {`${formattedDate(new Date(date.startDate))} - ${formattedDate(
                  new Date(date.endDate)
                )}`}
              </div>
            )
          )}
        </div>
      </div>
    </Content>
  );
};

export default DateRange;
