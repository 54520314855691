import { useContext, useEffect, useState, useLayoutEffect } from 'react';
import { Container, Filter, InputElement } from './styled';

import { message, Table, Modal, Row, Col } from 'antd';

import { WarningOutlined, CheckOutlined, PhoneOutlined } from '@ant-design/icons';

import NumberFormat from 'react-number-format';

import { formattedDate } from '../../utils/date-format';
import { LoadingContext } from '../../context/loading';
import { proposalService } from '../../service';
import { AuthContext } from '../../context/auth';

import Select from '../../components/Select';
import SelectInput from './SelectInput';

import { Loading, showToast } from '../../providers';
import { ProposalDeny } from './Interfaces';
import { Button, Input } from '../../components';
import DateRange from '../../components/DateRange';
import { EPeriodDaysName, IDateRange } from '../../entity/datetime.entity';

import { currencyFormat } from '../../utils/string-format';
import * as apiService from '../../service/iquiteiApi.service';
import theme from '../../theme';

const MOTIVO_TYPE = {
  OTHER: 'Outro',
  HIGH_VALUE: 'O valor está muito alto para pagar',
  MORE_INSTALLMENTS: 'Gostaria de mais parcelas',
  BAD_DUE_DATE: 'Não consigo pagar até o vencimento ofertado',
  NON_TRUSTED: 'Não senti segurança em negociar',
};

const ProposalsDeny = () => {
  const { setLoading: setLoadingContext } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const [status, setStatus] = useState('PENDING');

  const [credorFilter, setCredorFilter] = useState<string>('0');
  const [credorFiltered, setCredorFiltered] = useState<string>('');

  const [motivoFilter, setMotivoFilter] = useState<string>('');
  const [motivoFiltered, setMotivoFiltered] = useState<string>('');

  const [ProposalOptions, setProposalOptions] = useState<any>([]);
  const [OtherOptions, setOtherOptions] = useState<any[]>([]);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [negotiate, setNegotiate] = useState<any>({
    id: '',
    clientId: '',
    clientName: '',
    creditor: '',
    portionAmount: '',
    debt: '',
    portion: '',
    amount: '',
    status: '',
    step: '',
    type: '',
    other: '',
    negotiationExpiration: '',
    origin: '',
    operationId: '',
  });

  const [statusChange, setStatusChange] = useState<any>({ id: 0, status: '' });

  const [date, setDate] = useState<IDateRange>();
  const [modal, toggleModal] = useState(false);

  const [active, setActive] = useState<any[]>([
    {
      id: 'PENDING',
      active: true,
    },
    {
      id: 'CALLED',
      active: false,
    },
    {
      id: 'NEGOTIATED',
      active: false,
    },
  ]);

  const ControllerRequisition = new AbortController();

  useEffect(() => {
    setLoading(false);
    return () => ControllerRequisition.abort();
  }, []);

  useLayoutEffect(() => {
    (async () => {
      await fetchProposalDenyFiltered(status, credorFiltered);
    })();
  }, [statusChange]);

  useEffect(() => {
    setLoadingContext(false);
    (async () => {
      try {
        if (user && user.partner) {
          await fetchProposalDenyFiltered(status, credorFiltered);
        }
      } catch (error: any) {
        console.error(error);
        message.error(error);
      }
    })();
  }, [user, status, statusChange]);

  useEffect(() => {
    setLoading(false);
  }, [motivoFiltered]);

  useEffect(() => {
    setLoading(true);
    setMotivoFilter('0');
    setMotivoFiltered('');

    setCredorFilter('0');
  }, [status]);

  const columns = [
    {
      title: 'Cliente',
      dataIndex: 'name',
      key: 'name',
      align: 'left' as const,
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: 'CPF',
      dataIndex: 'cpf',
      key: 'cpf',
      align: 'left' as const,
      render: (cpf: string) => {
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
      // sorter: (a: any, b: any) => a.phonesLength - b.phonesLength,
    },
    {
      title: 'Telefone',
      dataIndex: 'phone',
      key: 'phone',
      align: 'left' as const,
      render: (phone: string) => {
        return phone.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, '($1) $2 $3-$4');
      },
      sorter: (a: any, b: any) => a.phonesLength - b.phonesLength,
    },
    {
      title: 'Credor',
      dataIndex: 'creditor',
      key: 'creditor',
      align: 'left' as const,
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: 'Campanha',
      dataIndex: 'shipmentName',
      key: 'shipmentName',
      align: 'left' as const,
      sorter: (a: any, b: any) => a.name.length - b.name.length,
    },
    {
      title: 'Motivo',
      dataIndex: ['type', 'other'],
      key: 'type',
      align: 'left' as const,
      render: (tamanho: any, proposal: ProposalDeny) => {
        switch (proposal.type) {
          case 'OTHER':
            return `Outro: ${proposal.other}`;
          case 'HIGH_VALUE':
            return 'O valor está muito alto para pagar';
          case 'MORE_INSTALLMENTS':
            return 'Gostaria de mais parcelas';
          case 'BAD_DUE_DATE':
            return 'Não consigo pagar até o vencimento ofertado';
          case 'NON_TRUSTED':
            return 'Não senti segurança em negociar';
          default:
            return 'Não identificado';
        }
      },
      sorter: (a: any, b: any) => a.entriesLength - b.entriesLength,
    },
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (value: string) => {
        return formattedDate(new Date(value));
      },
      align: 'left' as const,
      sorter: (a: any, b: any) => a.createdAt - b.createdAt,
    },
    {
      title: 'Status',
      dataIndex: ['status', 'id'],
      key: 'status',
      render: (tamanho: any, { status, id }: ProposalDeny) =>
        status !== 'NEGOTIATED' ? (
          <div style={{ marginTop: 8 }}>
            <SelectInput state={status} id={id} callback={onStatusChange} />
          </div>
        ) : (
          <div className="container-status">
            <h5 className="status">Negociado</h5>
          </div>
        ),
      sorter: (a: any, b: any) => a.status - b.status,
    },
  ];

  const handleActiveFilter = (name: string) => {
    active.forEach((item, index) =>
      item.id === name ? (active[index].active = true) : (active[index].active = false)
    );
    setStatus(name);
  };

  const onStatusChange = async (id, status) => {
    try {
      setStatusChange({ id, status });

      const proposal: any = data.find((proposal: any) => proposal.id === id);

      if (status === 'NEGOTIATED') {
        setNegotiate({
          id: proposal?.id || '',
          clientId: proposal?.clientId || '',
          clientName: proposal?.name || '',
          creditor: proposal?.creditor || '',
          debt: proposal?.debt,
          portionAmount: 0,
          portion: 0,
          amount: 0,
          status: proposal?.status || '',
          step: proposal?.step || '',
          type: proposal?.type || '',
          other: proposal?.other || '',
          negotiationExpiration: '',
          operationId: user.id,
          // cpf: proposal?.cpf || '',
        });

        setOpenModal((enable) => !enable);
      }

      const body = {
        status,
        step: proposal.step,
        type: proposal.type,
        other: proposal.other,
      };

      if (status !== 'NEGOTIATED') {
        await proposalService.updatePorposalsDeny(user.partner.id, id, body);

        message.success('Status da Negociação alterado com sucesso!');
      } else {
        await fetchProposalDenyFiltered(status, credorFilter);
      }
    } catch (error: any) {
      console.error(error);
      message.error(error);
    }
  };

  const filterItems = [
    {
      title: <p className="title">Pendentes</p>,
      icon: (
        <div className="icon">
          <WarningOutlined />
        </div>
      ),
      name: 'PENDING',
    },
    {
      title: <p className="title">Contatados</p>,
      icon: (
        <div className="icon">
          <PhoneOutlined />
        </div>
      ),
      name: 'CALLED',
    },
    {
      title: <p className="title">Negociados</p>,
      icon: (
        <div className="icon">
          <CheckOutlined />
        </div>
      ),
      name: 'NEGOTIATED',
    },
  ];

  const handleMotivoFilter = (value: string) => {
    setLoading(true);

    setMotivoFilter(value);
    setMotivoFiltered(OtherOptions[value]._id);
  };

  const handleSetCredorFilter = async (value) => {
    setCredorFilter(value);

    const filteredProposalDeny = await proposalService.listPorposalsDeny(
      user.partner.id,
      ControllerRequisition.signal,
      status,
      ProposalOptions[value]._id
    );
    setData(filteredProposalDeny);

    const otherOption = filteredProposalDeny
      .filter((value, index, self) => index === self.findIndex((t) => t.type === value.type))
      .map((i) => ({ name: MOTIVO_TYPE[i.type] || 'Não identificado', _id: i.type }));
    otherOption.unshift({ name: 'Todos', _id: '' });
    setOtherOptions(otherOption);
  };

  const fetchProposalDenyFiltered = async (status?: string, creditor?: string) => {
    try {
      setLoading(true);

      const filteredProposalDeny = await proposalService.listPorposalsDeny(
        user.partner.id,
        ControllerRequisition.signal,
        status,
        creditor
      );
      setData(filteredProposalDeny);

      const otherOption = filteredProposalDeny
        .filter((value, index, self) => index === self.findIndex((t) => t.type === value.type))
        .map((i) => ({ name: MOTIVO_TYPE[i.type] || 'Não identificado', _id: i.type }));
      otherOption.unshift({ name: 'Todos', _id: '' });
      setOtherOptions(otherOption);

      const creditorOption = filteredProposalDeny
        .filter(
          (value, index, self) => index === self.findIndex((t) => t.creditor === value.creditor)
        )
        .map((i) => ({ name: i.creditor, _id: i.creditor }));
      creditorOption.unshift({ name: 'Todos', _id: '' });
      setProposalOptions(creditorOption);

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };;

  const validaData = (d) => {
    const dataToday = new Date();

    let day = dataToday.getDate();
    let month = dataToday.getMonth() + 1;
    let year = dataToday.getFullYear();

    let dataArray = '';
    if (month < 10) {
      dataArray = `${day}0${month}${year}`;
    } else {
      dataArray = `${day}${month}${year}`;
    }

    let compYear = d.slice(4, 8);
    let compMonth = d.slice(2, 4);
    let compDay = d.slice(0, 2);

    let d1 = new Date(year, month, day);
    let d2 = new Date(compYear, compMonth, compDay);

    let diff = d2.getTime() - d1.getTime();

    if (compMonth > 12 || compMonth < 1) {
      return true;
    }
    if (compDay > 31 || compDay < 0) {
      return true;
    }
    if (Number(compYear[0]) !== 2) {
      return true;
    }

    if (diff <= 0) {
      return true;
    } else {
      return false;
    }
  };

  const validateNegotaite = async () => {
    try {
    if (!negotiate.portionAmount || negotiate.portionAmount === '') {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'Você precisa informar qual é o valor do débito sem desconto',
      });
    }
    if (negotiate.portionAmount < 0 || negotiate.amount < 0) {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'Valores negativos são inválidos',
      });
    }
    if (!negotiate.negotiationExpiration || negotiate.negotiationExpiration === '') {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'Você precisa informar qual é a data de expiração da negociação',
      });
    }
    if (validaData(negotiate.negotiationExpiration)) {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'A data informada é inválida',
      });
    }
    if (!negotiate.portion || negotiate.portion === '') {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'Você precisa informar qual é a quantidade de parcelas',
      });
    }
    if (!negotiate.debt || negotiate.debt === '') {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'Você precisa informar qual é o valor do débito',
      });
    }
    if (negotiate.portion < 0) {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'O número de parcelas é inválido',
      });
    }
    if (!negotiate.amount || negotiate.amount === '') {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'Você precisa informar qual é o montante',
      });
    }
    return '';
    } catch (e) {
      throw e;
    }
  };

  const handleCalculateAmount = () => {
    if (negotiate.amount && negotiate.portion) {
      let account = negotiate.amount / negotiate.portion;

      setNegotiate({
        ...negotiate,
        portionAmount: account,
      });
    }
  };

  useEffect(() => {
    handleCalculateAmount();
  }, [negotiate.amount, negotiate.portion]);

  const handleCreateNegotiation = async (negotiate) => {
    try {
      const updateStatus = {
        status: 'NEGOTIATED',
        step: negotiate.step,
        type: negotiate.type,
        other: negotiate.other,
      };

      await validateNegotaite();

      await proposalService.updatePorposalsDeny(user.partner.id, negotiate.id, updateStatus);

      const body = {
        id: negotiate.clientId,
        portionAmount: negotiate.portionAmount,
        portion: negotiate.portion,
        amount: negotiate.amount,
        debt: negotiate.debt,
        negotiationExpiration: negotiate.negotiationExpiration,
        partnerId: user.partner.id,
        operationId: user.id,
        origin: 'OPERATION',
      };

      await apiService.postRequest(`/partners/${user.partner.id}/v2/negotiate`, body);
      await fetchProposalDenyFiltered(status, credorFiltered);

      message.success('Negociação resolvida com sucesso!');
    } catch (error: any) {
      console.error(error);
      message.error(error);
    } finally {
      setOpenModal(false);
    }
  };

  const standardStyle = {
    borderRadius: theme.border.radius,
    height: '46px',
    width: '100%',
    fontSize: theme.text.size.md,
    border: 'none',
    marginBottom: '10px',
    marginLeft: '0px',
    fontWeight: 'bold' as 'bold',
    paddingLeft: '20px',
    paddingRight: '20px',
  };

  const secondaryStyle = {
    ...standardStyle,
    backgroundColor: theme.colors.light,
    border: 1,
    borderStyle: 'solid',
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
  };

  const primaryStyle = {
    ...standardStyle,
    background: theme.colors.primary,
    color: theme.colors.light,
  };


  return (
    <Container>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h2 style={{ fontWeight: 'bold' }}>Negociações recusadas:</h2>
        <Button
          text='Baixar Relatório'
          color='primary'
          onClick={() => toggleModal(true)}
          styleCustom={{ width: '15%' }}
        />
      </div>
      <Modal
        onOk={async () => proposalService.downloadProposalsDeny(user.partner.id, date?.startDate, date?.endDate)}
        cancelText="Cancelar"
        keyboard
        title="Baixar relatório de negociações recusadas"
        visible={modal}
        onCancel={() => toggleModal(false)}
        width='30%'
        okText="Baixar negociações recusadas"
        okButtonProps={{ style: primaryStyle }}
        cancelButtonProps={{ style: secondaryStyle }}
      >
        <DateRange
          period={EPeriodDaysName.WEEK}
          onChangeDate={(dateRange: IDateRange) => setDate(dateRange)}
        />
      </Modal>
      
      <Modal
        onOk={() => handleCreateNegotiation(negotiate)}
        bodyStyle={{
          width: 650,
          height: 275,
        }}
        cancelText="Cancelar"
        keyboard
        title="Cadastrar negociação"
        visible={openModal}
        onCancel={() => setOpenModal(false)}
        width="700px"
        okText="Salvar negociação"
        okButtonProps={{ style: primaryStyle }}
        cancelButtonProps={{ style: secondaryStyle }}
      >
        <form action="">
          <Row gutter={[16, 6]}>
            <Col span={14}>
              <label>Nome do cliente:</label>
              <Input
                disabled
                type="text"
                value={negotiate.clientName}
                onChange={(value: string) => setNegotiate({ ...negotiate, clientName: value })}
              />
            </Col>

            <Col span={9}>
              <label>Nome do credor:</label>
              <Input
                disabled
                type="text"
                value={negotiate.creditor}
                onChange={(value: string) => setNegotiate({ ...negotiate, creditor: value })}
              />
            </Col>
          </Row>

          <Row gutter={[16, 6]}>
            <Col span={9}>
              <label>Valor total a pagar:</label>
              <NumberFormat
                thousandsGroupStyle="thousand"
                value={Number(negotiate.amount)}
                prefix="R$ "
                decimalSeparator=","
                displayType="input"
                type="text"
                thousandSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={InputElement}
                onValueChange={({ value }) => setNegotiate({ ...negotiate, amount: Number(value) })}
              />
            </Col>

            <Col span={7}>
              <label>Quantidade de parcelas:</label>
              <Input
                type="number"
                value={negotiate.portion}
                onChange={(value: string) =>
                  setNegotiate({
                    ...negotiate,
                    portion: value,
                  })
                }
              />
            </Col>

            <Col span={7}>
              <label>Valor das parcelas:</label>
              <Input
                type="text"
                value={
                  !negotiate.amount || !negotiate.portion
                    ? ''
                    : currencyFormat(Number(negotiate.portionAmount))
                }
              />
            </Col>
          </Row>

          <Row gutter={[16, 6]}>
            <Col span={12}>
              <label>Valor original do débito:</label>
              <NumberFormat
                thousandsGroupStyle="thousand"
                value={Number(negotiate.debt)}
                prefix="R$ "
                decimalSeparator=","
                displayType="input"
                type="text"
                thousandSeparator="."
                decimalScale={2}
                fixedDecimalScale={true}
                customInput={InputElement}
                onValueChange={({ value }) => setNegotiate({ ...negotiate, debt: Number(value) })}
              />
            </Col>
            <Col span={11}>
              <label>Data de expiração:</label>
              <NumberFormat
                customInput={InputElement}
                value={negotiate.negotiationExpiration}
                format="##/##/####"
                onValueChange={({ value }) =>
                  setNegotiate({ ...negotiate, negotiationExpiration: value })
                }
              />
            </Col>
          </Row>
        </form>
      </Modal>

      <div className="filters">
        <div className="filters-proposal">
          {filterItems.map(({ title, icon, name }, index) => (
            <Filter
              active={active[index].active}
              key={`${index}-${name}`}
              onClick={() => handleActiveFilter(name)}
            >
              <div className="filter">
                {icon}
                {title}
              </div>
            </Filter>
          ))}
        </div>

        <div className="filterSelect">
          <div className="selectFilter" style={{ marginRight: 17 }}>
            <label>Credor</label>
            <Select
              firstDisabled
              optionKeys={{ value: 'id', displayName: 'name' }}
              options={ProposalOptions}
              value={credorFilter}
              onChange={(value) => handleSetCredorFilter(value)}
            />
          </div>
          <div className="selectFilter">
            <label>Motivo</label>
            <Select
              firstDisabled
              optionKeys={{ value: 'id', displayName: 'name' }}
              options={OtherOptions}
              value={motivoFilter}
              onChange={(value: any) => handleMotivoFilter(value)}
            />
          </div>
        </div>
      </div>
      <div className="proposal-list">
        {loading && <Loading relative />}
        <Table
          className="ant-table-tbody"
          dataSource={data.filter((i: any) => (motivoFiltered ? i.type === motivoFiltered : true))}
          columns={columns}
          pagination={{ defaultCurrent: 1, defaultPageSize: 10 }}
          rowKey={(item: any, index) => `${item.id}${index}`}
          locale={{
            triggerDesc: 'Clique para ordenar de forma decrescente',
            triggerAsc: 'Clique para ordenar de forma crescente',
            cancelSort: 'Clique para cancelar a ordenação',
          }}
          rowClassName="table"
          bordered
        />
      </div>
    </Container>
  );
};

export default ProposalsDeny;
