import { useEffect, useState, useContext } from 'react';
import { CheckOutlined } from '@ant-design/icons';

import {
  getIntegrationCredentials,
  updateIntegrationCredentials,
} from '../../../service/integration.service';
import { Container, ContainerChild } from './styled';
import { Loading, showToast } from '../../../providers';
import { LoadingContext } from '../../../context/loading';
import { AuthContext } from '../../../context/auth';
import Input from '../../../components/Input';
import Select from '../../../components/Select';
import { Button } from '../../../components';

const Integration: React.FC = () => {
  const { setLoading: setLoadingContext } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [partnerCreditorCredentials, setPartnerCreditorCredentials] = useState<any[]>([]);
  const [selectedCreditor, setSelectedCreditor] = useState<any>('');

  const fetchPartnerCreditorCredentials = async () => {
    const response: any[] = await getIntegrationCredentials(user.partner.id);

    if (response.length) {
      setPartnerCreditorCredentials(response);
    }
  };

  const validateFields = () => {
    if (!selectedCreditor.username || selectedCreditor.username === '') {
      throw showToast({
        type: 'error',
        message: 'Campo do usuário não podem estar vazio',
      });
    }
    if (!selectedCreditor.password || selectedCreditor.password === '') {
      throw showToast({
        type: 'error',
        message: 'Campo da senha não pode estar vazia',
      });
    }
  };

  const handleCreateIntegrationCredentials = async () => {
    try {
      setLoading(true);

      validateFields();

      const updateData = {
        partnerId: selectedCreditor.partnerId,
        creditorId: selectedCreditor.creditorId,
        username: selectedCreditor.username,
        password: selectedCreditor.password,
      };

      await updateIntegrationCredentials(updateData);

      showToast({
        type: 'success',
        message: 'Credenciais de integração atualizadas com sucesso!',
      });

      setSelectedCreditor('')

      await fetchPartnerCreditorCredentials();

      setLoading(false);
    } catch (error: any) {
      console.error(error);
      throw error;
    }
  };

  const handleSelectCredentials = (creditorId) => {
    const creditor = partnerCreditorCredentials.find((pcc) => pcc.creditorId === creditorId);
    setSelectedCreditor({ ...creditor });
  };

  useEffect(() => {
    (async () => {
      if (user) {
        await fetchPartnerCreditorCredentials();
        setLoadingContext(false);
      }
    })();
  }, [user]);

  return (
    <Container>
      <h1 className="title">Configurações de integração</h1>

      <ContainerChild>
        {loading && <Loading relative />}
        <div className="selectInput">
          <label>Credor: </label>
          <Select
            placeholder="-- Selecione o credor --"
            firstDisabled
            optionKeys={{ value: 'creditorId', displayName: 'creditorName' }}
            value={selectedCreditor !== '' ? selectedCreditor.creditorId : ''}
            options={partnerCreditorCredentials}
            onChange={(value: any) => handleSelectCredentials(value)}
          />
        </div>

        {selectedCreditor && selectedCreditor !== '' && (
          <div>
            <div className="inputGroup">
              <label>Usuário de integração</label>
              <Input
                // loading={loading}
                autoComplete="off"
                type="text"
                value={selectedCreditor.username}
                onChange={(value: string) =>
                  setSelectedCreditor({ ...selectedCreditor, username: value })
                }
              />
            </div>

            <div className="inputGroup">
              <label>Senha de integração</label>
              <Input
                // loading={loading}
                autoComplete="off"
                type="text"
                value={selectedCreditor.password}
                onChange={(value: string) =>
                  setSelectedCreditor({ ...selectedCreditor, password: value })
                }
              />
            </div>

            <div className="button">
              <Button
                color={'primary'}
                text={'Salvar dados'}
                icon={<CheckOutlined />}
                onClick={() => handleCreateIntegrationCredentials()}
              />
            </div>
          </div>
        )}
      </ContainerChild>
    </Container>
  );
};

export default Integration;
