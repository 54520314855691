const cpfConvert = (cpf: string | number) => {
  let cpfCoverted = cpf.toString();
  if (cpfCoverted.length < 11) {
    const CPF = cpf.toString();
    const cpfLength = CPF.length - 1;
    const cpfSize = 10;
    const counter = cpfSize - cpfLength;
    cpfCoverted = '';
    
    for (let index = 0; index < counter; index += 1) {
      cpfCoverted += '0';
    }
    cpfCoverted += CPF;
  }
  return cpfCoverted;
};

export default cpfConvert;
