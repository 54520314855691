import React from 'react';

import { Input as AntdInput } from 'antd';

import theme from '../../theme';

interface TextAreaProps {
  value: string;
  onChange?: (value: string) => any;
  placeholder?: string;
  rows?: number;
  disabled?: boolean;
  id?: string;
}

const style = {
  borderRadius: theme.border.radius,
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: theme.colors.lightGrey,
  marginBottom: '10px',
};

const TextArea: React.FC<TextAreaProps> = ({
  value,
  onChange,
  placeholder,
  rows,
  disabled,
  id,
}) => {
  return (
    <AntdInput.TextArea
      id={id}
      placeholder={placeholder}
      style={style}
      value={value}
      rows={rows}
      disabled={disabled}
      onChange={(event: any) => onChange && onChange(event.target.value)}
    />
  );
};

export default TextArea;
