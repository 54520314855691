import styled from 'styled-components';

export const Container = styled.div`
  background: #f0f0f0;
  position: relative;
  margin: 40px 20px;
  width: 400px;
  height: 600px;
  border-radius: 10px;
  padding: 20px;

  .text-break-spaces {
    white-space: pre-wrap;
    text-align: center;
  }
`;
