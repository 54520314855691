import { CloseOutlined,CheckOutlined } from '@ant-design/icons';
import { Button, Input, TextArea } from '..';
import { Modal } from 'antd';
import { useContext, useState, useEffect, createContext } from 'react';
import { showToast } from '../../providers';
import { insertNegotiationsText } from '../../service/negotiationsText.service';
import { AuthContext } from '../../context/auth';


interface ModalShipmentDetailsProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  setTextNegociations: (values: any) => any;
  textNegociations: (values: any) => any;
  setSelected : (values: any) => any;
  setLoading : (values: any) => any;
  setNegotiationsText : (values: any) => any;
  setName : (values: any) => any;
  
}


const ModalNewText: React.FC<ModalShipmentDetailsProps> = ({ isOpen, onClose, setTextNegociations, textNegociations, setSelected, setLoading, setNegotiationsText, setName }) => {
  const [form, setFormText] = useState<any>({
    name: '',
  });
  const { user, getUserData } = useContext(AuthContext);
  const handleCancel = () => {
    onClose(false);
    setFormText({
      name: ''
    })
  };


  const checkUndefined = (item) => !checkUndefined || item.toString().trim() === '';

  const handleSave = async () => {
    
    const textPadrao = '';

    const insertText ={
      name : form.name,
      text : textPadrao,
    }

    if(checkUndefined(form.name)) {
      throw showToast({
        type: 'error',
        message: 'O nome da Campanha não pode estar vazio.',
      });
    }

    if(checkUndefined(form.text)) {
      throw showToast({
        type: 'error',
        message: 'O texto da Campanha não pode estar vazio.',
      });
    }

      const returnID = await insertNegotiationsText(user.partner.id, insertText);
      setSelected(returnID.id);
      setNegotiationsText(returnID.text)
      setLoading(true)
      setName(form.name)
      onClose(true);
      setFormText({
        name: ''
      })

    
  };

   

  return (
    <Modal
      title="Novo Texto"
      cancelText={'Fechar'}
      visible={isOpen}
      onCancel={handleCancel}
      footer={
        <div className="modal-footer-buttons">
          <Button color="primary" 
          text="Salvar" 
          icon={<CheckOutlined />} 
          onClick={handleSave} 
          />
        </div>
      }
    >
      <div>
        <label>Nome :</label>
        <Input
          type="text"
          value={form.name}
          placeholder="Nome da campanha"
          onChange={(value) => setFormText({name: value, text:value})}
          
        />
      </div>
    </Modal>
  );
};

export default ModalNewText;
