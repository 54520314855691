import { useState } from 'react';
import { Select } from '../../../components';
import theme from '../../../theme';

export default function SelectProposal({ state, callback, id }) {
  const [status, toggleStatus] = useState<string>(state);

  const STATUS = [
    { id: 'NEGOTIATED', name: 'NEGOTIATED', div: <div style={{ color: `${theme.colors.success}`, fontWeight: 'bold' }}>Negociado</div>, _id: 'NEGOTIATED'},
    { id: 'CALLED', name: 'CALLED', div: <div style={{ color: `${theme.colors.warning}`, fontWeight: 'bold' }}>Contatado</div>, _id: 'CALLED'},
    { id: 'PENDING', name: 'PENDING', div: <div style={{ color: `${theme.colors.danger}`, fontWeight: 'bold' }}>Pendente</div>, _id: 'PENDING'},
  ];

  const STATUSCONTACT = [
    { id: 'NEGOTIATED', name: 'NEGOTIATED', div: <div style={{ color: `${theme.colors.success}`, fontWeight: 'bold' }}>Negociado</div>, _id: 'NEGOTIATED'},
    { id: 'CALLED', name: 'CALLED', div: <div style={{ color: `${theme.colors.warning}`, fontWeight: 'bold' }}>Contatado</div>, _id: 'CALLED'},
  ];

  const onChangeValue = (value) => {
    toggleStatus(value);

    let state = '';

    if(value === 'NEGOTIATED') {
      state = 'NEGOTIATED';
    } else if (value === 'CALLED') {
      state = 'CALLED';
    } else {
      state = 'PENDING'
    }
    
    callback(id, state);
  };

  return (
    <Select
      options={state === 'PENDING' ? STATUS : STATUSCONTACT}
      value={status}
      onChange={onChangeValue}
      optionKeys={{ value: 'id', displayName: 'name', div: 'div'}}
      placeholder="Proposta"
      firstDisabled
    />
  );
};
