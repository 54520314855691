import { StepConfirmateCampaignContainer } from './styled';
import { TextArea } from '../../../../../../components';
import { formattedFullDate } from '../../../../../../utils/date-format';
import { currencyFormat } from '../../../../../../utils/string-format';
import InfoTooltip from '../../../../../../components/InfoTooltip';

const StepConfirmateCampaign = ({ formCampaign, setFormCampaign }: any) => {
  return (
    <StepConfirmateCampaignContainer>
      <div className="columns column-form">
        <div className="configurate-campaing">
          <label>Configurações:</label>
          <div>
            Nome da remessa: <InfoTooltip text="Este foi o nome dado para a sua campanha" />{' '}
            <b>{formCampaign.name}</b>
          </div>
          {Number(formCampaign?.creditor?.creditorId) === 6 &&
          <div>
            Nome da negociação: <InfoTooltip text="Este foi o nome informado da negociação" />{' '}
            <b>{formCampaign.negotiationName}</b>
          </div>}
          <div>
            Total de clientes:{' '}
            <InfoTooltip text="Número total de clientes a serem atingidos com a sua campanha" />{' '}
            <b> {formCampaign.file.response.data.sizeFile} </b>
          </div>
          <div>
            Data do disparo:{' '}
            <InfoTooltip text="A data selecionada para que sua campanha aconteça" />{' '}
            <b> {formattedFullDate(formCampaign.shippingDate, 'dd/MM/yyyy HH:mm')} </b>
          </div>
          <div>
            Operadora: <InfoTooltip text="A operadora selecionada para que sua campanha aconteça" />{' '}
            <b> {formCampaign.operator.name} </b>
          </div>
          <div>
            Tipo: <InfoTooltip text="O tipo de SMS escolhido para ser usado em sua campanha" />{' '}
            <b> {formCampaign.operatorType.name} </b>
          </div>

          <div>
            Investimento estimado da remessa:
            <InfoTooltip text="O valor estimado de acordo com as configurações de sua campanha" />{' '}
            <b>
              {currencyFormat(
                formCampaign.file.response.data.sizeFile * formCampaign.operatorType.value
              )}
            </b>
          </div>
          {/* <div className="line-dot">
             className={`dot ${formCampaign.whatsapp ? "green" : "red"}`}
            WhatsApp
          </div> */}
          <div className="line-dot">
            <div>
              <span className={`dot ${formCampaign.call ? 'green' : 'red'}`} />
              Central de atendimento
              <InfoTooltip text="Sinaliza se a opção de Central de atendimento foi marcada ou não durante a configuração de sua campanha" />
            </div>
            <div>
              <span className={`dot ${formCampaign.buttonDeny ? 'green' : 'red'}`} />
              Botão de recusar negociação
              <InfoTooltip text="Sinaliza se a opção de Recusar Negociação será mostrada ou não para o cliente durante a jornada de negociação”" />
            </div>
          </div>
        </div>
      </div>
      <div className="columns column-phone">
        <label>Texto SMS:</label>
        <TextArea id="sms-text-input" rows={5} value={formCampaign.smsText} />
        <label>Texto da página:</label>
        <TextArea id="sms-text-input" rows={5} value={formCampaign.negotiateText} />
      </div>
    </StepConfirmateCampaignContainer>
  );
};

export default StepConfirmateCampaign;
