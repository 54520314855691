import { Container, Logo, Card } from './styled';
import { Button, Input } from '../../components';
import logo from '../../assets/images/iquitei-logo.png';
import { useEffect, useState } from 'react';
import { message } from 'antd';

import { useHistory } from 'react-router-dom';
import { Loading, showToast } from '../../providers';
import { routeParams } from '../../config/routes.config';
import { authService } from '../../service';

export default function ForgotPassword() {
  const history = useHistory();

  const [loading, setLoading] = useState<boolean>(false);

  const [successMessage, setSuccessMessage] = useState<string | undefined>(undefined);

  const counterDuration = 10000;

  const [counter, setCounter] = useState<number>(0);

  const [form, setForm] = useState({
    email: '',
  });

  const send = async () => {
    setLoading(true);

    try {
      if (checkValidForm(form)) {
        await authService.checkUser(form.email);
        await authService.sendForgotPassword(form.email);

        setLoading(false);

        setSuccessMessage(
          'O link para a troca de senha foi enviado. Verifique sua caixa de e-mail.'
        );

        setInterval(() => {
          window.location.href = routeParams.ROUTE_LOGIN;
        }, counterDuration);

        setCounter(counterDuration / 1000);
      }
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const checkValidForm = (form: any) => {
    if (!form.email || form.email === '') {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'Você precisa informar qual é o seu e-mail',
      });
    }

    if (!(form.email.includes('@') && form.email.includes('.com'))) {
      setLoading(false);
      throw showToast({
        type: 'error',
        message: 'O e-mail informado é inválido. Verifique se escreveu corretamente.',
      });
    }

    return true;
  };

  useEffect(() => {
    if (counter > 0) {
      setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    }
  }, [counter]);

  return (
    <>
      {loading && <Loading />}
      <Container>
        <Card>
          <Logo>
            <img src={logo} alt="logo" />
          </Logo>

          {!successMessage ? (
            <>
              <div className="forgot-message">
                Digite seu e-mail abaixo para receber o link para a troca de senha.
              </div>

              <Input
                type="text"
                placeholder="Qual o seu e-mail?"
                value={form.email}
                onChange={(value: string) => setForm({ ...form, email: value })}
              />

              <Button color="primary" text="Enviar" onClick={send} />

              <div className="go-back">
                <Button
                  color="no-border"
                  text="Voltar para login"
                  onClick={() => history.push(routeParams.ROUTE_LOGIN)}
                />
              </div>
            </>
          ) : (
            <>
              <div className="forgot-message">
                <b>{successMessage}</b>
              </div>
              <div className="forgot-message">
                Você será redirecionado para o login em: {counter}...
              </div>
            </>
          )}
        </Card>
      </Container>
    </>
  );
}
