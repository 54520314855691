import { Container, UserProfileForm } from './styled';
import { Input, Button } from '../../components';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { LoadingContext } from '../../context/loading';
import { showToast } from '../../providers';
import { userService } from '../../service';
import { Avatar, Switch } from 'antd';
import { EditOutlined, UserOutlined } from '@ant-design/icons';

const UserProfile = () => {
  const { setLoading } = useContext(LoadingContext);
  const { user, getUserData } = useContext(AuthContext);

  const [photo, setPhoto] = useState('');

  const [isShowChangePassword, setIsShowChangePassword] = useState(false);

  const [form, setForm] = useState({
    name: '',
    email: '',
    profile: '',
    password: '',
    confirmPassword: '',
  });

  const handleSaveUser = async () => {
    try {
      setLoading(true);

      const { name, email, password, confirmPassword } = form;

      if (isShowChangePassword) {
        if (!password || password === '') {
          setLoading(false);
          return showToast({
            type: 'error',
            message: 'Sua senha não pode ser vazia.',
          });
        }

        if (password !== confirmPassword) {
          setLoading(false);
          return showToast({
            type: 'error',
            message: 'As senhas são diferentes. Digite novamente',
          });
        }
      }

      const res: any = await userService.updateProfile(
        user.partner.id,
        name,
        isShowChangePassword ? password : null,
        email
      );

      getUserData();

      setForm({
        ...form,
        password: '',
        confirmPassword: '',
      });
      showToast({ type: 'success', message: res });
      setLoading(false);
      setIsShowChangePassword(false);
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      showToast({
        type: 'error',
        message: err,
      });
    }
  };

  const onFileChange = async (event: any) => {
    try {
      setLoading(true);

      const file: File = event.target.files[0];

      const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png'];

      if (!allowedExtensions.includes(file.type)) {
        return showToast({
          type: 'error',
          message: 'Formato de imagem inválido. Formatos aceitos: JPG, JPEG e PNG',
        });
      }

      await userService.updateProfilePicture(user.partner.id, user.id, file);

      getUserData();

      showToast({
        type: 'success',
        message: 'Imagem de perfil atualizada com sucesso!',
      });

      return setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      throw showToast({
        type: 'error',
        message: err,
      });
    }
  };

  useEffect(() => {
    if (user && user.partner && user.partner.id) {
      setForm({
        ...form,
        email: user.email,
        name: user.name,
        profile: user.profile,
      });
      setLoading(false);

      setPhoto(user.imageUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Container>
      <h2>Meu perfil</h2>

      <div className="card">
        <UserProfileForm>
          <div>
            <div className="avatar">
              <Avatar size={80} shape="circle" icon={<UserOutlined />} src={photo} />
            </div>

            <div className="edit-input">
              <label id="edit-icon" htmlFor="input-file">
                <EditOutlined />
              </label>

              <input id="input-file" type="file" onChange={(event) => onFileChange(event)} />
            </div>
          </div>

          <div className="input">
            <label className="label">Nome:</label>
            <Input
              type="text"
              placeholder="Digite aqui o seu nome"
              value={form.name}
              onChange={(value: string) => setForm({ ...form, name: value })}
            />
          </div>

          <div className="input">
            <label className="label">E-mail:</label>
            <Input
              type="email"
              disabled
              placeholder="Digite aqui o seu e-mail"
              value={form.email}
            />
          </div>

          <div className="input">
            <label className="label">Perfil de acesso:</label>
            <Input
              type="text"
              disabled
              placeholder="Digite aqui o seu perfil de acesso"
              value={form.profile}
            />
          </div>

          <div className="switch-show-change-password">
            <label> Você deseja trocar a sua senha? &ensp;</label>
            <Switch
              size="default"
              checkedChildren="Sim"
              unCheckedChildren="Não"
              checked={isShowChangePassword}
              onChange={(event: any) => setIsShowChangePassword(event)}
            />
          </div>

          {isShowChangePassword && (
            <>
              <div className="input">
                <label className="label">Nova senha:</label>
                <Input
                  type="password"
                  placeholder="Nova senha"
                  value={form.password}
                  onChange={(value: string) => setForm({ ...form, password: value })}
                />
              </div>

              <div className="input">
                <label className="label">Confirmar senha:</label>
                <Input
                  type="password"
                  placeholder="Confirmar senha"
                  value={form.confirmPassword}
                  onChange={(value: string) => setForm({ ...form, confirmPassword: value })}
                />
              </div>
            </>
          )}

          <div className="button">
            <Button color="primary" text="Salvar alterações" onClick={handleSaveUser} />
          </div>
        </UserProfileForm>
      </div>
    </Container>
  );
};

export default UserProfile;
