import { Container } from './styled';

export default function RadioSelect({ checked, onChange, text }) {
  return (
    <Container>
      <label htmlFor="isEditing-create-partner">
        <input
          id="isEditing-create-partner"
          type="checkbox"
          className="switch"
          checked={checked}
          onClick={onChange}
        />
        {text}
      </label>
    </Container>
  )
};
