import { createContext } from 'react';

interface AuthState {
  user: any;
  setUser: (user: any | undefined) => void;
  authUser: (email: string, password: string) => Promise<boolean>;
  logoutUser: () => void;
  getUserData: () => Promise<any>;
}

const initialState: AuthState = {
  user: undefined,
  setUser: (user: any) => {},
  authUser: async (email: string, password: string) => false,
  logoutUser: async () => {},
  getUserData: async () => {},
};

const AuthContext = createContext<AuthState>(initialState);

const AuthConsumer = AuthContext.Consumer;

export { AuthContext, AuthConsumer };

export type { AuthState };
