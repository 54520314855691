import styled from 'styled-components';
import theme from '../../../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .metrics {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    gap: ${theme.flexGrid.gap};
    padding-top: 25px;

    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: ${theme.flexGrid.gap};
    }
  }

  .shipment-details {
    display: flex;
    flex-direction: column;
    flex-flow: row;
    flex-wrap: wrap;
    flex-grow: 2;
    gap: ${theme.flexGrid.gap};

    .shipment-detail-field {
      position: relative;
      flex: 1;
    }
  }

  .actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    .btn-content {
      width: fit-content;
    }
  }

  .shipments-list {
    max-width: 90vw;
    overflow: auto;

    @media screen and (min-width: 768px) {
      /* max-width: 76vw; */
    }
  }

  .campaign-list {
    display: block;
    width: 100%;
    height: 500px;
    overflow: auto;
    text-align: center;
    border: 1px solid #B4B4B4;
    border-radius: 20px;
    padding: 10px 5px;

    button.campaign-to-compare {
      align-items: center;
      background-color: #D9D9D9;
      border: none;
      border-radius: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 5px 5px;
      padding-left: 45px;
      margin: 10px auto;
      width: 100%;
      cursor: pointer;
      transition: 0.3s;
      &:hover {
        background-color: ${theme.colors.grey};
        transition: 0.3s;
      }
    }

    button.close-preview-compare {
      align-self: end;
      background-color: transparent;
      border: none;
      color: red;
      cursor: pointer;
      padding-top: 20px;
      padding-right: 20px;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .campaign-details-others {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .campaign-preview-details {
    width: 90%;
    margin: 0 20px;
  }

  @media only screen and (min-width: 900px) {
    .campaign-details-others {
      flex-direction: row;
      justify-content: space-between;
    }

    .campaign-list, .campaign-preview-details {
      width: 50%;
    }
  }
`;

export const PreviewImage = styled.img`
  display: flex;

  margin-top: 20px;

  border-radius: 15px;

  box-shadow: 0 0 6px 3px rgba(0
    ,0, 0.1);
`;
