import * as iquiteiSvc from './iquiteiApi.service';

export async function getIntegrationCredentials(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/partnerIntegration`, {
      signal,
    });

    return res.data;
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao buscar credor do parceiro';
    throw err;
  }
}

export async function insertIntegrationCredentials({
  partnerId,
  creditorId,
  username,
  password,
  isActive,
}) {
  try {
    const res = await iquiteiSvc.postRequest(`/partners/${partnerId}/v2/partnerIntegration`, {
      partnerId,
      creditorId,
      username,
      password,
      isActive,
    });

    return res;
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500
        ? data.error
        : 'Ocorreu um erro ao criar as credenciais de integração';
    throw err;
  }
}

export async function updateIntegrationCredentials({ partnerId, creditorId, username, password }) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/v2/partnerIntegration`, {
      partnerId,
      creditorId,
      username,
      password,
    });

    return res;
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500
        ? data.error
        : 'Ocorreu um erro ao criar as credenciais de integração';
    throw err;
  }
}
