import React, { useCallback, useState } from 'react';
import { Container } from './styled';
import {
  PieChart as ReachartsPieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';
import { Loading } from '../../providers';


interface IData {
  title: string;
  data: any[];
  name: string;
  value: number;
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);
  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

export default function PieChart({ data, loading }) {
  return (
    <Container>
      <div className="title">Negociações e boletos acessados </div>
      {loading ? <Loading relative/> :
      <div className="content">
        <ResponsiveContainer>
          <ReachartsPieChart>
            <Pie
              data={data}
              labelLine={false}
              label={renderCustomizedLabel}
              nameKey='name'
              outerRadius={80}
              fill="#8884d8"
              dataKey="value"
              legendType='circle'
              cx='50%'
              cy='50%'
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
              ))}
            </Pie>
            <Tooltip />
            <Legend
                verticalAlign="top"
                height={36}
                align="right"
                layout="vertical"
                margin={{ left: 10, right: 10 }}
                iconSize={10}
                iconType="circle"
              />
          </ReachartsPieChart>
        </ResponsiveContainer>
      </div>
      }
    </Container>
  );
}
