import styled from 'styled-components';
import theme from '../../theme';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 10px;

  label {
    margin-right: 10px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  nav {
    .active {
      background: ${theme.colors.secondary};
      color: ${theme.colors.primary};
      border: 1px solid ${theme.colors.primary};
    }

    .inactive {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.secondary};
      border: none;
    }

    .button-left {
      border-radius: ${theme.border.radius} 0 0 ${theme.border.radius};
      /* border-right: 1px solid ${theme.colors.secondary} */
    }

    .button-right {
      border-radius: 0 ${theme.border.radius} ${theme.border.radius} 0;
      /* border-left: 1px solid ${theme.colors.secondary} */
    }

    button {
      padding: 10px;
      cursor: pointer;
    }
  }

  .showRangePeriodSelected {
    margin-left: 10px;
    border: 0.5px solid;
    padding: 10px;
    border-radius: ${theme.border.radius};
  }

  input {
    margin: -10px -10px -35px 5px;
    background-color: none;
  }

  .parametersPeriod {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;
  }
`;
