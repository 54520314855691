import { useContext, useEffect, useState } from 'react';
import { ArrowDownOutlined, ArrowUpOutlined, DownloadOutlined } from '@ant-design/icons';
import * as iquiteiSvc from '../../service/iquiteiApi.service';
import { EPeriodDaysName, IPeriod } from '../../entity/datetime.entity';
import { currencyFormat } from '../../utils/string-format';
import InfoTooltip from '../InfoTooltip';
import { Container } from './styled';
import { Loading } from '../../providers';
import { message } from 'antd';
import fileDownload from 'js-file-download';
import { formattedDate } from '../../utils/date-format';
import { AuthContext } from '../../context/auth';
import { reportsService } from '../../service';



interface NumericMetricsItem {
  period?: IPeriod;
  comparatorValue?: number;
  value: any;
  compAvg?: any;
  loading?: boolean;
}

interface NumericMetricsProps {
  title: string;
  tooltip?: any;
  data: NumericMetricsItem;
  showCurrency?: boolean;
  loading?: boolean;
  download?: {
    id?: number;
    fileName?: 'Proposal' | 'Access' | 'CPC' | 'Negotiate' | 'Click' | 'PreventiveAccess' | 'Clients' | 'Tickets' | 'PreventiveCPC';
    date?: any;
    campaignName?: string;
  };
  name?: string;
}

interface NegotiationsProps {
  nome: string;
  cpf: string;
  telefone: string;
  qtdParcelas: number;
  vlParcela: number;
  vlTotalDivida: number;
  dtVencimento: string;
  dtNegociacao: string;
  vlAtualDivida: number;
}

const calcAvgComparisonPercentage = (value: number, comparisonValue: number, period?: IPeriod) => {
  let amountDays: number = 1;

  if (period) {
    switch (period.name) {
      case EPeriodDaysName.DAY: {
        amountDays = period.numberOfDays + 1;
        break;
      }
      case EPeriodDaysName.WEEK: {
        amountDays = -period.numberOfDays;
        break;
      }
      case EPeriodDaysName.MONTH: {
        amountDays = -period.numberOfDays;
        break;
      }
      case EPeriodDaysName.SELECTED: {
        amountDays = -period.numberOfDays;
        break;
      }
      default:
        amountDays = 1;
    }
  }

  const difference = value - comparisonValue;

  const percent = parseFloat((((difference / comparisonValue) === Infinity ? 0 : (difference / comparisonValue)) * 100).toFixed(1));

  return percent;
  // return parseFloat(
  //   (value && comparisonValue && amountDays
  //     ? ((value / amountDays) * 100) / comparisonValue - 100
  //     : 0
  //   ).toFixed(2)
  // );
};

const growIndicator = (value: number) => {
  let element: any = null;

  switch (Math.sign(value)) {
    case 1:
      element = (
        <div className={'comparison positive'}>
          ({value && value !== Infinity && !Number.isNaN(value) ? value : 0}%) <ArrowUpOutlined />
        </div>
      );
      break;
    case -1:
      element = (
        <div className={'comparison negative'}>
          ({value && value !== Infinity && !Number.isNaN(value) ? value : 0}%) <ArrowDownOutlined/>
        </div>
      );
      break;
    default:
      element = <div className={'comparison'}>({value && value !== Infinity && !Number.isNaN(value) ? value : 0}%)</div>;
      break;
  }

  return element;
};

const NumericMetrics: React.FC<NumericMetricsProps> = ({ name, title, tooltip, data, showCurrency, loading, download }) => {

  const { user } = useContext(AuthContext);

  const convert = async (json) => {
    if (json.length === 0 || !json) {
      return '';
    }
    const parsedJson = typeof json !== 'object' ? JSON.parse(json) : json;

    if (
      !Array.isArray(parsedJson) ||
      !parsedJson.every((p) => typeof p === 'object' && p !== null)
    ) {
      return '';
    }
    const heading = Object.keys(parsedJson[0]).join(';') + '\n';
    const body = parsedJson.map((j) => Object.values(j).join(';') + '\n');
    return (`${heading}${body.join('')}`);
  };

  const handleDownloadFile = async (signal?: AbortSignal)  => {
    try {
      const partnerId =  user.partner.id;
      const mime = 'text/csv; charset=utf-8';

      let reports: any = null;

      if (title === 'Negociações' && name) {
        const { data }: any = await iquiteiSvc.getRequest(
          `/partners/${partnerId}/v2/negotiate/importNegotiateByDate/?initDate=${download?.date?.startDate}&endDate=${download?.date?.endDate}`, { signal }
        );

        const dataFormat: NegotiationsProps[] = data.map(( item ) => ({
          ...item,
          dtVencimento: formattedDate(new Date(item.dtVencimento), 'yyyy-MM-dd'),
          dtNegociacao: formattedDate(new Date(item.dtNegociacao), 'yyyy-MM-dd'),
        }));

        reports = dataFormat;
      } else if(name) {
        const data: any = await reportsService.reportByDay(partnerId, download?.date?.startDate, download?.date?.endDate, name);
        reports = data;
      } else if (title === 'Negociações' && !name) {
        const { data }: any = await iquiteiSvc.getRequest(
          `/partners/${partnerId}/v2/negotiate/importNegotiate/${download?.id}`, { signal }
        );

        const dataFormat: NegotiationsProps[] = data.map(( item ) => ({
          ...item,
          dtVencimento: formattedDate(new Date(item.dtVencimento), 'yyyy-MM-dd'),
          dtNegociacao: formattedDate(new Date(item.dtNegociacao), 'yyyy-MM-dd'),
        }));

        reports = dataFormat;
      } else {
        const { data }:any = await iquiteiSvc.getRequest(
          `/partners/${partnerId}/v2/exportReport/${download?.id}/downloadShipment${download?.fileName}Report`, { signal }
        );

        reports = data;
      }

      if(data === null){
        const err = `Não existe ${title} para baixar`;
        message.error(err);
        return '';
      }

      const csv = await convert(reports);

      fileDownload('\uFEFF' + csv, `${download?.campaignName || 'Relatório'}-${title}.csv`, mime);
    } catch (error: any) {
      const err = typeof error === 'string' ? error : 'Ocorreu um erro, por favor tente novamente';
      message.error(err);
    }
  };
  
  return (
    <Container>
      {loading && <Loading relative />}
      <div className="title">
        <div>
          {title}
          {tooltip != null && (
            <InfoTooltip text={tooltip} />
          )}
        </div>
      <div>
        {(data.value > 0 && download) && (
          <div>
            <DownloadOutlined
              alt='Download relatório de campanha'
              onClick={() => handleDownloadFile()}
              className='button-download'
            />
          </div>
        )}
      </div>
      </div>
      {data && (
        <div className="content">
          <div className="value">{showCurrency ? currencyFormat(data.value) : data.value}</div>
          <div className="percentage">
            {data.comparatorValue != null && (
              <div className="total">
                {data.value && data.comparatorValue
                  ? ((data.value / data.comparatorValue) * 100).toFixed(1)
                  : 0}
                %
              </div>
            )}
            {data?.compAvg != null
              ? growIndicator(calcAvgComparisonPercentage(data.value, data?.compAvg, data?.period))
              : ''}
          </div>
        </div>
      )}
    </Container>
  );
};

export default NumericMetrics;
