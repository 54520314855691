import styled from 'styled-components';
import theme from '../../../../../../theme';

export const StepUploadFileContainer = styled.div`
  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: ${theme.colors.primary};
  }
`;

export const MessageError = styled.span`
  color: ${theme.colors.issued};
`
