import * as iquiteiSvc from './iquiteiApi.service';

export async function getOperatorsType(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/operatorsType`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function getOperatorsTypeById(partnerId: number, id: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/operatorsType/${id}`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function createOperatorsType(partnerId: number, body) {
  try {
    const res = await iquiteiSvc.postRequest(`/partners/${partnerId}/v2/operatorsType`, body);

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function updateOperatorsType(partnerId: number, id: number, body: any) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/v2/operatorsType/${id}`, body);

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function deleteOperatorsType(partnerId: number, id: number) {
  try {
    const res = await iquiteiSvc.deleteRequest(`/partners/${partnerId}/v2/operatorsType/${id}`);

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}