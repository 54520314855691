import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .card {
    display: flex;
    justify-content: center;
    width: 600px;
    align-items: center;
    border-radius: ${theme.border.radius};
    margin-bottom: 10px;
    background: ${theme.colors.white};
    box-shadow: ${theme.boxShadow};
    color: ${theme.colors.primary};
    padding: 40px 30px;
  }

  @media screen and (max-width: 767px) {
    .card {
      width: 100%;
    }
  }

  .edit-input {
    width: 30px;
    height: 30px;
    border: 1px solid ${theme.colors.primary};
    color: ${theme.colors.primary};
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.lightGrey};
    margin-top: -35px;
    margin-left: 60px;
    z-index: 100;
    position: relative;
    cursor: pointer;
  }

  #edit-icon {
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .edit-input > input {
    display: none;
  }

  .edit-input img {
    width: 80px;
  }

  .switch-show-change-password {
    display: flex;
    justify-content: flex-end;
    font-size: ${theme.text.size.md};
    line-height: 15px;
    padding-top: 18px;
    color: ${theme.colors.grey};
  }

  .label {
    font-size: 14px;
    line-height: 19, 1px;
  }

  .input {
    padding-top: 10px;
  }

  .avatar {
    padding-bottom: 6px;
  }

  .button {
    padding-top: 10px;
  }
`;

export const UserProfileForm = styled.form`
  width: 100%;
`;
