import React from 'react';
import { Checkbox as AntdCheckbox } from 'antd';

interface SelectProps {
  defaultChecked: boolean | any;
  onChange: (value: boolean) => any;
}

const style = {
  padding: '4px',
};

const Checkbox: React.FC<SelectProps> = ({ defaultChecked, onChange }) => {
  return (
    <AntdCheckbox
      style={style}
      defaultChecked={defaultChecked}
      onChange={(event: any) => onChange && onChange(!defaultChecked)}
    />
  );
};

export default Checkbox;
