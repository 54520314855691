import { Container, GenerateReportsForm } from './styled';
import { Button, Select } from '../../components';
import { useContext, useEffect, useState } from 'react';
import { LoadingContext } from '../../context/loading';
import { message, Switch } from 'antd';
import { reportsService } from '../../service';
import { getPartnerCreditors } from '../../service/partner.service';
import { AuthContext } from '../../context/auth';
import { listShipments } from '../../service/shipments.service';
import { listReports } from '../../service/reports.service';
import DatePicker from '../../components/DatePicker';
import { formattedDate } from '../../utils/date-format';

const GenerateReports = () => {
  const { setLoading } = useContext(LoadingContext);

  const { user } = useContext(AuthContext);

  const [partnerCreditors, setPartnerCreditors] = useState<any>([]);
  const [partnerShipments, setPartnerShipments] = useState<any>([]);

  const [isShowExportShipment, setIsShowExportShipment] = useState<boolean>(false);

  const [reports, setReports] = useState<any>([]);

  const [exportFormat] = useState<any>([
    {
      name: 'csv',
    },
  ]);

  const [form, setForm] = useState<any>({
    startDate: '',
    endDate: '',
    creditorId: '',
    shipmentId: '',
    queryName: '',
    fileFormat: '',
  });

  const handleExportReport = async () => {
    try {
      setLoading(true);

      await reportsService.exportReport(
        user.partner.id,
        formattedDate(new Date(form.startDate), 'yyyy-MM-dd'),
        formattedDate(new Date(form.endDate), 'yyyy-MM-dd'),
        form.creditorId,
        form.shipmentId,
        form.queryName,
        form.fileFormat
      );

      setForm({
        startDate: '',
        endDate: '',
        creditorId: '',
        shipmentId: '',
        queryName: '',
        fileFormat: '',
      });

      setLoading(false);

      message.success('Relatório gerado com sucesso!');
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchPartnerCreditors = async (partnerId: number) => {
    try {
      const res: any[] = await getPartnerCreditors(partnerId);
      setPartnerCreditors(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchPartnerShipments = async (partnerId: number) => {
    try {
      const res: any[] = await listShipments(partnerId);
      setPartnerShipments(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchReports = async (partnerId: number) => {
    try {
      const res: any[] = await listReports(partnerId);
      setReports(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const showHideShipmentsOption = async (checked: boolean) => {
    if (!checked) {
      setForm({ ...form, shipmentId: null });
    }

    setIsShowExportShipment(checked);
  };

  useEffect(() => {
    setLoading(false);

    if (user && user.partner.id) {
      fetchPartnerCreditors(user.partner.id);
      fetchPartnerShipments(user.partner.id);
      fetchReports(user.partner.id);
    }
    // eslint-disable-next-line
  }, [user]);
  return (
    <Container>
      <h2>Exportar relatórios</h2>

      <div className="card">
        <GenerateReportsForm>
          <div className="card-message">
            <span>Selecione as opções abaixo antes de exportar seu relatório:</span>
          </div>

          <div>
            <label>Qual é o credor?</label>
            <Select
              placeholder="-- Selecione --"
              firstDisabled
              optionKeys={{ value: 'creditorId', displayName: 'creditorName' }}
              options={partnerCreditors}
              value={form?.creditorId}
              onChange={(value: string) => setForm({ ...form, creditorId: value })}
            />
          </div>

          <div id="switch-shipment">
            <label>Deseja exportar dados de uma remessa específica ou todos?</label>
            <Switch
              size="default"
              checkedChildren="Remessa"
              unCheckedChildren="Todos"
              checked={isShowExportShipment}
              onChange={(checked: boolean) => showHideShipmentsOption(checked)}
            />
          </div>

          {isShowExportShipment && (
            <div>
              <label>Qual é a remessa?</label>
              <Select
                placeholder="-- Selecione --"
                firstDisabled
                optionKeys={{ value: 'id', displayName: 'name' }}
                options={partnerShipments}
                value={form?.shipmentId}
                onChange={(value: string) => setForm({ ...form, shipmentId: value })}
              />
            </div>
          )}

          <div>
            <label>Início período:</label>
            <DatePicker
              placeholder="-- Selecione --"
              pattern="dd/MM/yyyy"
              value={form.startDate}
              onChange={(date: Date) => setForm({ ...form, startDate: date })}
            />
          </div>

          <div>
            <label>Fim período:</label>
            <DatePicker
              placeholder="-- Selecione --"
              pattern="dd/MM/yyyy"
              value={form.endDate}
              onChange={(date: Date) => setForm({ ...form, endDate: date })}
            />
          </div>

          <div>
            <label>Quais dados que deseja exportar?</label>
            <Select
              placeholder="-- Selecione --"
              firstDisabled
              optionKeys={{ value: 'reportName', displayName: 'name' }}
              options={reports}
              value={form?.queryName}
              onChange={(value: string) => setForm({ ...form, queryName: value })}
            />
          </div>

          <div>
            <label>Qual o formato do arquivo que deseja exportar?</label>
            <Select
              placeholder="-- Selecione --"
              firstDisabled
              optionKeys={{ value: 'name', displayName: 'name' }}
              options={exportFormat}
              value={form?.fileFormat}
              onChange={(value: string) => setForm({ ...form, fileFormat: value })}
            />
          </div>

          <Button color="primary" text="Exportar" onClick={handleExportReport} />
        </GenerateReportsForm>
      </div>
    </Container>
  );
};

export default GenerateReports;
