import { Container, RegisterPartnerForm } from './styled';
import { Input, Button, InfoTooltip } from '../../../components';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadingContext } from '../../../context/loading';
import { AuthContext } from '../../../context/auth';
import { message } from 'antd';
import { partnerService } from '../../../service';
import { cpfCnpjMask } from '../../../utils/string-format';

const RegisterPartner = () => {
  const { setLoading } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const [isEditing, toggleIsEditing] = useState(false);
  const history = useHistory();

  const [form, setForm] = useState<any>({
    name: '',
    email: '',
    initials: '',
    socialReason: '',
    fantasyName: '',
    document: null,
    image: null,
    partnerId: '',
    imageUrl: '',
  });

  useEffect(() => {
    if (user && !isEditing) setForm({...form, partnerId: user.partner.id });
    if (user && isEditing) {
      partnerService.getInfoById(user.partner.id).then((partner) => {
        setForm({
          ...form,
          partnerId: user.partner.id,
          email: partner.email,
          initials: partner.sigla,
          socialReason: partner.name_cnpj,
          fantasyName: partner.name,
          document: partner.document ? cpfCnpjMask(partner.document) : '',
          imageUrl: partner.image_url,
        });
      });
    }
  }, [user]);

  const checkUndefined = (item) => {
    if (!item || item.trim() === '') return true;
    return false;
  };

  const verifyForm = () => {
    const { name, email, document, initials, socialReason, fantasyName } = form;

    if (checkUndefined(name) && !isEditing) {
      return ('Informe o nome do gerente.');
    }

    if (checkUndefined(email)) {
      return ('Informe o email do gerente.');
    }

    if (checkUndefined(initials)) {
      return ('Informe a sigla do parceiro.');
    }

    if (checkUndefined(socialReason)) {
      return ('Informe a razão social do parceiro.');
    }

    if (checkUndefined(fantasyName)) {
      return ('Informe o nome fantasia do parceiro.');
    }

    if (checkUndefined(document)) {
      return ('Informe o CNPJ do parceiro.');
    }

    const rawDocument = document.replace(/[^\d]/g, '');

    if (!rawDocument || rawDocument.length < 14) {
      return ('CNPJ do parceiro inválido.');
    }

    return false;
  };

  const handleSavePartner = async () => {
    try {
      setLoading(true);

      const { name, email, document, initials, socialReason, fantasyName, image, partnerId } = form;

      
      if (verifyForm()) {
        setLoading(false);
        return message.error(verifyForm());
      }

      if (!email || email === '') {
        setLoading(false);
        return message.error('Informe o email do parceiro.');
      }

      if (!document) {
        setLoading(false);
        return message.error('CNPJ do parceiro não pode estar vazio.');
      }

      const rawDocument = document.replace(/[^\d]/g, '');

      if (!rawDocument || rawDocument.length < 14) {
        setLoading(false);
        return message.error('CNPJ do parceiro inválido.');
      }

      if (!isEditing) {
        await partnerService.createPartner(
          name,
          email,
          rawDocument,
          initials,
          socialReason,
          fantasyName,
          image,
        );

        setForm({
          ...form,
          name: '',
          email: '',
          initials: '',
          socialReason: '',
          fantasyName: '',
          document: null,
        });
      } else {
        await partnerService.updatePartner(
          partnerId,
          email,
          rawDocument,
          initials,
          socialReason,
          fantasyName,
          image,
        );
      }

      setLoading(false);

      message.success(`Parceiro ${!isEditing ? 'cadastrado': 'alterado'} com sucesso!`);
    } catch (error: any) {
      setLoading(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  const onFileChange = async (event: any) => {
    try {
      setLoading(true);

      const file: File = event.target.files[0];

      const allowedExtensions = ['image/jpeg', 'image/jpg', 'image/png'];

      if (!allowedExtensions.includes(file.type)) {
        return message.error('Formato de imagem inválido. Formatos aceitos: JPG, JPEG e PNG');
      }


      message.success('Imagem enviada com sucesso!');

      setLoading(false);
      return setForm({ ...form, image: file });
    } catch (error: any) {
      setLoading(false);
      message.error('Ocorreu um erro ao enviar a imagem. Tente novamente.');
      console.error(error);
    }
  };

  const onClickEditMode = async () => {
    setLoading(true);

    if (isEditing) {
      setForm({
        ...form,
        name: '',
        email: '',
        initials: '',
        socialReason: '',
        fantasyName: '',
        document: null,
      });
    } else {
      const partner = await partnerService.getInfoById(form.partnerId);

      setForm({
        ...form,
        email: partner.email,
        initials: partner.sigla,
        socialReason: partner.name_cnpj,
        fantasyName: partner.name,
        document: partner.document ? cpfCnpjMask(partner.document) : '',
        imageUrl: partner.image_url,
      });
    }

    toggleIsEditing(!isEditing);
    setLoading(false);
  };

  return (
    <Container>
      <h2>Cadastrar parceiro</h2>

      <RegisterPartnerForm>
        <label htmlFor="isEditing-create-partner">
          <input
            id="isEditing-create-partner"
            type="checkbox"
            className="switch"
            checked={isEditing}
            onClick={onClickEditMode}
          />
          Modo editor
        </label>

        {!isEditing && <>
          <Input
          type="text"
          placeholder="Nome gerente:"
          value={form.name}
          onChange={(value: string) => setForm({ ...form, name: value })}
        />
        
        <div className="div-email-tooltip">
          <InfoTooltip text={'O e-mail é utilizado para logar na plataforma'} position={'right'} />
          <Input
            type="text"
            placeholder="E-mail gerente:"
            value={form.email}
            onChange={(value: string) => setForm({ ...form, email: value })}
          />
        </div>
        
        </>}


        <Input
          type="text"
          placeholder="Sigla:"
          value={form.initials}
          onChange={(value: string) => setForm({ ...form, initials: value })}
        />

        <Input
          type="text"
          placeholder="Razão social:"
          value={form.socialReason}
          onChange={(value: string) => setForm({ ...form, socialReason: value })}
        />

        <Input
          type="text"
          placeholder="Nome fantasia:"
          value={form.fantasyName}
          onChange={(value: string) => setForm({ ...form, fantasyName: value })}
        />

        <Input
          type="text"
          maxLength={16}
          placeholder="CNPJ:"
          value={form.document}
          onChange={(value: string) => setForm({ ...form, document: cpfCnpjMask(value) })}
        />

        <input
          id="input-file"
          type="file"
          onChange={onFileChange}
          accept=".jpg, .jpeg, .png"
        />

        {/* <Input
          type="text"
          placeholder="Blip Bot ApiKey (opcional)"
          value={form?.blipBotToken || ''}
          onChange={(value: string) => setForm({ ...form, blipBotToken: value })}
        /> */}

        <div className="buttons-register-cancel">
          <Button color="primary" text={`${!isEditing ? 'Cadastrar' : 'Alterar'} parceiro`} onClick={handleSavePartner} />
          <Button color="secondary" text="Cancelar" onClick={() => history.push('/dashboard')} />
        </div>

        <div className="partner-image-preview">
          {(isEditing && form.imageUrl) && <img src={form.imageUrl} alt='partner'/>}
        </div>
      </RegisterPartnerForm>
    </Container>
  );
};

export default RegisterPartner;
