/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { StepSetupSMSMessageContainer } from './styled';
import { TextArea } from '../../../../../../components';
import { useEffect, useState } from 'react';
import IPhoneMockup from '../IPhoneMockup';
import InfoTooltip from '../../../../../../components/InfoTooltip';

const StepSetupSMSMessage = ({ formCampaign, setFormCampaign, onCheckIsValid }: any) => {
  const [countPhones] = useState<number>(formCampaign.file.response.data.sizeFile);
  const [smsText, setSmsText] = useState<string>(formCampaign.smsText);
  const [smsLength, setSmsLength] = useState<number>(0);

  const [campaignVariableNames] = useState<string[]>(formCampaign.file.response.data.headers);

  const concatVariable = (variable: string) => {
    if (smsText?.includes(variable)) {
      return;
    }
    setSmsText(smsText?.concat(`{${variable}}`));

    const elem: any = document.getElementById('sms-text-input');

    if (elem) {
      elem.focus();
    }
  };

  useEffect(() => {
    setFormCampaign({ ...formCampaign, smsText: smsText });
    // eslint-disable-next-line
    if(smsText.length == 0){
      setSmsLength(0)
    }
  }, [smsText]);

  useEffect(() => {
    const isValidSMSText = formCampaign.smsText && formCampaign.smsText !== '' && formCampaign.smsText.includes('{Link}');

    onCheckIsValid(isValidSMSText);
  }, [formCampaign]);
  
  return (
    <StepSetupSMSMessageContainer>
      <div className="columns column-form">
        <div className="phones-count">
          Quantidade de telefones:
          <b>{countPhones}</b>
          <InfoTooltip text="Contagem total de telefones" />
        </div>

        <div>
          <label>Variáveis disponíveis:</label>
          <InfoTooltip text="As variáveis são buscadas a partir do nome das colunas do arquivo. Você pode utilizá-las na confecção do seu texto de SMS e observar o resultado no simulador ao lado" />
          <div className="variables-list">
            {campaignVariableNames.map((variable: string, idx) => {
              if (!smsText.includes(variable)) {
                return (
                  <div key={idx} className="variable-item" onClick={() => concatVariable(variable)}>
                    {variable}
                  </div>
                );
              }
            })}
          </div>
        </div>

        <div>
          <label>Texto do SMS:</label>
          <TextArea
            id="sms-text-input"
            rows={5}
            placeholder="Digite aqui o texto a ser enviado nos SMS desta campanha"
            value={smsText}
            onChange={(value: any) => setSmsText(value)}
          />
        </div>
        <div className="count-text">
          Quantidade aproximada de caracteres: <b>{smsLength}</b>
        </div>
      </div>
      <div className="columns column-phone">
        <IPhoneMockup
          message={smsText}
          variables={formCampaign.file.response.data}
          setSmsLength={setSmsLength}
        />
      </div>
    </StepSetupSMSMessageContainer>
  );
};

export default StepSetupSMSMessage;
