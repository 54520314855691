/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useContext } from 'react';
import { NumericMetrics, BarChart, LineChart, Select } from '../../components';
import { Entry, Customer, Proposal, Deal } from '../../entity';

import theme from '../../theme';
import { Container } from './styled';
import { AuthContext } from '../../context/auth';
import {
  accessService,
  customerService,
  dealService,
  hourInfo,
  clicksByLinkAndAccessService,
  proposalService,
  shipmentsService,
  partnerService,
} from '../../service';
import HorizontalBarChart from '../../components/HorizontalBarChart';
import FunnelChart from '../../components/FunnelChart';
import { ResponsiveRow } from '../../shared-styled';
import DateRange from '../../components/DateRange';
import { EPeriodDaysName, IDateRange } from '../../entity/datetime.entity';
import { message } from 'antd';
import { LoadingContext } from '../../context/loading';

export default function Dashboard ()  {
  const { user } = useContext(AuthContext);
  const { setLoading: setLoadingContext } = useContext(LoadingContext);
  const [date, setDate] = useState<IDateRange>();

  const [avgNegotiationsPrice, setAvgNegotiationsPrice] = useState<number>(0);
  const [totalNegotiationsPrice, setTotalNegotiationsPrice] = useState<number>(0);
  const [firstPortionPrice, setFirstPortionPrice] = useState<number>(0);

  const [access, setAccess] = useState<any>([]);
  const [hour, setHour] = useState<any[]>([]);
  const [clicksByLink, setClicksByLink] = useState<any>(undefined);
  const [documentConfirmations, setDocumentConfirmations] = useState<number>(0);

  const [proposals, setProposals] = useState<number>(0);
  const [deals, setDeals] = useState<any[]>([]);

  const [dailyClicksData, setDailyClicksData] = useState<any[]>([]);
  const [dailyAccessData, setDailyAccessData] = useState<any[]>([]);

  const [dailyTotalDealAmountData, setDailyTotalDealAmountData] = useState<any[]>([]);

  const [loadingAvgNegotiationsPrice, setLoadingAvgNegotiationsPrice] = useState<any>(true);
  const [loadingTotalNegotiationsPrice, setLoadingTotalNegotiationsPrice] = useState<any>(true);
  const [loadingFirstPortionPrice, setLoadingFirstPortionPrice] = useState<any>(true);
  const [loadingClicksByLink, setLoadingClicksByLink] = useState<any>(true);
  const [loadingAccess, setLoadingAccess] = useState<any>(true);
  const [loadingHour, setLoadingHour] = useState<any>(true);
  const [loadingDocumentConfirmations, setLoadingDocumentConfirmations] = useState<any>(true);
  const [loadingProposals, setLoadingProposals] = useState<any>(true);
  const [loadingDailyAccessData, setLoadingDailyAccessData] = useState<any>(true);
  const [loadingDailyTotalDealAmountData, setLoadingDailyTotalDealAmountData] = useState<any>(true);
  const [loadingShipmentAvgs, setLoadingShipmentAvgs] = useState<any>(true);
  const [loadingDeals, setLoadingDeals] = useState<any>(true);
  const [creditorList, setCreditorList] = useState<any>([]);
  const [creditor, setCreditor] = useState<any>(0);

  const [shipmentAvgs, setShipmentAvgs] = useState<any>({
    clicksByLink: 0,
    accesses: 0,
    cpc: 0,
    proposals: 0,
    negotiations: 0,
    totalNegotiationsPrice: 0,
    firstPortionPrice: 0,
    avgNegotiationsPrice: 0,
  });

  const ControllerRequisition = new AbortController();

  const fetchClicksByLink = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const res: any = await clicksByLinkAndAccessService.getByDay(partnerId, { startDate, endDate, signal, creditor });

      setClicksByLink(res.clicksByLink);
      setLoadingClicksByLink(false);
      setAccess(res.access);
      setLoadingAccess(false);
      setDocumentConfirmations(res.confirmation);
      setLoadingDocumentConfirmations(false);
      setProposals(res.proposal);
      setLoadingProposals(false);
    } catch (error: any) {
      setLoadingClicksByLink(false);
      setLoadingAccess(false);
      setLoadingDocumentConfirmations(false);
      setLoadingProposals(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchDailyAccessData = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const [res, res2]: any[] = await Promise.all([
        accessService.getAccessByDay(
          partnerId,
          {
            startDate,
            endDate,
            days: date?.period.numberOfDays,
            signal,
            creditor,
          },
        ),
        accessService.getClicksByDay(
          partnerId,
          {
            startDate,
            endDate,
            days: date?.period.numberOfDays,
            signal,
            creditor,
          },
        ),
      ]);

      setDailyAccessData(res);
      setDailyClicksData(res2);
      setLoadingDailyAccessData(false);
    } catch (error: any) {
      setLoadingDailyAccessData(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchHour = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const res: any[] = await hourInfo.getHourInfo(partnerId, { startDate, endDate, signal, creditor });

      setLoadingHour(false);
      setHour(res);
    } catch (error: any) {
      setLoadingHour(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchCreditors = async (partnerId: number, signal?: AbortSignal) => {
    try {
      const res: any[] = await partnerService.getPartnerCreditors(partnerId, signal);

      const resAll = res.concat({creditorId: 0, creditorName: 'Todos' })
      setCreditorList(resAll);
      setCreditor(0);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchDeals = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const res: Deal[] = await dealService.listDeals(partnerId, { startDate, endDate, signal, creditor });

      setDeals(res);
      setLoadingDeals(false);
    } catch (error: any) {
      setLoadingDeals(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchShipmentAvgs = async (partnerId: number, startDate?: string, endDate?: string, signal?: AbortSignal, creditor?: any) => {
    try {
      const res: any = await shipmentsService.getShipmentAvgs(partnerId, { startDate, endDate, signal, creditor });

      setShipmentAvgs(res);
      setLoadingShipmentAvgs(false);
    } catch (error: any) {
      setLoadingShipmentAvgs(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const calcDealsAvgTicket = async () => {
    const portionAmount: number[] = [];
    const amount: number[] = [];
    // eslint-disable-next-line array-callback-return
    for (let deal of deals) {
      portionAmount.push(deal.portion_amount);
      amount.push(deal.amount);
    }

    const reducer = (accumulator: number, curr: number) => accumulator + curr;

    setFirstPortionPrice(portionAmount.length > 0 ? portionAmount.reduce(reducer) : 0);

    setAvgNegotiationsPrice(
      portionAmount.length > 0 ? portionAmount.reduce(reducer) / deals.length : 0
    );
    setTotalNegotiationsPrice(amount.length > 0 ? amount.reduce(reducer) : 0);

    setLoadingFirstPortionPrice(false);
    setLoadingTotalNegotiationsPrice(false);
    setLoadingAvgNegotiationsPrice(false)
  };

  const fetchDailyNegotiationsData = async (
    partnerId: number,
    startDate?: string,
    endDate?: string,
    signal?: AbortSignal,
    creditor?: any,
  ) => {
    try {
      const res: any[] = await dealService.getNegotiationsByDay(
        partnerId,
        {
          startDate,
          endDate,
          days: date?.period.numberOfDays,
          signal,
          creditor,
        },
      );

      setDailyTotalDealAmountData(res);
      setLoadingDailyTotalDealAmountData(false);
    } catch (error: any) {
      setLoadingDailyTotalDealAmountData(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await calcDealsAvgTicket();
    })();
  }, [deals, date]);

  useEffect(() => {
    (async () => {
      if (user && user.partner.id && date) {
        setLoadingContext(false);

        await Promise.all([
          fetchClicksByLink(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor), 
          fetchDeals(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
          fetchShipmentAvgs(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
          fetchHour(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
          fetchDailyAccessData(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
          fetchDailyNegotiationsData(user.partner.id, date?.startDate, date?.endDate, ControllerRequisition.signal, creditor),
        ])
      }
    })();
    return () => ControllerRequisition.abort();
  }, [user, date, creditor]);

  useEffect(() => {
    (async() => {
      if(user && user.partner.id) {
        await fetchCreditors(user.partner.id, ControllerRequisition.signal);
      }
    })();
  }, [user]);


  const formattedDateAccessAndClicks = (accessArray, clicksArray) => {
    let accessData = accessArray.map((t) => ({...t, acessos: t.values}));
    const clicksData = clicksArray.map((t) => {
      const accessItem = accessData.find((i) => t.day === i.day);
      if(accessItem) return ({...t, ...accessItem, cliques: t.values});
      return ({...t, acessos: 0, cliques: t.values})
    });

    accessData = accessData.filter((t) => clicksData.every((i) => i.day !== t.day)).map((t) => ({...t, acessos: t.values, cliques: 0}));

    return [
      ...clicksData,
      ...accessData,
    ];
  }

  return (
    <Container>
      <h2>Painel de controle</h2>
      <div style={{ display: 'flex', alignItems: 'end' }}>
        <DateRange
          period={EPeriodDaysName.WEEK}
          onChangeDate={(dateRange: IDateRange) => setDate(dateRange)}
        />
        <div style={{width: '20%', marginBottom: '10px', marginLeft: '10px', display:'flex', flexDirection: 'column', justifyContent: 'end' }}>
          <span>Credor:</span>
          <Select
            options={creditorList}
            value={creditor}
            onChange={(e) => setCreditor(e)}
            optionKeys={{ value: 'creditorId', displayName: 'creditorName' }}
            placeholder='Credor'
            firstDisabled={true}
          />
        </div>

      </div>

      <div className="metrics">
        <ResponsiveRow>
          <NumericMetrics
            title="Cliques"
            loading={ loadingClicksByLink || loadingShipmentAvgs }
            tooltip='Quantidade cliques nos links no período'
            data={{
              period: date?.period,
              value: clicksByLink,
              compAvg: shipmentAvgs.clicksByLink,
            }}
            name="clicks"
            download={{ date }}
          ></NumericMetrics>
          <NumericMetrics
            title="Acessos"
            loading={ loadingAccess || loadingClicksByLink || loadingShipmentAvgs }
            tooltip='Quantidade total de à página de confirmação de CPF no período'
            data={{
              period: date?.period,
              value: access,
              comparatorValue: clicksByLink,
              compAvg: shipmentAvgs.accesses,
            }}
            name="access"
            download={{ date }}
          ></NumericMetrics>

          <NumericMetrics
            title="CPC"
            loading={ loadingAccess || loadingDocumentConfirmations || loadingShipmentAvgs }
            tooltip='Quantidade total de confirmações de CPF no período'
            data={{
              period: date?.period,
              value: documentConfirmations,
              comparatorValue: access,
              compAvg: shipmentAvgs.cpc,
            }}
            name="confirmation"
            download={{ date }}
          ></NumericMetrics>

          <NumericMetrics
            title="Propostas"
            loading={ loadingProposals || loadingDocumentConfirmations || loadingShipmentAvgs }
            tooltip='Quantidade total de propostas selecionadas e aceitas pelos clientes no período'
            data={{
              period: date?.period,
              value: proposals,
              comparatorValue: documentConfirmations,
              compAvg: shipmentAvgs.proposals,
            }}
            name="proposal"
            download={{ date }}
          ></NumericMetrics>

          <NumericMetrics
            title="Negociações"
            loading={ loadingDeals || loadingProposals || loadingShipmentAvgs }
            tooltip='Quantidade total de negociações realizadas pelos clientes no período'
            data={{
              period: date?.period,
              value: deals.length,
              comparatorValue: proposals,
              compAvg: shipmentAvgs.negotiations,
            }}
            name="negotiation"
            download={{ date }}
          ></NumericMetrics>
        </ResponsiveRow>
        <ResponsiveRow>
          <NumericMetrics
            title="Total negociado"
            loading={ loadingDailyAccessData || loadingTotalNegotiationsPrice || loadingShipmentAvgs }
            data={{
              period: date?.period,
              value: totalNegotiationsPrice,
              compAvg: shipmentAvgs.totalNegotiationsPrice,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            title="1ª parcela"
            loading={ loadingDailyAccessData || loadingFirstPortionPrice || loadingShipmentAvgs }
            data={{
              period: date?.period,
              value: firstPortionPrice,
              compAvg: shipmentAvgs.firstPortionPrice,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            title="Ticket médio"
            tooltip="Valor das somas da 1ª parcela dividido com a quantidade de negociações"
            loading={ loadingDailyAccessData || loadingAvgNegotiationsPrice || loadingShipmentAvgs }
            data={{
              period: date?.period,
              value: avgNegotiationsPrice,
              compAvg: shipmentAvgs.avgNegotiationsPrice,
            }}
            showCurrency
          ></NumericMetrics>
        </ResponsiveRow>

        <ResponsiveRow>
          <BarChart
            loading={ loadingDailyAccessData }
            title="Acessos e cliques únicos por dia"
            data={formattedDateAccessAndClicks(dailyAccessData, dailyClicksData)}
            colors={[theme.colors.red, theme.colors.healthy]}
            keys={['cliques', 'acessos']}
            indexBy={'day'}
            axisBottomName={'Dia da semana'}
            axisLeftName={'Quantidade'}
            label={'Acessos'}
          ></BarChart>
          <BarChart
            loading={ loadingDailyTotalDealAmountData }
            title="Valor total dos acordos fechados por dia (1ª parcela)"
            data={dailyTotalDealAmountData.map((t) => ({...t, Valor: t.values}))}
            colors={[theme.colors.blue]}
            keys={['Valor']}
            indexBy={'day'}
            axisBottomName={'Dia da semana'}
            axisLeftName={'Valor fechado'}
            prefix={'R$ '}
            formatPattern={'.2s'}
          ></BarChart>
        </ResponsiveRow>

        <ResponsiveRow>
          <FunnelChart
            loading={
              loadingAccess
              || loadingDocumentConfirmations
              || loadingProposals
              || loadingDeals
              || loadingClicksByLink
            }
            title="Funil de negociação"
            data={[
              { etapa: 'Cliques', values: clicksByLink, fill: '#4ace0a' },
              { etapa: 'Acessos', values: access, fill: '#faa000' },
              { etapa: 'CPC', values: documentConfirmations, fill: '#8c00ff' },
              { etapa: 'Propostas', values: proposals, fill: '#f70a0a' },
              { etapa: 'Negociações', values: deals.length, fill: '#00d9ff' },
            ]}
            colors={[theme.colors.tertiary]}
            keys={['values']}
            indexBy={'etapa'}
            axisBottomName={'Quantidade'}
            label={'Quantidade'}
          ></FunnelChart>
          <LineChart
            loading={ loadingHour }
            title="Gráfico por hora"
            data={hour}
            prefix={''}
            formatPattern={'.0s'}
            suffix="" 
          ></LineChart>
        </ResponsiveRow>
      </div>
    </Container>
  );
};
