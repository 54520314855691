import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Input, TextArea, Select } from '..';
import { Modal, message } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/auth';
import { partnerService } from '../../service';
import { formattedDate } from '../../utils/date-format';
import { formModalShipmentValidation } from './validation.schema';
import { showToast } from '../../providers';

interface ModalGenerateShipmentProps {
  isOpen: boolean;
  onClose: (value: boolean) => void;
  onSave: (values: any) => any;
}

const ModalGenerateShipment: React.FC<ModalGenerateShipmentProps> = ({
  isOpen,
  onClose,
  onSave,
}) => {
  const { user } = useContext(AuthContext);

  const [formShipment, setFormShipment] = useState<any>({
    partnerId: null,
    file: null,
    name: null,
    message: null,
    creditorId: null,
    csvFormat: null,
    shippingDate: formattedDate(new Date(), 'dd/MM/yyyy HH:mm'),
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [partnerCreditors, setPartnerCreditors] = useState<any[]>([]);

  const handleCancel = () => {
    onClose(false);
    const inputElem: any = document?.getElementById('input-file');

    if (inputElem) {
      inputElem.value = null;
    }
    setSelectedFile(null);
  };

  const handleGenerate = () => {
    const validation = formModalShipmentValidation.validate(formShipment);

    if (!validation.error) {
      onSave(formShipment);
      setFormShipment({
        partnerId: null,
        file: null,
        name: null,
        message: null,
        creditorId: null,
        csvFormat: null,
        shippingDate: null,
      });
      const inputElem: any = document?.getElementById('input-file');

      if (inputElem) {
        inputElem.value = null;
      }
      setSelectedFile(null);
    } else {
      showToast({ type: 'error', message: validation.error.message });
    }
  };

  const onFileChange = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setFormShipment({ ...formShipment, file: event.target.files[0] });
  };

  const fetchPartnerCreditors = async (partnerId: number) => {
    try {
      const res: any[] = await partnerService.getPartnerCreditors(partnerId);
      setPartnerCreditors(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(() => {
    if (user && user.partner.id) {
      setFormShipment({
        ...formShipment,
        partnerId: user.partner.id,
        csvFormat: user.partner.csvFormat,
        shippingDate: formattedDate(new Date(), 'yyyy-MM-dd'),
      });

      fetchPartnerCreditors(user.partner.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isOpen]);

  return (
    <Modal
      title="Gerar nova remessa"
      visible={isOpen}
      onCancel={handleCancel}
      footer={
        <div className="modal-footer-buttons">
          <Button color="secondary" text="Fechar" icon={<CloseOutlined />} onClick={handleCancel} />

          <Button
            color="primary"
            text="Gerar remessa"
            icon={<CheckOutlined />}
            onClick={handleGenerate}
          />
        </div>
      }
    >
      <div>
        <label>Nome da remessa:</label>
        <Input
          type="text"
          value={formShipment.name}
          placeholder="Ex: Remessa de fim de ano"
          onChange={(value: any) => setFormShipment({ ...formShipment, name: value })}
        />
      </div>

      <div>
        <label>Mensagem de contato:</label>
        <TextArea
          value={formShipment.message}
          placeholder="Olá {nome}, tudo, bem?"
          rows={4}
          onChange={(value: any) => setFormShipment({ ...formShipment, message: value })}
        />
      </div>

      <div>
        <label>Selecione a carteira:</label>
        <Select
          placeholder="-- Selecione a carteira --"
          firstDisabled
          optionKeys={{ value: 'creditorId', displayName: 'creditorName' }}
          options={partnerCreditors as any[]}
          value={formShipment.creditorId}
          onChange={(value: any) => setFormShipment({ ...formShipment, creditorId: value })}
        />
      </div>

      <div>
        <label>Upload do arquivo:</label>
        <input id="input-file" type="file" onChange={onFileChange} />
      </div>
    </Modal>
  );
};

export default ModalGenerateShipment;
