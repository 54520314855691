import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;

  .card {
    display: flex;
    justify-content: center;
    width: 400px;
    align-items: center;
    border-radius: ${theme.border.radius};
    margin-bottom: 10px;
    background: ${theme.colors.white};
    box-shadow: ${theme.boxShadow};
    color: ${theme.colors.primary};
    padding: 40px 30px;
  }

  @media screen and (max-width: 767px) {
    .card {
      width: 100%;
    }
  }

  .card-row{
    display: flex;
    flex-direction: column;
    flex-flow: row;
    flex-wrap: wrap;
    flex-grow: 4;
    gap: ${theme.flexGrid.gap};
    position: relative;
    min-height: 100px;
    margin-bottom: 20px;

    @media screen and (min-width: 768px) {
      /* max-width: 76vw; */
    }
  }
`;

export const ViewLatestNews = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  
  h3 {
    font-weight: bold;
  }

  span {
    color: ${theme.colors.grey};
  }

  p {
    margin-top: 20px;
  }
  
  .cardText {
    color: black;
  }
`;
