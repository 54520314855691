import React, { useState } from 'react';
import { LoadingContext } from './loading.context';

interface Props {
  children: React.ReactNode;
}

export const LoadingProvider: React.FC<Props> = ({ children }) => {
  const [loading, setLoading] = useState<boolean>(true);

  return (
    <LoadingContext.Provider value={{ loading, setLoading }}>{children}</LoadingContext.Provider>
  );
};
