import fileDownload from 'js-file-download';
import * as iquiteiSvc from './iquiteiApi.service';

export async function downloadCampaign(campaignId: number, partnerId: number, name: string, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${campaignId}/download/generated`, { signal }
    );

    const mime = 'text/csv; charset=utf-8';
    fileDownload('\uFEFF' + res.data.toString(), `${name}.csv`, mime); //formatação para aparecer acentos
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function downloadCampaignPreventive(campaignId: number, partnerId: number, name: string, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${campaignId}/download/preventive/generated`, { signal }
    );

    const mime = 'text/csv; charset=utf-8';
    fileDownload('\uFEFF' + res.data.toString(), `${name}.csv`, mime); //formatação para aparecer acentos
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function generateCampaign(campaignData: any) {
  try {
    return await iquiteiSvc.postRequest(
      `/partners/${campaignData.partnerId}/v2/shipment/generateCampaign`,
      campaignData
    );
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500
        ? data.error
        : 'Ocorreu um erro ao gerar a campanha. Entre em contato com o suporte';
    throw err;
  }
}

export async function generateCampaignPreventive(campaignData: any) {
  try {
    return await iquiteiSvc.postRequest(
      `/partners/${campaignData.partnerId}/v2/shipment/generateCampaignPreventive`,
      campaignData
    );
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500
        ? data.error
        : 'Ocorreu um erro ao gerar a campanha. Entre em contato com o suporte';
    throw err;
  }
}

export async function downloadOriginalFileUploaded (campaignId: number, partnerId: number, fileName: string, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${campaignId}/download/uploaded `, { signal }
    );

    const mime = 'text/csv; charset=utf-8';
    fileDownload('\uFEFF' + res.data.toString(), `${fileName}.csv`, mime);
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function downloadOriginalFileUploadedPreventive (campaignId: number, partnerId: number, fileName: string, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${campaignId}/download/preventive/uploaded `, { signal }
    );

    const mime = 'text/csv; charset=utf-8';
    fileDownload('\uFEFF' + res.data.toString(), `${fileName}.csv`, mime);
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function getPreventiveCampaignsInfo(partnerId: number, signal?: AbortSignal) {
  try {
    const { data } = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/info/preventive`,
      { signal }
    );
    return data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500
        ? data.error
        : 'Ocorreu um erro ao carregar as campanha. Entre em contato com o suporte';
    throw err;
  }
}
export async function getPreventiveCampaignsById(partnerId: number,shipmentId: number, signal?: AbortSignal) {
  try {
    const { data } = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/info/preventive/${shipmentId}`,
      { signal }
    );
    return data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err =
      data.statusCode !== 500
        ? data.error
        : 'Ocorreu um erro ao carregar os dados da campanha. Entre em contato com o suporte';
    throw err;
  }
}

export async function getNegotiateCampaignById(partnerId: number, shipmentId: number, signal?: AbortSignal) {
  try {
    const { data } = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/info/negotiate/${shipmentId}`,
      { signal }
    ); 

    return data.data[0];
  } catch (error: any) {
      const { data } = error.response;
      const err =
        data.statusCode !== 500
          ? data.error
          : 'Ocorreu um erro ao carregar os dados da campanha. Entre em contato com o suporte';
      throw err;
  }
}
