/* eslint-disable react-hooks/exhaustive-deps */

import { StepUploadFileContainer, MessageError } from './styled';
import { Input, Select } from '../../../../../../components';
import { message, Upload, Table} from 'antd';
import Dragger from 'antd/lib/upload/Dragger';
import { InboxOutlined } from '@ant-design/icons';
import { AuthContext } from '../../../../../../context/auth';
import { useContext, useEffect, useState } from 'react';
import { partnerService } from '../../../../../../service';
import InfoTooltip from '../../../../../../components/InfoTooltip';
import { LoadingContext } from '../../../../../../context/loading';
import cpfConvert from '../../../../../../utils/cpf-complete';
import { params } from '../../../../../../config';

const StepUploadFile = ({ formCampaign, setFormCampaign, onCheckIsValid }: any) => {
  const { user } = useContext(AuthContext);
  const [headersTable, setHeadersTable] = useState<any[]>([])
  const [dataTable, setDataTable] = useState<any[]>([])
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const { setLoading } = useContext(LoadingContext);

  const fetchPartnerCreditors = async (partnerId: number) => {
    try {
      const res: any[] = await partnerService.getPartnerCreditors(partnerId);
      setCreditors(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const dataPreview = async (infoFile: any)=>{
    let arr : any[] = []
    let headersTable : any[] = []
    infoFile.firstLine.map((item, index)=>{
      if (infoFile.headers[index] === 'CPF_CNPJ') {
        arr[infoFile.headers[index]] = cpfConvert(infoFile.firstLine[index]);
      } else {
        arr[infoFile.headers[index]] = infoFile.firstLine[index];
      }
      headersTable.push({
        title: infoFile.headers[index],
        dataIndex: infoFile.headers[index],
        key: infoFile.headers[index],
      })
      return null;
    })
    setHeadersTable(headersTable)
    setDataTable([{... arr}])

    setIsOpen(true)
  }

  const dataPreviewWithErrors = async (headers, table, errors)=>{
    let headersTable = headers.map((header) => ({
      title: () => {
        if (errors['0'] && errors['0'][header]) return <MessageError>{header} <InfoTooltip text={errors['0'][header]} position="top"/></MessageError>
        return header;
      },
      dataIndex: header,
      key: header,
    }));
    if(errors['0']) {
      message.error(`As colunas: ${Object.keys(errors['0'])}, são obrigatórias e precisam ser escritas desta forma`);
    }
    const dataTable = table.map((item) => {
      let output = item;
      Object.entries(item).forEach(([key, value]) => {
        if(errors[item.linha] && errors[item.linha][key]) {
          const column: any = value;
          output[key] = <MessageError>{column} <InfoTooltip text={errors[item.linha][key]} position="top"/></MessageError>;
        }
      });
      return output;
    });
    setHeadersTable(headersTable)
    setDataTable(dataTable)

    setIsOpen(true)
  }

  useEffect(() => {
    if (user && user.partner.id) {
      fetchPartnerCreditors(user.partner.id);
    }
  }, [user]);

  const [creditors, setCreditors] = useState<any[]>([]);

  let props: any = null;

  if (user && user.partner) {
    props = {
      name: 'file',
      multiple: false,
      maxCount: 1,
      action: `${process.env.REACT_APP_API_URL}/partners/${user.partner.id}/v2/shipment/getHeaders`,
      onChange(info: any) {
        setLoading(true);
        const { status } = info.file;
        if (status !== 'uploading') {
          //console.log(info.file, info.fileList);
        }
        if (status === 'done') {
          setLoading(false);
          message.success(`${info.file.name} teve sucesso ao realizar upload.`);
          setFormCampaign({ ...formCampaign, file: info.file });
          dataPreview(info.file.response.data)
        } else if (status === 'error') {
          setLoading(false);
          if (info.file.response && info.file.response.error && typeof info.file.response.error === 'string') {
            const { headers, table, infoErrors } = info.file.response;
            dataPreviewWithErrors(headers, table, infoErrors);
            message.error('O arquivo possui alguma linha com erro, verifique na tabela abaixo');
          }
          else message.error(`${info.file.name} teve falha ao realizar upload.`);
        } else if (status === 'removed') {
          setLoading(false);
        }
      },
      headers: {
        Authorization: localStorage.getItem(params.PARAMS_AUTH_TOKEN),
      },
      onDrop(e: any) {
        //console.log("Dropped files", e.dataTransfer.files);
      },
      beforeUpload(file: any) {
        // console.log(file.type);
        // let types = ['text/plain', 'text/csv', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];
        // if (!types.includes(file.type)) {
        //   message.error(`${file.name} não possui formato permitido.`);
        // }
        return file.type === 'text/plain' ? true : Upload.LIST_IGNORE;
      },
    };
  }

  useEffect(() => {
    const isValidName = formCampaign.name && formCampaign.name !== '';
    const isValidCreditor = formCampaign.creditor && formCampaign.creditor !== '';
    const isValidNegotiationName = formCampaign?.creditor?.creditorId !== 6 || (formCampaign.negotiationName && formCampaign.negotiationName !== '');

    const isValidFile = formCampaign.file !== null && formCampaign.file !== undefined;

    // eslint-disable-next-line prefer-destructuring
    const headers = formCampaign?.file?.response?.data?.headers;
    const creditorName = formCampaign?.creditor?.creditorName;

    const isValidHeaderFields =
      headers?.includes('CPF_CNPJ') && headers?.includes('Telefone') && headers?.includes('Nome');

    const isValidHeaders =
      ((creditorName !== 'Return' && formCampaign?.creditor?.creditorId !== 6) ||
        ((creditorName === 'Return' && headers?.includes('IDBinding')) || (formCampaign?.creditor?.creditorId === 6 && headers?.includes('IDBinding')))) &&
      isValidHeaderFields;

    onCheckIsValid(isValidName && isValidCreditor && isValidFile && isValidHeaders && isValidNegotiationName);
  }, [formCampaign]);



  const dataSource = [
    {
      Telefone: '67992326418',
      Nome: 'ESTEVAO',
      CPF_CNPJ: '05198591114',
      VL_Debito: '88.49',
      Min_Parcela: '1',
      Max_Parcela: '1',
      Min_Desconto: '0.57',
      Max_Desconto: '0.55',
      Data_Vencimento: '30/03/2022',
      Origem: 'PAN'
  }
  ];
  
  const columns = [
    {
        'title': 'Telefone',
        'dataIndex': 'Telefone',
        'key': 'Telefone'
    },
    {
        'title': 'Nome',
        'dataIndex': 'Nome',
        'key': 'Nome'
    },
    {
        'title': 'CPF_CNPJ',
        'dataIndex': 'CPF_CNPJ',
        'key': 'CPF_CNPJ'
    },
    {
        'title': 'VL_Debito',
        'dataIndex': 'VL_Debito',
        'key': 'VL_Debito'
    },
    {
        'title': 'Min_Parcela',
        'dataIndex': 'Min_Parcela',
        'key': 'Min_Parcela'
    },
    {
        'title': 'Max_Parcela',
        'dataIndex': 'Max_Parcela',
        'key': 'Max_Parcela'
    },
    {
        'title': 'Min_Desconto',
        'dataIndex': 'Min_Desconto',
        'key': 'Min_Desconto'
    },
    {
        'title': 'Max_Desconto',
        'dataIndex': 'Max_Desconto',
        'key': 'Max_Desconto'
    },
    {
        'title': 'Data_Vencimento',
        'dataIndex': 'Data_Vencimento',
        'key': 'Data_Vencimento'
    },
    {
        'title': 'Origem',
        'dataIndex': 'Origem',
        'key': 'Origem'
    }
];
  
  

  return (
    <StepUploadFileContainer>
      <div>
        <label>Nome da campanha:</label>
        <InfoTooltip
          text="Aqui você deve definir o nome da campanha que deseja criar, esse nome, será utilizado para encontrá-la nos relatórios."
          position="rightBottom"
        />
        <Input
          type="text"
          placeholder="Digite o nome da campanha"
          value={formCampaign.name}
          onChange={(value: any) => setFormCampaign({ ...formCampaign, name: value })}
        />
      </div>
      <div>
        <label>Selecione o credor:</label>
        <Select
          placeholder="-- Selecione --"
          firstDisabled
          optionKeys={{ value: 'creditorId', displayName: 'creditorName' }}
          options={creditors}
          value={formCampaign?.creditor?.creditorId ? formCampaign?.creditor?.creditorId : ''}
          onChange={(value: any) => {
            // eslint-disable-next-line array-callback-return
            const selectedType = creditors.find((tCred: any) => {
              if (value === tCred.creditorId) {
                return tCred;
              }
            });

            setFormCampaign({ ...formCampaign, creditor: selectedType });
          }}
        />
      </div>
      {Number(formCampaign?.creditor?.creditorId) === 6 &&
      <div>
        <label>Nome da negociação:</label>
        <InfoTooltip
          text="Aqui você deve definir o nome da negociação que deseja utilizar, esse nome, será pesquisado nas negociações do cliente."
          position="rightBottom"
        />
        <Input
          type="text"
          placeholder="Digite o nome da negociação"
          value={formCampaign.negotiationName}
          disabled={true}
          onChange={(value: any) => setFormCampaign({ ...formCampaign, negotiationName: value })}
        />
      </div>
      }
      {props && (
        <Dragger {...props}>
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Clique aqui ou arraste e solte aqui para realizar upload.
          </p>
          <p className="ant-upload-hint">
            **Apenas um arquivo por vez. Formato permitido: .csv
          </p>
        </Dragger>
      )}
      {
        isOpen && <Table dataSource={dataTable} columns={headersTable} />
      }
      
      <div
        onClick={(event) => {
                window.location.href = 'https://firebasestorage.googleapis.com/v0/b/vhg-dash-prod.appspot.com/o/utils%2Fmodelo.csv?alt=media&token=8a38588f-165f-4984-b975-2ca1d3ccc7fe'
            }}
            style={{cursor: 'pointer'}}
        >
            Modelo csv   
        </div>
      <div
        onClick={(event) => {
                window.location.href = 'https://firebasestorage.googleapis.com/v0/b/vhg-dash-prod.appspot.com/o/utils%2Fmodelo.txt?alt=media&token=f9be1d47-a5b9-4ded-803d-70d54231983b'
            }}
            style={{cursor: 'pointer'}}
        >
            Modelo txt   
        </div>

    </StepUploadFileContainer>
  );
};

export default StepUploadFile;
