import React from 'react';
import { Tooltip as AntdTooltip } from 'antd';
//import theme from "../../theme";
import { InfoCircleOutlined } from '@ant-design/icons';

interface SelectProps {
  text: string;
  position?: any;
}

const style = {
  margin: '4px',
};

/*
positions:
    - topLeft
    - top
    - topRight
    - leftTop
    - left
    - leftBottom
    - rightTop
    - right
    - rightBottom
    - bottomLeft
    - bottom
    - bottomRight
*/

const InfoTooltip: React.FC<SelectProps> = ({ text, position }) => {
  return (
    <AntdTooltip title={text} placement={position}>
      <InfoCircleOutlined style={style} />
    </AntdTooltip>
  );
};

export default InfoTooltip;
