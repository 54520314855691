export default function Table({ data = [[''],['']], titles = [''] }) {
  const renderTitles = () => {
    return <tr>{titles.map((title: any) => <td key={title}>{title}</td>)}</tr>
  };

  const renderData = () => {
    return data.map((line, index) => <tr key={`line${index}`}>{line.map((column, i) => <td key={column}>{column}</td>)}</tr>);
  };

  return (
    <table>
      <thead>
        {titles.length > 0 && renderTitles()}
      </thead>
      <tbody>
        {data.length > 0 && renderData()}
      </tbody>
    </table>
  )
}
