import { Container } from './styled';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../../../context/auth';
import { LoadingContext } from '../../../../context/loading';
import { useHistory } from 'react-router';
import { IDateRange } from '../../../../entity/datetime.entity';
import theme from '../../../../theme';
import { ResponsiveRow } from '../../../../shared-styled';
import { NumericMetrics, Input, BarChart, PieChart, FunnelChart } from '../../../../components';
import { Customer, Deal, Entry, Partner, Proposal } from '../../../../entity';
import { currencyFormat } from '../../../../utils/string-format';
import {
  accessService,
  dealService,
  shipmentsService,
  monitoredService,
  confirmationService,
  creditorErrorService,
  hourInfo,
  campaignService,
} from '../../../../service';
import TextArea from 'antd/lib/input/TextArea';
import { ExpandAltOutlined, DownloadOutlined } from '@ant-design/icons';
import { routeParams } from '../../../../config/routes.config';
import InfoTooltip from '../../../../components/InfoTooltip';
import { message } from 'antd';
import { Loading } from '../../../../providers';


const ShipmentDetails = () => {
  const history: any = useHistory();


  const { user } = useContext(AuthContext);
  const { setLoading } = useContext(LoadingContext);

  const [date /*, setDate*/] = useState<IDateRange>();
  const [shipment, setShipment] = useState<any>({
    shipping_date: new Date(),
  });

  const [avgNegotiationsPrice, setAvgNegotiationsPrice] = useState<number>(0);
  const [totalNegotiationsPrice, setTotalNegotiationsPrice] = useState<number>(0);
  const [firstPortionPrice, setFirstPortionPrice] = useState<number>(0);

  const [access, setAccess] = useState<number>(0);
  const [hour, setHour] = useState<Entry[]>([]);
  const [monitored, setMonitored] = useState<any>(0);
  const [documentConfirmations, setDocumentConfirmations] = useState<Customer[]>([]);

  const { shipmentId } = history.location.state;

  const [preventiveInfo, setPreventiveInfo] = useState<any>([]);

  const [PreventiveCampaignsAccessByDay, setPreventiveCampaignsAccessByDay] = useState<any>([]);

  const [deals, setDeals] = useState<any[]>([]);

  const [dailyAccessData, setDailyAccessData] = useState<any[]>([]);

  const [creditorError, setCreditorError] = useState<any[]>([]);

  const [dailyTotalDealAmountData, setDailyTotalDealAmountData] = useState<any[]>([]);

  const [pieInfoData, setPitInfoData] = useState<any[]>([]);

  const [shipmentAvgs, setShipmentAvgs] = useState<any>({
    monitored: 0,
    accesses: 0,
    cpc: 0,
    preventiveInfo: 0,
    negotiations: 0,
    totalNegotiationsPrice: 0,
    firstPortionPrice: 0,
    avgNegotiationsPrice: 0,
  });

  const [loadingPreventInfo, setPreventInfo] = useState(true);
  const [loadingCreditorError, setLoadingCreditorError] = useState(true);
  const [loadingFirstPortionPrice, setLoadingFirstPortionPrice] = useState(true);
  const [loadingDailyAccessData, setLoadingDailyAccessData] = useState(true);

  const ControllerRequisition = new AbortController();

  const fetchMonitored = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: any = await monitoredService.listByShipment(partnerId, shipmentId, ControllerRequisition.signal);

      setMonitored(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchAccess = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: number = await accessService.listByShipment(partnerId, shipmentId, ControllerRequisition.signal);
      setAccess(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };
  

  const fetchDailyAccessData = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: any[] = await accessService.getShipmentAccessByDay(partnerId, shipmentId, signal);

      setDailyAccessData(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchHour = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: any[] = await hourInfo.listByShipment(partnerId, shipmentId, signal);
      setHour(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchConfirmedDocuments = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: Customer[] = await confirmationService.listByShipment(partnerId, shipmentId, signal);
      setDocumentConfirmations(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchCampainInfoPreventive = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res = await campaignService.getPreventiveCampaignsById(partnerId, shipmentId, signal);

      setPreventiveInfo(res);
      setPreventInfo(false);
    } catch (error: any) {
      setPreventInfo(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
    
  };
  const fetchPreventiveCampaignsAccessByDay = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res = await accessService.getCampaignsAccessByDay(partnerId, shipmentId, signal);
      setPreventiveCampaignsAccessByDay(res);
      setLoadingDailyAccessData(false);
    } catch (error: any) {
      setLoadingDailyAccessData(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
    
  };
 
 
  
 //________________________________________________________
  const fetchDeals = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: Deal[] = await dealService.listByShipment(partnerId, shipmentId, signal);

      setDeals(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchShipmentAvgs = async (partnerId: number, signal?: AbortSignal) => {
    try {
      const res: any = await shipmentsService.getAvgsOfShipments(partnerId, signal);

      setShipmentAvgs(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const fetchCreditorErrors = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: any = await creditorErrorService.listCreditorErrorByShipment(partnerId, shipmentId, signal);

      setCreditorError(res);
      setLoadingCreditorError(false);
    } catch (error: any) {
      setLoadingCreditorError(false);
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const calcDealsAvgTicket = async () => {
    const portionAmount: number[] = [];
    const amount: number[] = [];
    // eslint-disable-next-line array-callback-return
    for (let deal of deals) {
      portionAmount.push(deal.portion_amount);
      amount.push(deal.amount);
    }

    const reducer = (accumulator: number, curr: number) => accumulator + curr;

    setFirstPortionPrice(portionAmount.length > 0 ? portionAmount.reduce(reducer) : 0);

    setAvgNegotiationsPrice(
      portionAmount.length > 0 ? portionAmount.reduce(reducer) / deals.length : 0
    );

    setTotalNegotiationsPrice(amount.length > 0 ? amount.reduce(reducer) : 0);

    setLoadingFirstPortionPrice(false);
  };

  const fetchDailyNegotiationsData = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: any[] = await dealService.getShipmentNegotiationsByDay(partnerId, shipmentId, signal);

      setDailyTotalDealAmountData(res);
  } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  const downloadOriginalFileUploaded = async (campaignId: number, partnerId: number, fileName: string)  => {
    try {
      await campaignService.downloadOriginalFileUploaded(campaignId, partnerId, fileName);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(() => {
    (async () => {
      await calcDealsAvgTicket();
    })();
  }, [deals, date]);

  useEffect(() => {
    if(preventiveInfo) {
      const info = [
        {
          name: 'Clientes',
          value: preventiveInfo.clients
        },
        {
          name: 'Acessos',
          value: preventiveInfo.access
        },
        {
          name: 'Confirmações',
          value: preventiveInfo.confirmacoes
        },
        {
          name: 'Boletos baixados',
          value: preventiveInfo.boletosBaixados
        },
      ];
      setPitInfoData(info);
    }
  }, [preventiveInfo])

  useEffect(() => {
    (async () => {
      if (user && user.partner.id) {
        setLoading(false);

        await Promise.all([
          // fetchMonitored(user.partner.id, shipmentId, ControllerRequisition.signal),
          // fetchAccess(user.partner.id, shipmentId, ControllerRequisition.signal),
          //  fetchConfirmedDocuments(user.partner.id, shipmentId, ControllerRequisition.signal),
          fetchCampainInfoPreventive(user.partner.id, shipmentId, ControllerRequisition.signal),
          fetchDeals(user.partner.id, shipmentId, ControllerRequisition.signal),
          // fetchShipmentAvgs(user.partner.id, ControllerRequisition.signal),
          // fetchHour(user.partner.id, shipmentId, ControllerRequisition.signal),
          // fetchDailyAccessData(user.partner.id, shipmentId, ControllerRequisition.signal),
          // fetchDailyNegotiationsData(user.partner.id, shipmentId, ControllerRequisition.signal),
          fetchCreditorErrors(user.partner.id, shipmentId, ControllerRequisition.signal),
          fetchPreventiveCampaignsAccessByDay(user.partner.id, shipmentId, ControllerRequisition.signal),

        ]);
      }
    })();

    return () => ControllerRequisition.abort();
  }, [user, date]);

  const fetchShipment = async (partnerId: number, shipmentId: number, signal?: AbortSignal) => {
    try {
      const res: any = await shipmentsService.findById(partnerId, shipmentId, signal);

      setShipment(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };
    
  const downloadCampaing = async (campaignId: number, partnerId: number, name: string) => {
    try { 
      await campaignService.downloadCampaignPreventive(campaignId, partnerId, name, ControllerRequisition.signal);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };



  function returnExpectation() {
    if ((((firstPortionPrice / 2) * 0.15) * 100) / (Number(preventiveInfo?.custoEnvio)) >= 110) { 
      return 'Alto';
    } else if ((((firstPortionPrice / 2) * 0.15) * 100) / (Number(preventiveInfo?.custoEnvio))  >= 0) {
      return 'Médio';
    } else {
      return 'Baixo';
    }
  };

  function returnProblems() {
    if (creditorError.length / access >= 0.5) {
      return 'Alto: Acima de 5% dos acessos';
    } else if (creditorError.length / access >= 0.1) {
      return 'Médio: de 1% até 5% dos acessos';
    } else {
      return 'Baixo: abaixo de 1%';
    }
  };

  useEffect(() => {
    (async () => {
      if (history) {
        const { partnerId, shipmentId } = history.location.state;

        await fetchShipment(partnerId, shipmentId, ControllerRequisition.signal);
      }
    })();

  }, [history]);


  const [dataSource, setDataSource] = useState<any[]>([]);

  const fetchShipments = async (partnerId: number) => {
    try {
      const res: any[] = await campaignService.getPreventiveCampaignsInfo(partnerId, ControllerRequisition.signal);
      setDataSource(res);
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  };

  useEffect(() => {
    if (user && user.partner.id) {
      fetchShipments(user.partner.id);
    }

  }, [user]);

  return (
    <Container>
      <h2>Detalhes da campanha de preventivo
      <DownloadOutlined
              onClick={() =>
                downloadCampaing(Number(shipmentId), Number(user.partner.id), shipment?.fileName)
              }
              style={{ marginLeft: '8px', color: 'green'}}
            />
      </h2>
      <div className="shipment-details">
        <div
          className="shipment-detail-field"
          onClick={() => downloadCampaing(shipment?.id, shipment?.partner, shipment?.name)}
        >
          <label>Nome da campanha:</label>
          <Input
            loading={ loadingPreventInfo }
            type="text"
            value={preventiveInfo?.name}
            placeholder="Ex: Remessa de fim de ano"
            disabled
          />
          
        </div>

        <div className="shipment-detail-field">
          <label>Data do envio:</label>
          <Input
            loading={ loadingPreventInfo }
            type="text"
            value={(new Date(preventiveInfo?.shippingDate).toLocaleString().replace(' ', ' as '))}
            placeholder="Ex: Remessa de fim de ano"
            disabled
          />
        </div>

        <div className="shipment-detail-field">
          <label>Credor:</label>
          <Input
            loading={ loadingPreventInfo }
            type="text"
            value={preventiveInfo?.credor}
            placeholder="Nome do Credor"
            disabled
          />
        </div>
      </div>

      <div className="shipment-details">
        <div className="shipment-detail-field">
          <label>Problemas com integração:</label>
          <Input
            loading={ loadingCreditorError }
            type="text"
            value={returnProblems()}
            placeholder="Ex: Remessa de fim de ano"
            suffix={
              <ExpandAltOutlined
                onClick={() =>
                  history.push(routeParams.campaigns.ROUTE_CAMPAIGN_ERRORS, {
                    creditorError: creditorError,
                    campaign: history.location.state,
                  })
                }
              />
            }
            disabled
          />
        </div>

        <div className="shipment-detail-field">
          <label>Custo do envio:</label>
          <Input
            loading={ loadingPreventInfo }
            type="text"
            value={`${currencyFormat(Number(preventiveInfo?.custoEnvio))}`}
            placeholder="Ex: Remessa de fim de ano"
            disabled
          />
        </div>

         <div className="shipment-detail-field">
          <label>Expectativa de retorno:</label>
          <Input
            loading={ loadingPreventInfo || loadingFirstPortionPrice }
            type="text"
            value={returnExpectation()}
            placeholder="Ex: Remessa de fim de ano"
           disabled
         />
       </div>
     </div>

      <div className="shipment-details">
        <div className="shipment-detail-field">
          {loadingPreventInfo && <Loading relative/>}
          <label>SMS:</label>
          <TextArea rows={4} value={preventiveInfo?.message} placeholder="Texto do SMS" disabled />
        </div>
      </div>

      <div className="metrics">
        <h2>Resultado:</h2>
        <ResponsiveRow>
          <NumericMetrics
            loading={ loadingPreventInfo }
            title="Clientes"
            tooltip='Quantidade total de clientes nesta campanha'
            data={{
              // period: date?.period,
              value: preventiveInfo?.clients,
              // compAvg: shipmentAvgs.monitored,
            }}
            download={{ id: shipmentId, fileName: 'Clients' }}
          ></NumericMetrics>

          <NumericMetrics
            loading={ loadingPreventInfo }
            title="Acessos"
            tooltip='Quantidade total de acessos obtidos nesta campanha únicos por cliente'
            data={{
              period: date?.period,
              value: preventiveInfo?.access,
              comparatorValue: preventiveInfo?.clients,
            }}
            download={{ id: shipmentId, fileName: 'PreventiveAccess' }}
          ></NumericMetrics>

          <NumericMetrics
            loading={ loadingPreventInfo }
            title="CPC"
            tooltip='confirmações de CPF, com % baseada no número de acessos únicos por cliente'
            data={{
              // period: date?.period,
              value: preventiveInfo?.confirmacoes,
              comparatorValue: preventiveInfo?.access,
              // compAvg: preventiveInfo.confirmacoes,
            }}
            download={{ id: shipmentId, fileName: 'PreventiveCPC' }}
          ></NumericMetrics>

          <NumericMetrics
            loading={ loadingPreventInfo }
            title="Boletos Baixados"
            tooltip='Quantidade de boletos baixados únicos por cliente'
            data={{
              // period: date?.period,
              value: preventiveInfo?.boletosBaixados,
              comparatorValue:  preventiveInfo?.confirmacoes,
              // compAvg: preventiveInfo.confirmacoes,
            }}
            download={{ id: shipmentId, fileName: 'Tickets' }}
          ></NumericMetrics>

          {/* <NumericMetrics
            loading={ loading.deals }
            title="Negociações"
            tooltip='Quantidade total de negociações realizadas nesta campanha'
            data={{
              period: date?.period,
              value: deals.length,
              comparatorValue: preventiveInfo.length,
              compAvg: shipmentAvgs.negotiations,
            }}
            identity={shipmentId}
          ></NumericMetrics> */}
        </ResponsiveRow>

        <ResponsiveRow>
         {/* ______valores a serem recebidos da api do cliente______ */}
         {/* <NumericMetrics
            loading={ loading.totalNegotiationsPrice }
            title="Total dos boletos baixados"
            tooltip='Total dos boletos baixados'
            data={{
              period: date?.period,
              value: totalNegotiationsPrice,
              compAvg: shipmentAvgs.totalNegotiationsPrice,
            }}
            showCurrency
          ></NumericMetrics>

          <NumericMetrics
            loading={ loadingFirstPortionPrice }
            title="Total dos boletos pagos"
            tooltip='Total em Reais para a 1ª parcela nesta campanha'
            data={{
              period: date?.period,
              value: firstPortionPrice,
              compAvg: shipmentAvgs.firstPortionPrice,
            }}
            showCurrency
          ></NumericMetrics>
                    <NumericMetrics
            loading={ loading.avgNegotiationsPrice }
            title="Ticket médio"
            tooltip='Valor médio alcançado nesta campanha'
            data={{
              period: date?.period,
              value: avgNegotiationsPrice,
              compAvg: shipmentAvgs.avgNegotiationsPrice,
            }}
            showCurrency
          ></NumericMetrics> */}
          {/* ______valores a serem recebidos da api do cliente______ */}
          
        </ResponsiveRow>

        <ResponsiveRow>
          <BarChart
            loading={ loadingDailyAccessData }
            title="Acessos e cliques únicos por dia"
            data={PreventiveCampaignsAccessByDay}
            colors={[theme.colors.red]}
            keys={['Acessos']}
            indexBy={'day'}
            axisBottomName={'Dia da semana'}
            axisLeftName={'Quantidade'}
            label={'Acessos'}
          ></BarChart>

          {/* <BarChart
            loading={ loading.dailyTotalDealAmountData }
            title="Valor total dos boletos baixados por dia"
            data={dailyTotalDealAmountData}
            colors={[theme.colors.blue]}
            keys={['values']}
            indexBy={'day'}
            axisBottomName={'Dia da semana'}
            axisLeftName={'Valor fechado'}
            prefix={'R$ '}
            formatPattern={'.2s'}
            label={'Valor'}
          ></BarChart> */}
        </ResponsiveRow>
        <ResponsiveRow>
          <FunnelChart
            loading={ loadingPreventInfo }
            title="Funil de negociação"
            data={[
              { etapa: 'Acessos', values: preventiveInfo?.access, fill: '#00C49F' },
              { etapa: 'Confirmações', values: preventiveInfo?.confirmacoes, fill: '#FFBB28' },
              { etapa: 'Boletos baixados', values: preventiveInfo?.boletosBaixados, fill: '#FF8042' },
            ]}
            colors={[theme.colors.tertiary]}
            keys={['values']}
            indexBy={'etapa'}
            axisBottomName={'Quantidade'}
            label={'Quantidade'}
          ></FunnelChart>
          <PieChart
            loading={ loadingPreventInfo }
            data={ pieInfoData }
            />
          </ResponsiveRow>
      </div>
    </Container>
  );
};

export default ShipmentDetails;
