import styled from 'styled-components';
import theme from '../../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  h1.title {
    font-size: 21px;
    color: ${theme.colors.dark};
    text-align: start;
  }
`;

export const ContainerChild = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 450px;
  height: auto;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.lightGrey};
  border-radius: 5px;

  label {
    font-weight: 700;
    font-size: 12px;
  }

  .selectInput {
    width: 100%;
    border-radius: 2px;
  }

  .inputGroup {
    width: 100%;
    border-radius: 2px;
  }
`;
