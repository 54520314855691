import * as iquiteiSvc from './iquiteiApi.service';

export async function getNegotiationsText(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/negotiationsText`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function getCustomPageText(partnerId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(`/partners/${partnerId}/v2/negotiationsText/customPage`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function insertCustomPageText(partnerId: number, body: any, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.postRequest(`/partners/${partnerId}/v2/negotiationsText/customPage`, { ...body, signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function updateCustomPageText(partnerId: number, id: number | string,  body: any, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/v2/negotiationsText/customPage/${id}`, { ...body, signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function deleteCustomPageText(partnerId: number, id: number | string, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.deleteRequest(`/partners/${partnerId}/v2/negotiationsText/customPage/${id}`, { signal });

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function insertNegotiationsText(partnerId: number, insertText:any) {
  try {
    const res = await iquiteiSvc.postRequest(`/partners/${partnerId}/v2/negotiationsText`, insertText);

    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function updateNegotiationsText(partnerId: number, id:number|string, body:any) {
  try {
    const res = await iquiteiSvc.putRequest(`/partners/${partnerId}/v2/negotiationsText/${id}`, body);
    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}

export async function deleteNegotiationsText(partnerId: number, id:number|string) {
  try {
    const res = await iquiteiSvc.deleteRequest(`/partners/${partnerId}/v2/negotiationsText/${id}`);
    return res.data.data;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro';
    throw err;
  }
}