import { message as toast } from 'antd';

interface ToastErrorProps {
  type: 'success' | 'info' | 'warning' | 'error' | undefined;
  message: string;
}

const showToast = (data: ToastErrorProps) => {
  const duration = 3;
  const { type, message = 'Ocorreu um erro, entre em contato com o suporte.' } = data;

  if (type === 'success') {
    return toast.success(message, duration);
  }

  if (type === 'error') {
    return toast.error(message, duration);
  }

  if (type === 'warning') {
    return toast.warning(message, duration);
  }
};

export default showToast;
