import { useContext, useEffect, useState } from 'react';
import { Container} from './styled';
import { message } from 'antd';

import { LoadingContext } from '../../context/loading';
import { Table } from '../../components';
import { healthCheckService } from '../../service';
import { Loading } from '../../providers';

interface LogData {
  createdAt: string;
  status: string;
}

const HealthCheck = () => {
  const { setLoading } = useContext(LoadingContext);
  const [data, setData] = useState([]);
  const [loading, setLoadingBreaked] = useState(true);

  const ControllerRequisition = new AbortController();

  useEffect(() => {
    setLoading(false);
    (async () => {
      try {
        const response = await healthCheckService.get(ControllerRequisition.signal);
        setData(response);
        setLoadingBreaked(false);
      } catch (error: any) {
        setLoadingBreaked(false);
        console.error(error);
        const err = typeof error === 'string' ? error : 'Ocorreu um erro';
        message.error(err);
      }
    })();
    return () => ControllerRequisition.abort();
  }, []);

  useEffect(() => {
    if (data.length > 0) setLoadingBreaked(false);
    else setLoadingBreaked(true);
  }, [data]);

  const convertData = (hcheck) => {
    const hcheckData = hcheck.data;

    const bodyData = [
      hcheckData
          .map((column: LogData, index) => 
          <div className="shipments-column">
            <div className={`${column.status.toLowerCase()} shipments-status`} key={`${column.status} ${index}`}/>
            <span className='shipments-date' key={`${column.createdAt} ${index}`}>{column.createdAt}</span>
          </div>),
    ];
    return <div className="shipments-table" key={hcheck.name}>
        <h2>{hcheck.name}</h2>
        <Table data={ bodyData }/>
    </div>
  };

  return (
    <Container>
      <h2>Status dos serviços:</h2>

      <div className="status">
        <div className="status-captions">
            <div>
              <div className='healthy circle' />    
              <h3>Acima de 95%</h3>
            </div>
            <div>
              <div className='partial circle' />    
              <h3>Entre 80% e 95%</h3>
            </div>
            <div>
              <div className='issued circle' />    
              <h3>Abaixo de 80%</h3>
            </div>
        </div>
      </div>

      <div className="shipments-list">
        {loading && <Loading relative/>}
        {data.length > 0 && data.sort().map((data) => convertData(Object.values(data)[0]))}
      </div>
    </Container>
  );
};

export default HealthCheck;
