import React, { useEffect, useState } from 'react';
import { message } from 'antd';

import { params } from '../../config';
import { showToast } from '../../providers';
import { authService, userService } from '../../service';
import { login } from '../../service/auth.service';
import { AuthContext } from './auth.context';

interface Props {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<any | undefined>(undefined);

  const checkAuthState = async () => {
    (async () => {
      getUserData();
    })();
  };

  async function authUser(email: string, password: string) {
    try {
      const res: any = await login(email, password);

      res.userData.lastVerifiedLogin = new Date();

      localStorage.setItem(params.PARAMS_USER, JSON.stringify(res.userData));
      localStorage.setItem(params.PARAMS_AUTH_TOKEN, `Bearer ${res.token}`);

      setUser(res.userData);

      return true;
    } catch (error: any) {
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      throw showToast({ type: 'error', message: err });
    }
  }

  async function logoutUser() {
    return authService.logout();
  }

  async function getUserData() {
    try {
      const storedUser: any = localStorage.getItem(params.PARAMS_USER);

      if (storedUser) {
        const user: any = JSON.parse(storedUser);

        if (!user?.id) {
          return logoutUser();
        }

        let userData = await userService.findById(user.partner.id, user.id);

        userData = { ...userData, lastVerifiedLogin: user.lastVerifiedLogin };

        localStorage.setItem(params.PARAMS_USER, JSON.stringify(userData));

        setUser(userData);
      }
    } catch (error: any) {
      console.error(error);
      const err = typeof error === 'string' ? error : 'Ocorreu um erro';
      message.error(err);
    }
  }

  useEffect(() => {
    checkAuthState();
  }, []);

  return (
    <AuthContext.Provider value={{ user, setUser, authUser, logoutUser, getUserData }}>
      {children}
    </AuthContext.Provider>
  );
};
