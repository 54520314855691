import * as iquiteiSvc from './iquiteiApi.service';

export async function listCreditorErrorByShipment(
    partnerId: number,
    shipmentId: number,
    signal?: AbortSignal,
    ) {
    try {
        const res = await iquiteiSvc.getRequest(
            `/partners/${partnerId}/v2/creditorErrorLogs/${shipmentId}`, { signal }
        )

        return res.data.data;
    } catch (error: any) {
        const { data } = error.response;
        const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os erros desta campanha';
        throw err;
    }
}
