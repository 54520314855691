import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: ${theme.colors.primary};
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 767px) {
    width: 100vw;
    height: 100vh;
    padding: 20px;
  }
`;

export const Card = styled.div`
  width: 350px;
  background-color: ${theme.colors.light} !important;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: ${theme.border.radius};

  .forgot-message {
    font-size: ${theme.text.size.md};
    margin: 20px 0;
  }

  .go-back {
    display: flex;
    justify-content: center;
  }
`;

export const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;

  img {
    width: 150px;
  }
`;
