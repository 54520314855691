import { StepConfirmateCampaignContainer } from './styled';
import { TextArea } from '../../../../../../components';
import { formattedFullDate } from '../../../../../../utils/date-format';
import { currencyFormat } from '../../../../../../utils/string-format';
import InfoTooltip from '../../../../../../components/InfoTooltip';

const StepConfirmateCampaign = ({ formCampaign, setFormCampaign }: any) => {
  return (
    <StepConfirmateCampaignContainer>
      <div className="columns column-form">
        <div className="configurate-campaing">
          <label>Configurações:</label>
          <div>
            Nome da remessa: <InfoTooltip text="Este foi o nome dado para a sua campanha" />{' '}
            <b>{formCampaign.name}</b>
          </div>
          <div>
            Total de clientes:{' '}
            <InfoTooltip text="Número total de clientes a serem atingidos com a sua campanha" />{' '}
            <b> {formCampaign.file.response.data.sizeFile - 1} </b>
          </div>
          <div>
            Data do disparo:{' '}
            <InfoTooltip text="A data selecionada para que sua campanha aconteça" />{' '}
            <b> {formattedFullDate(formCampaign.shippingDate, 'dd/MM/yyyy HH:mm')} </b>
          </div>
          <div>
            Operadora: <InfoTooltip text="A operadora selecionada para que sua campanha aconteça" />{' '}
            <b> {formCampaign.operator.name} </b>
          </div>
          <div>
            Tipo: <InfoTooltip text="O tipo de SMS escolhido para ser usado em sua campanha" />{' '}
            <b> {formCampaign.operatorType.name} </b>
          </div>

          <div>
            Investimento estimado da remessa:
            <InfoTooltip text="O valor estimado de acordo com as configurações de sua campanha" />{' '}
            <b>
              {currencyFormat(
                formCampaign.file.response.data.sizeFile * formCampaign.operatorType.value
              )}
            </b>
          </div>
        </div>
      </div>
      <div className="columns column-phone">
        <label>Texto SMS:</label>
        <TextArea id="sms-text-input" rows={5} value={formCampaign.smsText} />
      </div>
    </StepConfirmateCampaignContainer>
  );
};

export default StepConfirmateCampaign;
