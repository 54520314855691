import { Container } from './styled';
import React, { useContext, useEffect, useState } from 'react';
import DashboardMenu from '../DashboardMenu';
import { Footer } from 'antd/lib/layout/layout';
import { LoadingContext } from '../../context/loading';
import { Loading } from '../../providers';
import { AuthContext } from '../../context/auth';
import { routeParams } from '../../config/routes.config';
import { useHistory } from 'react-router-dom';
import emptyImg from '../../assets/images/emptyImg.png';

interface DashContainerProps {
  children?: any;
}

const DashContainer: React.FC<DashContainerProps> = ({ children }) => {
  const { loading } = useContext(LoadingContext);
  const { user } = useContext(AuthContext);
  const history = useHistory();

  const [loggedUser, setLoggedUser] = useState<any>({
    imageUrl: '',
    name: '',
  });

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    if (user) {
      setLoggedUser({
        name: user.name,
        imageUrl: user.imageUrl || emptyImg,
      });
    }
  }, [user]);

  return (
    <Container>
      {loading && <Loading />}
      <DashboardMenu />
      <div className="inner-content">
        <div id="layout-header">
          {/* <div className="menu-options">
            <Notifications />
          </div> */}
          {user && (
            <div className="user-profile">
              <button type="button" onClick={() => history.push(routeParams.ROUTE_USER_PROFILE)}>
                <img src={loggedUser.imageUrl} alt="user-profile" />
                <span>{loggedUser?.name}</span>
              </button>
            </div>
          )}
        </div>
        <div id="layout-content">{children}</div>
        <Footer id="layout-footer">
          iQuitei.com© - {currentYear}. Todos os direitos reservados.
        </Footer>
      </div>
    </Container>
  );
};

export default DashContainer;
