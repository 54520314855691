import styled from 'styled-components';
import theme from '../../theme';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-x: auto;

  .inner-content {
    background: ${theme.colors.light};
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  #layout-header {
    width: 100%;
    padding: 20px 50px 0px 50px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }

  #layout-header .menu-options {
    margin-right: 20px;
  }

  #layout-header .menu-options svg {
    font-size: 18px;
  }

  #layout-header .user-profile button {
    background: transparent;
    border: none;
    cursor: pointer;
  }

  #layout-header .user-profile img {
    border-radius: 1000px;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    object-fit: cover;
  }

  #layout-header .user-profile span {
    font-weight: bold;
    color: ${theme.colors.blue};
  }

  #layout-content {
    display: flex;
    padding: 10px 50px 50px 50px;
    width: 100%;
    /* height: 100%; */
  }

  #layout-footer {
    background: ${theme.colors.secondary};
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  @media screen and (max-width: 767px) {
    #layout-content {
      background: ${theme.colors.light};
      display: flex;
      padding: 0rem 5vw;
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;

    #layout-content {
      margin-top: 40px;
    }
  }
`;
