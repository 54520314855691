import * as iquiteiSvc from './iquiteiApi.service';

export async function listByShipment(partnerId: number, shipmentId: number, signal?: AbortSignal) {
  try {
    const res = await iquiteiSvc.getRequest(
      `/partners/${partnerId}/v2/shipment/${shipmentId}/data/confirmation`, { signal }
    );

    return res.data.result;
  } catch (error: any) {
    const { data } = error.response;
    const err = data.statusCode !== 500 ? data.error : 'Ocorreu um erro ao listar os acessos';
    throw err;
  }
}
