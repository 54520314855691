import { Container, PreviewImage } from '../styled';
import { Loading } from '../../../../../providers';
import TextArea from 'antd/lib/input/TextArea';
import { NumericMetrics, Input, BarChart, LineChart, FunnelChart, Button } from '../../../../../components';
import { ExpandAltOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { message, Modal } from 'antd';

export default function Preview({
  loadingShipment,
  loadingCampaignInfo,
  shipment,
  campaign,
  setShowBackground,
  showBackground,
}) {
  return (
    <div className='content-preview' style={{ width: '100%' }}>
      <div className="shipment-details" style={{ marginTop: 10 }}>
        <div className="shipment-detail-field">
          <label>SMS:</label>
          <div style={{ position: 'relative' }}>
            { loadingShipment && <Loading relative /> }
            <TextArea rows={4} value={shipment?.message} placeholder="Texto do SMS" disabled />
          </div>
        </div>
      </div>

      <div className="shipment-detail-field" style={{ marginTop: 10 }}>
        <label>Descrição de texto:</label>
        <div style={{ position: 'relative' }}>
          { loadingCampaignInfo && (<Loading relative />) }
          <TextArea 
            rows={4} 
            value={campaign?.headlineText || ''} 
            disabled
            style={{ position: 'relative' }} 
          />
        </div>
      </div>

      <div className="shipment-details" style={{ marginTop: 10, alignItems: 'center' }}>
        <div className="shipment-detail-field">
          <label>Imagem de fundo:</label>
          <Input
            loading={loadingCampaignInfo}
            type="text"
            value={campaign?.headlineImage || ''}
            suffix={
              <EyeOutlined
                onClick={() => setShowBackground((value) => !value)}
              />
            }
          />
          <Modal
            style={{ justifyContent: 'center', alignItems: 'center',display: 'flex' }}
            title='Imagem de fundo'
            okText='Abrir'
            onOk={() => { window.open(campaign?.headlineImage.toString())}}
            onCancel={() => setShowBackground(false)}
            cancelText='Voltar'
            visible={showBackground && campaign?.headlineImage}
          >
            <PreviewImage src={campaign?.headlineImage} alt='' />
          </Modal>
        </div>
        
        <div className="shipment-detail-field">
          <label>Texto do botão:</label>
          <Input
            loading={loadingCampaignInfo}
            type="text"
            value={campaign?.headlineButton || ''}
            disabled
            suffix={
              <i></i>
            }
          />
        </div>
      </div>

      <div style={{ display: 'flex', marginTop: 10, justifyContent: 'space-around', padding: 20 }}>
        <div className='shipment-detail-field-button' style={{ position: 'relative' }}>
          { loadingCampaignInfo && <Loading relative /> }
          <label>Mostrar botão de recusar negociação:</label>
          {campaign?.showDenyButton ? (
            <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
              Sim
            </span>
            ) : (
            <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
              Não
            </span>
          )}
        </div>
        <div className='shipment-details-field-button' style={{ position: 'relative' }}>
          { loadingCampaignInfo && <Loading relative /> }
          <label>Mostrar botão de central de atendimento: </label>
          {campaign?.call ? (
            <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
              Sim
            </span>
            ) : (
            <span style={{ fontWeight: 'bold', marginLeft: 5 }}>
              Não
            </span>
          )}
        </div>
      </div>
  </div>
  );
};