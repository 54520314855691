import styled from 'styled-components';
import theme from '../../../../../../theme';

export const StepConfirmateCampaignContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 5em;

  .columns {
    flex: 0 50%;
  }

  .column-form {
    margin: auto 0;
  }

  .column-phone {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
    color: ${theme.colors.primary};
  }

  .phones-count {
    margin-bottom: 20px;
  }

  .variables-list {
    display: flex;
    border-radius: 5px;
    margin-bottom: 10px;
    padding: 10px 0;
  }

  .variable-item {
    background: ${theme.colors.primary};
    color: ${theme.colors.white};
    width: fit-content;
    padding: 5px 15px;
    border-radius: 100px;
    float: left;
    margin-right: 10px;
    cursor: pointer;
    user-select: none;
  }

  .configurate-campaing {
    padding: 50px 0;
  }

  .dot {
    height: 15px;
    width: 15px;
    background-color: #bbb;
    border-radius: 50%;
    display: inline-block;
    margin-right: 4px;
  }

  .green {
    background: green;
  }

  .red {
    background: red;
  }

  .line-dot {
    display: flex;
    align-items: center;
  }
`;
