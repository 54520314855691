export enum EPeriodNumberOfDays {
  PERIOD_DAY = 0,
  PERIOD_WEEK = -6,
  PERIOD_MONTH = -29,
  PERIOD_SELECTED = -30,
}

export enum EPeriodDaysName {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  SELECTED = 'selected',
}

export interface IPeriod {
  name: string;
  numberOfDays: EPeriodNumberOfDays;
}

export interface IDateRange {
  period: any;
  startDate: string;
  endDate: string;
}
