import { useHistory, useLocation } from 'react-router-dom';
import { Container, Footer, NavBar } from './styled';
import {
  HomeOutlined,
  StarOutlined,
  ContainerOutlined,
  LogoutOutlined,
  MenuOutlined,
  SettingOutlined,
  CloudServerOutlined,
  UserOutlined,
  WarningOutlined,
  BugOutlined,
  BankOutlined,
  UserAddOutlined,
  ApiOutlined,
  RobotOutlined,
  PlusOutlined,
  CloseOutlined,
  DashboardOutlined,
  ContactsOutlined,
} from '@ant-design/icons';
import { useContext, useState, useEffect } from 'react';
import { Loading } from '../../providers';
import { routeParams } from '../../config/routes.config';
import { AuthContext } from '../../context/auth';
import { PartnerContext } from '../../context/partnerSelect';
import { UserRole } from '../../entity';
import logo from '../../assets/images/iquitei-logo-light.png';
import emptyImg from '../../assets/images/emptyImg.png';
import { Select } from '..';
import { partnerService, userService } from '../../service';
import { message } from 'antd';

interface IMenuItem {
  name: string;
  icon?: any;
  children?: IMenuItem[];
  path: string;
  allowedRoles?: string[];
}

interface SubMenuProps {
  name: string;
  active: boolean;
}

const DashboardMenu: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { user, logoutUser, setUser } = useContext(AuthContext);
  const { partnerSelected, setPartnerSelected } = useContext(PartnerContext);

  const [loading, setLoading] = useState<boolean>(false);

  const [partnerList, setPartnerList] = useState<any>([]);

  const [telegramGroupPartner, setTelegramGroupPartner] = useState(null);

  const autoSetTelegramGroup = async () => {
    try {
      const partner = await partnerService.getInfoById(String(partnerSelected));

      if (partner?.telegram) {
        setTelegramGroupPartner(partner.telegram);
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const userStorage = localStorage.getItem('@AUTH.user');
    if (userStorage && !user && !partnerSelected) {
      const userJson = JSON.parse(userStorage);
      setUser(userJson);
    }
    (async () => {
      try {
        const partners = await partnerService.listPartners();

        setPartnerList(partners);
      } catch (error: any) {
        console.error(error);
        const err = typeof error === 'string' ? error : 'Ocorreu um erro';
        message.error(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (user) {
      (async () => {
        try {
          await userService.registerLastLogin(user.partner.id, user.id);
        } catch (error: any) {
          console.error(error);
          const err = typeof error === 'string' ? error : 'Ocorreu um erro';
          message.error(err);
        }
      })();
      if (typeof user.partner.id === 'undefined') {
        history.push('/error');
      }

      const verifyRole1 = menuItems.some(
        (item: any) =>
          item.path === location.pathname && item.allowedRoles.every((role) => role !== user.role)
      );
      const verifyRole2 = menuItems2.some(
        (item: any) =>
          item.path === location.pathname && item.allowedRoles.every((role) => role !== user.role)
      );
      if (verifyRole1 || verifyRole2) {
        const searchAllowRouter: any = menuItems.find((item: any) =>
          item.allowedRoles.some((role) => role === user.role)
        );
        const searchAllowRouter2: any = menuItems2.find((item: any) =>
          item.allowedRoles.some((role) => role === user.role)
        );

        if (searchAllowRouter && searchAllowRouter.path) history.push(searchAllowRouter.path);
        else if (
          searchAllowRouter &&
          searchAllowRouter.children &&
          searchAllowRouter.children.length !== undefined &&
          searchAllowRouter.children[0].path
        )
          history.push(searchAllowRouter.children[0].path);
        else if (searchAllowRouter2 && searchAllowRouter2.path)
          history.push(searchAllowRouter2.path);
        else if (
          searchAllowRouter2 &&
          searchAllowRouter2.children &&
          searchAllowRouter2.children.length !== undefined &&
          searchAllowRouter2.children[0].path
        )
          history.push(searchAllowRouter2.children[0].path);
        else history.push('/error');
      }
    }
    if (user && !partnerSelected) {
      setPartnerSelected(user.partner.id);
    }
  }, [user]);

  useEffect(() => {
    if (user && user.role === UserRole.ROLE_ADMIN) {
      setUser({ ...user, partner: { ...user.partner, id: partnerSelected } });

      const userStorage = localStorage.getItem('@AUTH.user');
      if (userStorage) {
        const userJson = JSON.parse(userStorage);
        localStorage.setItem(
          '@AUTH.user',
          JSON.stringify({ ...userJson, partner: { ...userJson.partner, id: partnerSelected } })
        );
      }
    }

    autoSetTelegramGroup();
  }, [partnerSelected]);

  const menuItems: IMenuItem[] = [
    {
      name: 'Dashboards',
      icon: <DashboardOutlined />,
      path: '',
      children: [
        {
          name: 'Negociações',
          path: routeParams.ROUTE_DASHBOARD,
        },
        {
          name: 'Financeiro',
          path: routeParams.ROUTE_FINANCE_DASHBOARD,
        },
      ],
      allowedRoles: [UserRole.ROLE_ADMIN, UserRole.ROLE_SUPERVISOR, UserRole.ROLE_MANAGER],
    },
    {
      name: 'Campanhas',
      icon: <ContainerOutlined />,
      path: '',
      children: [
        {
          name: 'Negociação',
          path: routeParams.campaigns.ROUTE_CAMPAIGNS_LIST,
        },
        {
          name: 'Preventivo',
          path: routeParams.campaigns.ROUTE_CAMPAIGNS_LIST_PREVENTIVE,
        },
      ],
      allowedRoles: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER, UserRole.ROLE_SUPERVISOR],
    },
    {
      name: 'Negociações recusadas',
      icon: <WarningOutlined />,
      path: routeParams.ROUTE_PROPOSAL_DENY,
      allowedRoles: [
        UserRole.ROLE_ADMIN,
        UserRole.ROLE_MANAGER,
        UserRole.ROLE_SUPERVISOR,
        UserRole.ROLE_OPERATOR,
      ],
    },
    {
      name: 'Lista de bloqueio',
      icon: <ContactsOutlined />,
      path: routeParams.ROUTE_BLOCKLIST,
      allowedRoles: [
        UserRole.ROLE_ADMIN,
        UserRole.ROLE_MANAGER,
        UserRole.ROLE_SUPERVISOR,
        UserRole.ROLE_OPERATOR,
      ],
    },
    {
      name: 'Cadastrar parceiro',
      icon: <BankOutlined />,
      path: routeParams.ROUTE_REGISTER_PARTNER,
      allowedRoles: [UserRole.ROLE_ADMIN],
    },
    {
      name: 'Cadastrar usuário',
      icon: <UserAddOutlined />,
      path: routeParams.ROUTE_REGISTER_EMPLOYEE,
      allowedRoles: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER, UserRole.ROLE_SUPERVISOR],
    },
    {
      name: 'Cadastrar credor',
      icon: <UserAddOutlined />,
      path: routeParams.ROUTE_REGISTER_CREDITOR,
      allowedRoles: [UserRole.ROLE_ADMIN],
    },
    // {
    //   name: 'Exportar relatórios',
    //   icon: <ContainerOutlined />,
    //   path: routeParams.ROUTE_GENERATE_REPORTS,
    //   allowedRoles: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER, UserRole.ROLE_SUPERVISOR],
    // },
    {
      name: 'Configurações de negociação',
      icon: <SettingOutlined />,
      path: routeParams.ROUTE_CREDITOR_CONFIGURATION,
      allowedRoles: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER, UserRole.ROLE_SUPERVISOR],
    },
    {
      name: 'Configurações de integração',
      icon: <ApiOutlined />,
      path: routeParams.ROUTE_INTEGRATION_CONFIGURATION,
      allowedRoles: [UserRole.ROLE_ADMIN, UserRole.ROLE_MANAGER, UserRole.ROLE_SUPERVISOR],
    },
    {
      name: 'Erros do sistema',
      icon: <BugOutlined />,
      path: routeParams.ROUTE_SYSTEM_ERRORS,
      allowedRoles: [UserRole.ROLE_ADMIN],
    },
  ];

  const menuItems2: IMenuItem[] = [
    {
      name: 'Meu perfil',
      icon: <UserOutlined />,
      path: routeParams.ROUTE_USER_PROFILE,
      allowedRoles: [
        UserRole.ROLE_ADMIN,
        UserRole.ROLE_MANAGER,
        UserRole.ROLE_SUPERVISOR,
        UserRole.ROLE_OPERATOR,
      ],
    },
    // {
    //   name: 'Últimas novidades',
    //   icon: <StarOutlined />,
    //   path: routeParams.ROUTE_SYSTEM_NEWS,
    //   allowedRoles: [
    //     UserRole.ROLE_ADMIN,
    //     UserRole.ROLE_MANAGER,
    //     UserRole.ROLE_SUPERVISOR,
    //     UserRole.ROLE_OPERATOR,
    //   ],
    // },
    {
      name: 'Status dos serviços',
      icon: <CloudServerOutlined />,
      path: routeParams.ROUTE_HEALTH_CHECK,
      allowedRoles: [
        UserRole.ROLE_ADMIN,
        UserRole.ROLE_MANAGER,
        UserRole.ROLE_SUPERVISOR,
        UserRole.ROLE_OPERATOR,
      ],
    },
    {
      name: 'Bot de relatórios diários',
      icon: <RobotOutlined />,
      path: 'telegram',
      allowedRoles: [
        UserRole.ROLE_ADMIN,
        UserRole.ROLE_MANAGER,
        UserRole.ROLE_SUPERVISOR,
        UserRole.ROLE_OPERATOR,
      ],
    },
  ];

  const MenuPartnersSelect = () => {
    return (
      <li className="partners-select no-hover">
        <span className="item-text">Visão do parceiro:</span>
        <Select
          options={partnerList}
          value={partnerSelected}
          onChange={setPartnerSelected}
          optionKeys={{ value: 'id', displayName: 'name' }}
          placeholder="Parceiros"
          firstDisabled
        />
      </li>
    );
  };

  const goTo = (path: string) => {
    if (window.innerWidth <= 1000) {
      closeMobileMenu();
    }

    history.push(path);
  };

  const logout = async () => {
    setLoading(true);
    logoutUser();
    history.push('/');
  };

  const checkIsAllowed = (item: IMenuItem) => {
    return item.allowedRoles?.includes(user?.role || '');
  };

  const closeMobileMenu = () => {
    const mobileMenuElem: any = document.getElementById('side-menu');
    mobileMenuElem.style.display = 'none';
  };

  const menuHeaderLogo = (
    <div className="main-logo">
      <img src={logo} alt="logo" />
    </div>
  );

  const [showSubmenu, setShowsubmenu] = useState<SubMenuProps[]>([]);
  useEffect(() => {
    menuItems.map(
      (i: IMenuItem) =>
        i.children?.length !== 0 &&
        showSubmenu.push({
          name: i.name,
          active: false,
        })
    );
  }, []);

  const handleActiveSubMenu = (item: IMenuItem, index: number) => {
    try {
      const data: SubMenuProps = showSubmenu[index];

      const obj = {
        name: item.name,
        active: !data?.active,
      };

      showSubmenu[index] = obj;

      setShowsubmenu((prevShowSubmenu) => {
        return { ...prevShowSubmenu };
      });
    } catch (error) {
      throw error;
    }
  };

  const renderMenuItem = (item: IMenuItem, index: number) => {
    if (item.children) {
      return (
        <li key={index} onClick={() => handleActiveSubMenu(item, index)} className="item-sub-menu">
          <div className="sub-menu-info">
            <div className="item-icon">{item.icon}</div>
            <span className="item-text">{item.name}</span>
          </div>
          {showSubmenu[index]?.active && (
            <div className={`sub-items ${showSubmenu[index]?.active ? 'show' : ''}`}>
              <ul>
                {item.children.map((item: IMenuItem, index2) => (
                  <li key={`sub-${index2}`} onClick={() => goTo(item.path)}>
                    <span className="item-text">{item.name}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </li>
      );
    }
    return (
      <li key={index} onClick={() => goTo(item.path)}>
        <div className="item-icon">{item.icon}</div>
        <span className="item-text">{item.name}</span>
      </li>
    );
  };

  return (
    <>
      {loading && <Loading />}
      <Container>
        <MobileHeaderMenuContainer children={menuHeaderLogo}></MobileHeaderMenuContainer>
        <div id="side-menu">
          <div className="close-button">
            <CloseOutlined onClick={closeMobileMenu} />
          </div>

          {menuHeaderLogo}
          <NavBar>
            <ul>
              {partnerList.length > 0 && user?.role === UserRole.ROLE_ADMIN && MenuPartnersSelect()}
              {menuItems.map(
                (item: IMenuItem, index: number) =>
                  checkIsAllowed(item) && renderMenuItem(item, index)
              )}
              <hr className="divisor" />
              {menuItems2.map(
                (item: IMenuItem, index: number) =>
                  checkIsAllowed(item) &&
                  (item.path === 'telegram' ? (
                    telegramGroupPartner && (
                      <li key={index} onClick={() => window.open(telegramGroupPartner, '_blank')}>
                        <div className="item-icon">{item.icon}</div>
                        <span className="item-text">{item.name}</span>
                      </li>
                    )
                  ) : (
                    <li key={index} onClick={() => goTo(item.path)}>
                      <div className="item-icon">{item.icon}</div>
                      <span className="item-text">{item.name}</span>
                    </li>
                  ))
              )}
            </ul>
          </NavBar>
          <Footer>
            <div className="bottom">
              <div className="item no-hover" onClick={logout} style={{ textAlign: 'center' }}>
                <LogoutOutlined /> <span className="item-text">Sair</span>
              </div>
            </div>
          </Footer>
        </div>
      </Container>
    </>
  );
};

const MobileHeaderMenuContainer: React.FC<any> = ({ children }) => {
  const openMobileMenu = () => {
    const mobileMenuElem: any = document.getElementById('side-menu');
    mobileMenuElem.style.display = 'flex';
  };

  return (
    <div id="mobile-header">
      {children}
      <MenuOutlined onClick={openMobileMenu} />
    </div>
  );
};
export default DashboardMenu;
